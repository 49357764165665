import React, { useState, useEffect} from 'react'
import {Row,Col, Modal} from 'react-bootstrap'
import Card from '../../../components/Card'
import { getApiCall } from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import {getUser } from '../../../utilities/setting';
import ToastNotification from '../../../components/Toast';
import { USER_ROLES ,NUMBERS_TYPES,NUBMERS_STATUS_LABEL} from '../../../utilities/constant';
import Loader  from '../../../components/partials/components/loader';
import { useNavigate,useParams } from "react-router-dom";
import { errorCheck } from '../auth/auth-utils';
import { postApiCall} from "../../../service/httpCall";
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { API_TO_ALLOCATE_NUMBERS ,API_DID_MAPPING, API_ALLOCATED_NUMBERS,API_REMOVE_NUMBERS} from "../../../service/url";
import "../../../assets/custom/css/custom.css";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { MultiSelect } from 'primereact/multiselect'; 

const numberTypeLabels = NUMBERS_TYPES.map(val => val['label']);
const categoryLabels = ['Free', 'Paid'];
const currencyLabels =['INR','USD'];

const UserAllocate = () =>{
   const navigate = useNavigate();
   const routerParams = useParams();
   const [numbersList, setNumbersList] = useState([]);
   const[filters,setFilters] =useState({
    actual_number: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    country_code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    state_code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    number_type: { value: null, matchMode: FilterMatchMode.EQUALS },
    category: { value: null, matchMode: FilterMatchMode.EQUALS },
    currency: { value: null, matchMode: FilterMatchMode.EQUALS },
    cost: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    operator: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
});
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [loading, setLoading] = useState(false);
   const [selectedNumbers, setSelectedNumber] = useState([]);
   const [userRole, setUserRole] = useState('');
   const [isSuperAdmin, checkIsSuperAdmin] = useState(false);
   const [isViewingAllocated, setIsViewingAllocated] = useState(false);
   const [currentUserId,setCurrentUserId] = useState(undefined);
   const [showModal, setShowModal] =useState(false);
   const [selectedAllocations , setSelectedAllocations] = useState([]);
   const [showRemoveButton , setShowRemoveButton] = useState(false);
   const [allocatedNumber, setAllocatedNumber] = useState([]);


   

   useEffect(() => {
        getUser().then((user) => {
        if (user) {
            if (user.role === USER_ROLES.SUPERADMIN) {
            checkIsSuperAdmin(true);
            }
            setUserRole(user.role);
            setCurrentUserId(user._id);
        }

        });
    }, []);

 
   
   useEffect(() => {
        if (toastProps) {
            setShowToast(true);
        }
    }, [toastProps]);


    useEffect(() => {
        if(currentUserId){
            getApiCall(`${API_TO_ALLOCATE_NUMBERS}/${currentUserId}`)
        .then(onNumberListSuccess)
        .catch(onError)
        .finally(() => setLoading(false));
        }
    }, [currentUserId])

    function onNumberListSuccess(resp) {
        const updatedList = resp.map((val, idx) => {
            val['key'] = idx + 1;
            val['createdAt'] = new Date(val['createdAt']).toGMTString();
            return val;
        });
        setNumbersList(updatedList);
    }
  
    // const handleAllocate = () => {
    //     const {id} = routerParams;
    //     const selectedDID = selectedNumbers;
    //     if(selectedDID.length === 0) {
    //         setToastProps({ type: 'danger' , message: 'No DID selected for allocation' });
    //         setShowToast(true);
    //         return;
    //     }
    //     const params ={
    //         DID : selectedDID,
    //         allocated_to : id,
    //     }

    //     postApiCall(API_DID_MAPPING, params)
    //     .then(() => {
    //         setToastProps({ type: 'success', message: 'DID(s) allocated successfully' });
    //         setShowToast(true);
    //         const updatedNumbersList = numbersList.filter(number => !selectedDID.includes(number._id));
    //             setNumbersList(updatedNumbersList);
    //             setSelectedNumber([]);
    //     })
    //     .catch(error => {
    //         setToastProps({ type: 'danger', message: `Failed to allocate DID(s): ${error}` });
    //         setShowToast(true);
    //     })
    //     .finally(() => setLoading(false));
    // };


    const handleAllocate = () => {
        const {id} = routerParams;
        const selectedDID = selectedNumbers;
        if(selectedDID.length === 0) {
            setToastProps({ type: 'danger' , message: 'No number selected for allocation' });
            setShowToast(true);
            return;
        }
        const params ={
            DID : selectedDID,
            allocated_to : id,
        }
    
        postApiCall(API_DID_MAPPING, params)
        .then(() => {
            const numberType = numbersList.find(number => selectedDID.includes(number._id))?.number_type || 'Number';
            setToastProps({ type: 'success', message: `${numberType}(s) allocated successfully` });
            setShowToast(true);
            const updatedNumbersList = numbersList.filter(number => !selectedDID.includes(number._id));
            setNumbersList(updatedNumbersList);
            setSelectedNumber([]);
        })
        .catch(error => {
            setToastProps({ type: 'danger', message: `Failed to allocate numbers: ${error}` });
            setShowToast(true);
        })
        .finally(() => setLoading(false));
    };
    
    const handleAllocatedToUser = () => {
        const { id } = routerParams;
        setIsViewingAllocated(true);

        getApiCall(`${API_ALLOCATED_NUMBERS}/${id}`)
            .then((resp) => {
                setShowRemoveButton(resp.is_removal_button)
                onNumberListSuccess(resp.data);

            })
            .catch(onError)
            .finally(() => setLoading(false));
    }

   const dropdownItemTemplate = (option) => {
      return <span>{option}</span>;
   }


    const numberTypeFilterTemplate = options => {
        return <Dropdown value={options.value} options={numberTypeLabels} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    }
    const categoryFilterTemplate = options => {
        return <Dropdown value={options.value} options={categoryLabels} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select Category" className="p-column-filter" showClear />;
    }
    const currencyFilterTemplate = options => {
        return <Dropdown value={options.value} options={currencyLabels} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select Currency" className="p-column-filter" showClear />;
    }
    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={NUBMERS_STATUS_LABEL} onChange={(e) => options.filterCallback(e.value)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    }
   
   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        console.log(err)
      }
   }

   
   const handleCheckboxToggle = (numberId) => {
    const isSelected = selectedNumbers.includes(numberId);
    setSelectedNumber(isSelected
        ? selectedNumbers.filter(id => id !== numberId)
        : [...selectedNumbers, numberId]);
   };

   const handleNumberTypeFilter = (type) => {
        setIsViewingAllocated(false);
        setFilters((prevFilters) => ({
            ...prevFilters,
            number_type: { value: type, matchMode: FilterMatchMode.EQUALS },
        }));
    };

    const handleSelectAll = () => {
        if (selectedNumbers.length === numbersList.length) {
            setSelectedNumber([]);
        } else {
            const allNumberIds = numbersList.map(number => number._id);
            setSelectedNumber(allNumberIds);
        }
    };


    const getButtonLabel = () => {
        const {role} = routerParams;
        if (isSuperAdmin || role === USER_ROLES.RESELLER) {
            return "Allocate To Reseller";
        }
        
        switch (role) {
            case USER_ROLES.RESELLER:
                return "Allocate To Reseller"
            case USER_ROLES.COMPANY_ADMIN:
                return "Allocate to Company User";
            case USER_ROLES.CALL_CENTRE_ADMIN:
                return "Allocate to Call Centre";
            default:
                return "Allocate";
        }

    };

    const handleRefresh = () => {
        setIsViewingAllocated(false);  
        getApiCall(`${API_TO_ALLOCATE_NUMBERS}/${currentUserId}`)
            .then(onNumberListSuccess)
            .catch(onError)
            .finally(() => setLoading(false));
    };


    const handleOpenModal = () => {
        setShowModal(true); 
    };

    const handleCloseModal = () => {
        setSelectedAllocations([]);
        setShowModal(false); 
    };

    useEffect(() => {
        const {id} = routerParams
        if(id){
            getApiCall(`${API_TO_ALLOCATE_NUMBERS}/${id}`)
        .then((resp) => {
            const updatedList = resp.map((val, idx) => {
                val['key'] = idx + 1;
                val['createdAt'] = new Date(val['createdAt']).toGMTString();
                return val;
            });
            setAllocatedNumber(updatedList);
        })
        .catch(onError)
        .finally(() => setLoading(false));
        }
    }, [showModal])

    const handleRemoveAllocatedDIDs =() =>{
        const { id } = routerParams;
        if(selectedAllocations.length === 0){
            setToastProps({ type: 'danger', message: 'No DID selected for removal'});
            setShowToast(true);
            return;
        }
 
        setLoading(true);

        const params = {
            DID: selectedAllocations,
            user_id: id,
        };

        postApiCall(API_REMOVE_NUMBERS, params)
        .then(() => {
            setToastProps({ type: 'success', message: 'DID(s) removed successfully' });
            setShowToast(true);

            const updatedNumbersList = numbersList.filter(number => !selectedAllocations.includes(number._id));
            setNumbersList(updatedNumbersList);
            setSelectedAllocations([]);
            setShowModal(false);
        })
        .catch((error) => {
            setToastProps({ type: 'danger', message: `Failed to remove DID(s): ${error}` });
            setSelectedAllocations([]);
            setShowModal(false);
        })
        .finally(() => setLoading(false));

        
        
            
    }

   const rowClassName = (data) => {
      return {
          'highlighted-row': selectedNumbers.includes(data._id)
        };
    }
       
    if (loading) {
      return <Loader />
    }

  return(
    <>
       <div>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                     <div className="header-title">
                        <h4 className="card-title">Allocate Number</h4>
                     </div>
                     <div>
                     <button className="btn btn-primary me-2" onClick={handleRefresh}>Refresh</button>
                     {isViewingAllocated && numbersList.length > 0 && showRemoveButton && (
                    <button className="btn btn-primary me-2" onClick={handleOpenModal}>Remove Number</button>
                    )}
                     </div>
                  </Card.Header>
                  <Card.Body className="px-0">
                   <div className="table-responsive border-bottom my-3">
                        <Row className="d-flex align-items-center">
                            <Col>
                                <Button
                                    label={getButtonLabel()}
                                    onClick={() => { setIsViewingAllocated(false); handleAllocate(); }}
                                    className="p-button-primary p-button-sm"
                                    style={{ marginBottom: '10px', marginLeft: '10px' }}
                                    disabled={selectedNumbers.length === 0}
                                />
                                <Button
                                    label="Allocated Numbers"
                                    onClick={handleAllocatedToUser}
                                   className="p-button-primary p-button-sm"
                                    style={{ marginBottom: '10px', marginLeft: '10px' }}
                                />
                            </Col>
                        </Row>           
                        <Col className="d-flex justify-content-end">
                            <div className='button-group'>
                                <button className="btn btn-primary btn-sm button-spacing" onClick={() => handleNumberTypeFilter('DID')}>DID</button>
                                <button className="btn btn-primary btn-sm button-spacing" onClick={() => handleNumberTypeFilter('VMN')}>VMN</button>
                                <button className="btn btn-primary btn-sm button-spacing" onClick={() => handleNumberTypeFilter('TOLL FREE')}>TOLL FREE</button>
                            </div>
                        </Col>                   
                  
                        <DataTable value={numbersList} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8} rowClassName = {rowClassName}>
                            {(!isViewingAllocated && (isSuperAdmin || [USER_ROLES.RESELLER, USER_ROLES.COMPANY_ADMIN, USER_ROLES.CALL_CENTRE_ADMIN].includes(userRole))) && (
                                    <Column 
                                        header={<Checkbox 
                                        checked={selectedNumbers.length === numbersList.length && numbersList.length > 0} 
                                        onChange={handleSelectAll} 
                                    />} 
                                    headerStyle={{ width: '3rem' }} 
                                    body={(rowData) => (
                                    <Checkbox 
                                        checked={selectedNumbers.includes(rowData._id)} 
                                        onChange={() => handleCheckboxToggle(rowData._id)} 
                                    />
                                )} 
                                />
                            )} 
                            
                            <Column field="actual_number" filterField="actual_number" header="DID" filter filterPlaceholder="Search by Number" body={(rowData) => rowData.actual_number || '-'}></Column>
                            <Column field="country_code" filterField="country_code" header="Country Code" filter filterPlaceholder="Search by Country Code" body={(rowData) => rowData.country_code || '-'}></Column>
                            <Column field="state_code" filterField="state_code" header="State Code" filter filterPlaceholder="Search by State Code" body={(rowData) => rowData.state_code || '-'}></Column>
                            <Column field="cost" filterField="cost" header="Cost" filter filterPlaceholder="Search by Cost" body={(rowData) => rowData.cost || '-'}></Column>
                            <Column field="operator" filterField="operator" header="Operator" filter filterPlaceholder="Search by Operator" body={(rowData) => rowData.operator || '-'}></Column>
                            <Column field="category" filterField="category" header="Category" filter filterPlaceholder="Search by Category" filterElement={categoryFilterTemplate} body={(rowData) => rowData.category || '-'}></Column>
                            <Column field="currency" filterField="currency" header="Currency" filter filterPlaceholder="Search by Currency" filterElement={currencyFilterTemplate} body={(rowData) => rowData.currency || '-'}></Column>
                            <Column field="number_type" filterField="number_type" header="Number Type" filter filterPlaceholder="Search by Number Type" filterElement={numberTypeFilterTemplate} body={(rowData) => rowData.number_type || '-'}></Column>
                            <Column field="status" header="Status" filterElement={statusFilterTemplate} filter body={(rowData) => rowData.status || '-'}></Column>
                            <Column field="routing_id" filterField="routing_id" header="Routing Id" filter filterPlaceholder="Search by Routing Id" body={(rowData) => rowData.routing_id || '-'}></Column>
                            <Column field="routing_type" filterField="routing_type" header="Routing Type" filter filterPlaceholder="Search by Routing Type"  body={(rowData) => rowData.routing_type || '-'}></Column>
                            <Column field="routing_destination" filterField="routing_destination" header="Routing Destination" filter filterPlaceholder="Search by Routing"  body={(rowData) => rowData.routing_destination || '-'}></Column>
                            <Column field="createdAt" header="Created Date"></Column> 
                            {isViewingAllocated && (
                                <Column field="allocated_name" header="Allocated To"></Column> 
                            )}
                            {isViewingAllocated && (
                                <Column field="allocated_by" header="Allocated By"></Column> 
                            )}
                        </DataTable>
                    </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>

         <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove Numbers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MultiSelect
                            value={selectedAllocations}
                            options={allocatedNumber.map(number => ({ label: number.actual_number, value: number._id }))}
                            onChange={(e) => setSelectedAllocations(e.value)}
                            placeholder="Select Number"
                            className="w-100"
                            appendTo="self"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button label="Close" className="p-button-secondary" style={{ marginRight: '10px' }} onClick={handleCloseModal} />
                        <Button label="Remove" className="p-button-primary" onClick={handleRemoveAllocatedDIDs} />
                    </Modal.Footer>
                </Modal>
       </div>
       <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

    </>
  )

}

export default UserAllocate;