import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../../components/Card';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ToastNotification from '../../../../components/Toast';
import { getApiCall } from '../../../../service/getApiCall';
import { postApiMediaCall } from '../../../../service/postApiCall';
import { API_CREATE_PROMPTS , API_GET_LANGUAGES} from '../../../../service/url';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../utilities/constant';
import LottieAnimation from '../../../../components/lottie/lottie';
import Modal from "react-bootstrap/Modal";


const AddVoice = () => {
    const [file, setFile] = useState(null);
    const [category, setCategory] = useState([]);
    const [fileName, setFileName] = useState('');
    const [toastProps, setToastProps] = useState(null);
    const [showToastModal, setShowToastModal] = useState(false);
    const [sendCategory, setSendcategory] = useState(null);
    const [toastMessage, setToastMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [languages, setLanguages] = useState([]);
    const navigate = useNavigate();
    const [selectedLanguage, setSelectedLanguage] = useState('')

    const showToasts = (message) => {
        setToastMessage(message);
        setShowToastModal(true);

        // Hide modal automatically after 3 seconds
        setTimeout(() => {
            setShowToastModal(false);
        }, 3000);
    };

    useEffect(() => {
            if (toastProps) {
                setShowToast(true);
            }
    
        }, [toastProps]);

    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
    };


    const uploadFile = async (file, category, fileName) => {
        const formData = new FormData();
        formData.append('file', file);    
        formData.append('prompt_category', category); 
        formData.append('prompt_name', fileName);
        formData.append('language', selectedLanguage);

        try {
            postApiMediaCall(API_CREATE_PROMPTS, formData)
                .then(resp => {
                    onPromptsSuccess(resp);
                })
                .catch(err => {
                    onPromptsError(err);
                })
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    function onPromptsSuccess(resp) {
        showToasts('Voice File Uploaded Successfully, Please wait for Admin Approval')
        setTimeout(() => {
            navigate(`/${ROUTES.VOICE_LIBRARY.VIEW_VOICE_FILES}`);
        }, 1500);
    }

    function onPromptsError(err) {
        setToastProps({message: JSON.stringify(err), type: 'danger'});
    }
    
    const handleUpload = async () => {
        if (!file || !sendCategory || !fileName.trim() || !selectedLanguage.trim()) {
            setToastProps({ type: 'danger', message: 'Please fill all fields!' });
            setShowToast(true);
            return;
        }
        uploadFile(file,sendCategory,fileName)
    };
    
    const fetchCategories = () =>{
        getApiCall('/v1/voice-category').then((response)=>
        {
            if(response)
            {
                setCategory(response.categories)
            }
        })
    }

    const fetchLanguages = () =>{
        getApiCall(API_GET_LANGUAGES).then((response)=>
        {
            if(response)
            {
                const transformed = response.map(item =>
                    Object.entries(item.language).map(([key, value]) => ({
                      label: value,
                      value: key
                    }))
                  );

                setLanguages(transformed[0])
            }
        })
    }

    useEffect(()=> {
       fetchCategories();
       fetchLanguages();
    },
    [])


    return (
        <>
        <div style={{ marginTop: '20px' }}>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-block">
                            <h4 className="card-title">Upload Voice File</h4>
                        </Card.Header>
                        <Card.Body>
                            <form>
                                <Row className="mb-3">
                                    <Col md="6">
                                        <label htmlFor="uploadFile" className="form-label">
                                            Upload WAV or MP3 File
                                        </label>
                                        <input
                                            type="file"
                                            id="uploadFile"
                                            className="form-control"
                                            accept=".wav, .mp3"
                                            onChange={handleFileUpload}
                                        />
                                    </Col>
                                    <Col md="6">
                                        <label htmlFor="fileName" className="form-label">
                                            File Name
                                        </label>
                                        <input
                                            type="text"
                                            id="fileName"
                                            value={fileName}
                                            onChange={(e) => setFileName(e.target.value)}
                                            className="form-control"
                                            placeholder="File Name"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="6">
                                        <label htmlFor="category" className="form-label">
                                            Select Category
                                        </label>
                                        <Dropdown
                                            style={{ height: "50%", alignItems: "center" }}
                                            id="category"
                                            value={sendCategory}
                                            options={category}
                                            onChange={(e) => setSendcategory(e.value)}
                                            placeholder="Select Category"
                                            className="form-control"
                                        />
                                    </Col>
                                    <Col md="6">
                                        <label htmlFor="language" className="form-label">
                                            Select Language
                                        </label>
                                        <Dropdown
                                            style={{ height: "50%", alignItems: "center" }}
                                            id="language"
                                            value={selectedLanguage}
                                            options={languages}
                                            onChange={(e) => setSelectedLanguage(e.value)}
                                            placeholder="Select Language"
                                            className="form-control"
                                        />
                                    </Col>
                                </Row>
                                <Button
                                    label="Upload File"
                                    onClick={handleUpload}
                                    className="p-button-danger mt-3"
                                    type="button"
                                />
                            </form>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </div>

      <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

       {/* LOTTIE MODEL FOR TOAST */}
       <Modal show={showToastModal} centered onHide={() => setShowToastModal(false)} size="sm">
                <Modal.Body className="text-center">
                    <h5>{toastMessage}</h5>
                    <LottieAnimation />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddVoice;
