import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import Card from "../../../components/Card";
import { useNavigate, useParams } from "react-router-dom";
import { patchApiCallWithHeader, getApiCall } from "../../../service/httpCall";
import { API_USER_STATUS_UPDATE } from "../../../service/url";
import { ROUTES, BACKEND_API_BASE_URL } from "../../../utilities/constant";
import ToastNotification from "../../../components/Toast";
import { errorCheck } from "../auth/auth-utils";

const UserResetPassword = () => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [username, setUsername] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordInputs, setShowPasswordInputs] = useState(false);
  const [enteredSamePasswords, setEnteredSamePasswords] = useState(false);

  useEffect(() => {
    const SELECTED_USER_ROUTE = `${API_USER_STATUS_UPDATE}/${id}`;
    getApiCall(SELECTED_USER_ROUTE, {})
    .then(getUserDataRecordSuccess)
    .catch(getUserDataError);
  }, []);

  const getUserDataRecordSuccess = (resp) => {
    if(resp){
      setUsername(resp.username);
    }
  }

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  const handleValidateUsername = (e) => {
    e.preventDefault();
    setShowPasswordInputs(false);
    const ALL_USERS_ROUTE = `${BACKEND_API_BASE_URL}/v1/${ROUTES.USERS.LIST}`;
    if (!username.trim().length) {
      setToastProps({ message: "Please add a valid username", type: "danger" });
      return;
    }
    getApiCall(ALL_USERS_ROUTE, {})
      .then(getUserDataSuccess)
      .catch(getUserDataError);
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setEnteredSamePasswords(false);

    if (!password.trim().length) {
      setToastProps({ message: "Password Can't be blank", type: "danger" });
      return;
    }
    if (password == selectedUser) {
      setToastProps({
        message: "New Password Can't be same as Older Password.",
        type: "danger",
      });
      return;
    }
    if (!confirmPassword.trim().length) {
      setToastProps({ message: "Password Can't be blank", type: "danger" });
      return;
    }
    if (password === confirmPassword) {
      const payload = { newPassword: confirmPassword };
      const SELECTED_USER_ROUTE = `${API_USER_STATUS_UPDATE}/${id}`;
      patchApiCallWithHeader(SELECTED_USER_ROUTE, payload)
          .then(handleUpdateUserPassword)
          .catch(getUserDataError);
    }
    if (password !== confirmPassword) {
      setToastProps({ message: "Password and confirm password are different", type: "danger" });
      return;
    }
  };

  const getUserDataSuccess = (resp) => {
    if (resp) {
      const filteredUserData = resp.filter((user) => {
        return user.username
          .trim()
          == username.trim()
      });
      if (filteredUserData.length < 1) {
        setToastProps({
          message: "User not found. Try some different username.",
          type: "danger",
        });
        return;
      }
      if (filteredUserData.length == 1) {
        setSelectedUser(filteredUserData[0].actual_password);
        // setToastProps({
        //   message: "User found.",
        //   type: "success",
        // });
        setTimeout(() => {
          setShowPasswordInputs(true);
        }, 200);
      }
    }
  };

  const handleUpdateUserPassword = (resp) => {
    setToastProps({ message: "Password Changed", type: "success", time: 4000 });
    setTimeout(() => {
      navigate("/users");
    }, 1000);
  }

  const getUserDataError = (err) => {
    if (typeof err !== "object" && err.includes("TokenExpiredError")) {
      errorCheck(err, navigate, setToastProps, setShowToast);
      setShowPasswordInputs(false);
    } else {
      setButtonDisable(false);
      setShowPasswordInputs(false);
      setToastProps({ message: JSON.stringify(err), type: "danger" });
    }
  };
  return (
    <div>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Reset Password</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <form onSubmit={handleValidateUsername}>
                <div className="row">
                  <Form.Group className="col-md-6 form-group">
                    <Form.Label htmlFor="username">User Name:</Form.Label>
                    <Form.Control
                      type="text"
                      id="username"
                      required
                      placeholder="User Name"
                      value={username}
                      readOnly
                    />
                  </Form.Group>
                </div>
                <Button
                  type="submit"
                  variant="btn btn-primary"
                  disabled={buttonDisable}
                >
                  Continue &nbsp;
                  {buttonDisable ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <></>
                  )}
                </Button>
              </form>

              {showPasswordInputs == true ? (
                <form
                  style={{ marginTop: "50px" }}
                  onSubmit={handleResetPassword}
                >
                  <div className="row">
                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="username">
                        Enter your password:
                      </Form.Label>
                      <Form.Control
                        type="password"
                        id="password"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                  </div>

                  <div className="row">
                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="username">
                        Re-enter your password:
                      </Form.Label>
                      <Form.Control
                        type="password"
                        id="confirm_password"
                        placeholder="Password"
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <Button
                    type="submit"
                    variant="btn btn-primary"
                    disabled={buttonDisable}
                  >
                    Change Password &nbsp;
                    {buttonDisable ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <></>
                    )}
                  </Button>
                </form>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </div>
  );
};

export default UserResetPassword;
