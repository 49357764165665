import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import {
  API_COUNTRY_CODES_LIST,
  API_NUMBER_STATUS,
  API_OPERATORS_LIST,
  API_STATES_LIST
} from "../../../service/url";
import { getApiCall, postApiCall } from "../../../service/httpCall";
import {
  NUMBERS_TYPES,
  
 
} from "../../../utilities/constant";

export default function RenderNumberForm(props) {



  console.log("PROPS CATEGORY", props);

  

  const [countryCodesList, setCountryCodesList] = useState([]);
  const [numberStatusList, setNumberStatusList] = useState([]);
  const [stateCodeList, setStateCode] = useState([]);
  const [operatorsList, setOperatorsList] = useState([]);

  useEffect(() => {
    getApiCall(API_COUNTRY_CODES_LIST, {})
      .then(onCountryCodesListSuccess)
      .catch(onError);

    getApiCall(API_NUMBER_STATUS, {})
      .then(onNumberStatusSuccess)
      .catch(onError);

    getApiCall(API_OPERATORS_LIST, {})
     .then(onOperatorListSuccess)
     .catch(onError);  
  
  }, []);

  function onOperatorListSuccess(resp) {
    setOperatorsList(resp);
  }

  useEffect(() => {
    if (props.countryCodeId) {
      const params = {
        countryCode: props.countryCodeId,
      };
      postApiCall(API_STATES_LIST, params)
        .then((response) => {
          setStateCode(response);
        })
        .catch((error) => console.error("Error while fetching states:", error));
    }
  }, [props.countryCodeId]);

  function onNumberStatusSuccess(resp) {
    setNumberStatusList(resp);
  }

  function onCountryCodesListSuccess(resp) {
    setCountryCodesList(resp);
  }

  function onError(err) {
    console.log(err);
  }

  return (
    <>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          <Form.Label htmlFor="number_type">Number Type:</Form.Label>
          <Form.Select
            id="numberType"
            value={props.numberType}
            className="p-2"
            onChange={(e) =>props.setNumberType(e.target.value)}
            required
          >
             <option value={""}>Select Number Type</option>
            {NUMBERS_TYPES.map(function(val,idx){
              return(
                <option key ={idx} value={val.value}>
                  {val.label}
                </option>
              )
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          <Form.Label htmlFor="currency">Currency:</Form.Label>
          <Form.Select
            id="currency"
            value={props.currency}
            className="p-2"
            onChange={(e) => props.setCurrency(e.target.value)}
            required
          >
            <option value="">Select Currency</option>
            <option value="INR">INR</option>
            <option value="USD">USD</option>
          </Form.Select>
        </Form.Group>
      </div> 
      <div className="row">
        {props.numberType === 'DID' && (
          <Form.Group className="col-md-6 form-group">
            <Form.Label htmlFor="category">Category:</Form.Label>
            <Form.Select
              id="category"
              value={props.category}
              className="p-2"
              onChange={(e) =>props.setCategory(e.target.value)}
              required
            >
              <option value="">Select Category</option>
              <option value="Free">Free</option>
              <option value="Paid">Paid</option>
            </Form.Select>
          </Form.Group>
        )}
        {props.numberType === 'DID' && (
          <Form.Group className="col-md-6 form-group">
          <Form.Label htmlFor="number_type">State Code:</Form.Label>
          <Form.Select
            id="stateCode"
            required
            value={props.stateCode}
            className="p-2"
            onChange={(e) => props.setStateCode(e.target.value)}
          >
            <option value={""}>Select State Code</option>
            {stateCodeList && stateCodeList.map(function (val, idx) {
              return (
                <option key={idx} value={val.isoCode}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        )}
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          <Form.Label htmlFor="country_code">Country Code:</Form.Label>
          <Form.Select
            id="countryCodeId"
            required
            value={props.countryCodeId}
            className="p-2"
            onChange={(e) => props.setCountryCodeId(e.target.value)}
          >
            <option value={""}>Select Country Code</option>
            {countryCodesList.map(function (val, idx) {
              return (
                <option key={idx} value={val.code}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          <Form.Label htmlFor="did">DID:</Form.Label>
          <Form.Control
            type="text"
            placeholder="DID"
            id="did"
            value={props.DID}
            onChange={(e) => props.setDID(e.target.value)}
            required
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          <Form.Label htmlFor="cost">Cost:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Cost"
            id="cost"
            value={props.cost}
            onChange={(e) => props.setCost(e.target.value)}
            required
          />
          </Form.Group>

          <Form.Group className="col-md-6 form-group">
          <Form.Label htmlFor="number_type">Operator:</Form.Label>
            <Form.Select
              id="operator"
              placeholder="Operator"
              className="p-2"
              required
              value={props.operator}
              onChange={(e) => props.setOperator(e.target.value)}
            >
              <option value={""}>Select Operator</option>
              {operatorsList.map(function (val, idx) {
                return (
                  <option key={idx} value={val.name}>
                    {val.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        <Form.Group className="col-md-6 form-group">
          <Form.Label htmlFor="status">Status:</Form.Label>
          <Form.Select
            id="numberStatusId"
            required
            value={props.status}
            className="p-2"
            onChange={(e) => props.setStatus(e.target.value)}
          >
            <option value={""}>Select Status</option>
            {numberStatusList.map(function (val, idx) {
              return (
                <option key={idx} value={val.status_code}>
                  {val.status}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </div>
      <hr />
    </>
  );
}
