import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import Loader from '../../../components/partials/components/loader';
import { API_EXTENTION } from '../../../service/url';
import { getApiCall, postApiCall } from '../../../service/httpCall';
import exportData from '../../../utilities/export';
import '../../../assets/custom/css/custom.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

const Extention = () => {
   const navigate = useNavigate();
   const [extentionList, setExtentionList] = useState([]);
   const [loading, setLoading] = useState(false);
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [selectedExtentions, setSelectedExtentions] = useState([]);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);

   // Hide toast automatically after 3 seconds
   useEffect(() => {
      if (showToast) {
         const timer = setTimeout(() => setShowToast(false), 3000);
         return () => clearTimeout(timer);
      }
   }, [showToast]);

   useEffect(() => {
      if (confirmDelete) {
         handleDeleteSelectedExtentions();
      }
   }, [confirmDelete]);

   // Fetch Extention Data
   const fetchExtentions = () => {
      setLoading(true);
      getApiCall(API_EXTENTION)
         .then((resp) => {
            console.log("RESPINSE DATA EXETENTION", resp);
            if (resp) {

               console.log("RESPINSE DATA EXETENTION", resp);
               const updatedList = resp.map((val, idx) => ({
                  ...val,
                  key: idx + 1,
                  createdAt: new Date(val.createdAt).toLocaleString(), // Format the createdAt date
                  updatedAt: new Date(val.updatedAt).toLocaleString(), // Format the updatedAt date
               }));
               setExtentionList(updatedList);
            } else {
               setToastProps({ type: 'danger', message: 'Failed to fetch extentions' });
               setShowToast(true);
            }
         })
         .catch((error) => {
            console.error("Error fetching extensions:", error);
            setToastProps({ type: 'danger', message: 'Error fetching extentions' });
            setShowToast(true);
         })
         .finally(() => setLoading(false));
   };

   useEffect(fetchExtentions, []);

   const handleDeleteSelectedExtentions = () => {
      if (selectedExtentions.length === 0) return;
      postApiCall(API_EXTENTION + '/delete', { extentionIds: selectedExtentions })
         .then(() => {
            setToastProps({ type: 'success', message: 'Extentions deleted successfully' });
            setShowToast(true);
            fetchExtentions();
            setSelectedExtentions([]);
         })
         .catch((err) => {
            setToastProps({ type: 'danger', message: `Unable to delete extentions: ${err}` });
            setShowToast(true);
         })
         .finally(() => setEnableDeleteModal(false));
   };

   const handleExport = async () => {
      setButtonDisable(true);
      await exportData('Extention', setToastProps, setShowToast);
      setButtonDisable(false);
   };

   const onCheckboxChange = (e, extentionId) => {
      const { checked } = e.target;
      setSelectedExtentions((prevSelected) =>
         checked ? [...prevSelected, extentionId] : prevSelected.filter((id) => id !== extentionId)
      );
   };

   const onHeaderCheckboxChange = (e) => {
      const { checked } = e.target;
      if (checked) {
         const allExtentionIds = extentionList.map((extention) => extention._id);
         setSelectedExtentions(allExtentionIds);
      } else {
         setSelectedExtentions([]);
      }
   };

   // Custom render for the "Allocation" column
   const allocationBodyTemplate = (rowData) => {
      return rowData.isAllocated === 0 ? "Available" : "Allocated";
   };

   // Custom render for the "Extension" column (combining prefix and description)
   const extensionBodyTemplate = (rowData) => {
      return `${rowData.extention} (${rowData.description || "No Description"})`;
   };

   return (
      <div style={{ marginTop: '20px' }}>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <h4>Extentions List</h4>
                     <Button className="btn btn-primary" onClick={() => navigate('/create-extention')}>Create Extention</Button>
                  </Card.Header>
                  <Card.Body className="px-0">
                     {loading ? <Loader /> : (
                        <>
                           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                              <Button
                                 label="Delete Selected"
                                 onClick={() => setEnableDeleteModal(true)}
                                 className="p-button-danger p-button-sm"
                                 style={{ marginLeft: '10px' }}
                                 disabled={selectedExtentions.length === 0}
                              />
                              <Button
                                 label="Export Data"
                                 onClick={handleExport}
                                 className="p-button-active p-button-sm"
                                 style={{ marginLeft: '10px' }}
                                 disabled={buttonDisable}
                              >
                                 {buttonDisable && (
                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                 )}
                              </Button>
                           </div>

                           <DataTable value={extentionList} paginator rows={10}>
                              <Column
                                 header={<Checkbox onChange={onHeaderCheckboxChange} checked={selectedExtentions.length === extentionList.length && extentionList.length > 0} />}
                                 body={(rowData) => (
                                    <Checkbox onChange={(e) => onCheckboxChange(e, rowData._id)} checked={selectedExtentions.includes(rowData._id)} />
                                 )}
                                 style={{ width: '3rem' }}
                              />
                              <Column field="username" header="Username" />
                              <Column body={allocationBodyTemplate} header="Allocation Status" />
                              <Column body={extensionBodyTemplate} header="Extension (Description)" />
                              <Column field="createdAt" header="Creation Date" />
                           </DataTable>
                        </>
                     )}
                  </Card.Body>
               </Card>
            </Col>
         </Row>

         <RenderDeleteModal
            show={enableDeleteModal}
            setShow={setEnableDeleteModal}
            setConfirmDelete={setConfirmDelete}
            isMultiDelete={true}
            clearSelectedData={() => setSelectedExtentions([])}
            onConfirm={handleDeleteSelectedExtentions}
         />

         {/* <ToastNotification show={showToast} set_show_toast={setShowToast} {...toastProps} /> */}
      </div>
   );
};

export default Extention;
