import { apiWithHeader } from "./api";
import { API_STATUS } from "./constant";
import { BACKEND_API_BASE_URL } from "../utilities/constant";
import { getUserAccessToken } from "../utilities/setting";

export const patchApiCallWithHeader = async(url, payload) => {

    const accessToken = await getUserAccessToken();
  
    const response = await apiWithHeader(BACKEND_API_BASE_URL, 'application/json', accessToken)
    .patch(url, payload)
    .catch(async function (error) {
        if(error.response == undefined){
            var errorMsg = 'A network error occurred. This could be a CORS issue or a dropped internet connection.';
        }else{
            const dataMsg = error.response.data.message ? String(error.response.data.message) : '';
            var errorMsg = dataMsg
        }
        return errorMsg;
    });

    if (response.status == API_STATUS.SUCCESS || response.status == API_STATUS.ACCEPED) {
        return response.data.data;
    } else {
        return Promise.reject(response);
    }

};