import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function RenderInactiveUserModal(props){

    const handleClose = () => {
        props.setShow(false);
        props?.setConfirmUserInactive(false);
    };
    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Inactive the Selected user?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to make this user inactive?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={() => props.setConfirmUserInactive(true)}>Yes</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default RenderInactiveUserModal;