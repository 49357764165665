import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import Loader from '../../../components/partials/components/loader';
import { API_AGENT_GROUPS_LIST, API_AGENT_GROUP_DELETE } from '../../../service/url';
import { getApiCall, postApiCall } from '../../../service/httpCall';
import exportData from '../../../utilities/export';
import '../../../assets/custom/css/custom.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { ToastContainer, toast } from 'react-toastify';
import { Dropdown } from 'primereact/dropdown';
import { EditIcon, DeleteIcon } from '../../../components/setting/elements';


const AgentGroups = () => {
   const navigate = useNavigate();
   const [agentGroupList, setAgentGroupList] = useState([]);
   const [loading, setLoading] = useState(false);
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [selectedAgentGroups, setSelectedAgentGroups] = useState([]);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);

   useEffect(() => {
      if (showToast) {
         const timer = setTimeout(() => {
            setShowToast(false);
         }, 1500);
         return () => clearTimeout(timer);
      }
   }, [showToast]);

   const fetchAgentGroups = () => {
      setLoading(true);
      getApiCall(API_AGENT_GROUPS_LIST)
         .then((resp) => {
            if (resp) {
               const updatedList = resp.map((val, idx) => ({
                  ...val,
                  key: idx + 1,
                  createdAt: new Date(val.createdAt).toLocaleString(),
               }));
               setAgentGroupList(updatedList);
            } else {
               setToastProps({ type: 'danger', message: 'Failed to fetch agent groups' });
               setShowToast(true);
            }
         })
         .catch((error) => {
            setToastProps({ type: 'danger', message: 'Error fetching agent groups' });
            setShowToast(true);
         })
         .finally(() => setLoading(false));
   };

   useEffect(fetchAgentGroups, []);

   const handleDeleteSelectedAgentGroups = () => {
      if (selectedAgentGroups.length === 0) return;
      postApiCall(API_AGENT_GROUP_DELETE, { agentGroupIds: selectedAgentGroups })
         .then(() => {
            setToastProps({ type: 'success', message: 'Agent groups deleted successfully' });
            setShowToast(true);
            fetchAgentGroups();
            setSelectedAgentGroups([]);
         })
         .catch((err) => {
            setToastProps({ type: 'danger', message: `Unable to delete agent groups: ${err}` });
            setShowToast(true);
         })
         .finally(() => setEnableDeleteModal(false));
   };

   const handleExport = async () => {
      setButtonDisable(true);
      await exportData('AgentGroup', setToastProps, setShowToast);
      setButtonDisable(false);
   };

   const onCheckboxChange = (e, groupId) => {
      const { checked } = e.target;
      setSelectedAgentGroups((prevSelected) =>
         checked ? [...prevSelected, groupId] : prevSelected.filter((id) => id !== groupId)
      );
   };

   const onHeaderCheckboxChange = (e) => {
      const { checked } = e.target;
      if (checked) {
         const allGroupIds = agentGroupList.map((group) => group._id);
         setSelectedAgentGroups(allGroupIds);
      } else {
         setSelectedAgentGroups([]);
      }
   };

   const handleEditAgentGroup = (groupId) => {
      navigate(`/group-settings/${groupId}`, { state: { groupId } }); // Pass groupId in state
  };
  

   return (
      <div style={{ marginTop: '20px' }}>
         <ToastContainer />
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <h4>Call group list</h4>
                     <Button className="btn btn-primary" onClick={() => navigate('/create-agent-group')}>
                        Create Call Group
                     </Button>
                  </Card.Header>
                  <Card.Body className="px-0">
                     {loading ? (
                        <Loader />
                     ) : (
                        <>
                           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                              <Button
                                 label="Delete Selected"
                                 onClick={() => setEnableDeleteModal(true)}
                                 className="p-button-danger p-button-sm"
                                 style={{ marginLeft: '10px' }}
                                 disabled={selectedAgentGroups.length === 0}
                              />
                              <Button
                                 label="Export Data"
                                 onClick={handleExport}
                                 className="p-button-active p-button-sm"
                                 style={{ marginLeft: '10px' }}
                                 disabled={buttonDisable}
                              >
                                 {buttonDisable && (
                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                 )}
                              </Button>
                           </div>

                           <DataTable value={agentGroupList} paginator rows={10}>
  {/* Checkbox Column */}
  <Column
    header={
      <Checkbox
        onChange={onHeaderCheckboxChange}
        checked={selectedAgentGroups.length === agentGroupList.length && agentGroupList.length > 0}
      />
    }
    body={(rowData) => (
      <Checkbox
        onChange={(e) => onCheckboxChange(e, rowData._id)}
        checked={selectedAgentGroups.includes(rowData._id)}
      />
    )}
    style={{ width: '3rem' }}
  />

  {/* Group ID Column */}
  <Column field="_id" header="Group ID" />

  {/* Group Name Column */}
  <Column field="group_name" header="Group Name" />

  {/* Group Owner Column */}
  <Column field="group_owner" header="Group Owner" />

  {/* Member Count Column */}
  <Column field="member_count" header="Member" />

  {/* Strategy Column */}
  <Column field="strategy" header="Strategy" />

  {/* Answered Column */}
  <Column field="answered" header="Answered" />

  {/* Missed Column */}
  <Column field="missed" header="Missed" />

  {/* Sticky Column */}
  <Column
    header="Sticky"
    body={(rowData) => (rowData.sticky ? '✅' : '❌')}
  />
  {/* Time Column */}
  <Column
  header="Time"
  body={(rowData) => {
    const timeSchedule = rowData.time_schedule?.[0]; // Access the first time_schedule object
    return timeSchedule?.time ? '✅' : '❌';
  }}
/>

  {/* Created At Column */}
  {/* <Column
    field="createdAt"
    header="Created At"
    body={(rowData) => new Date(rowData.createdAt).toLocaleString()}
  /> */}

  {/* Updated At Column */}
  <Column
    field="updatedAt"
    header="Updated At"
    body={(rowData) => new Date(rowData.updatedAt).toLocaleString()}
  />

  {/* Actions Column */}
  <Column
    header="Actions"
    body={(rowData) => (
        <div className="actions" style={{ display: 'flex', gap: '5px' }}>
            <div onClick={() => handleEditAgentGroup(rowData._id)}>
                <EditIcon title="Edit" />
            </div>
            <div>
                <DeleteIcon
                    handleCallback={() => handleDeleteSelectedAgentGroups([rowData._id])}
                    title="Delete"
                />
            </div>
        </div>
    )}
/>

</DataTable>




                        </>
                     )}
                  </Card.Body>
               </Card>
            </Col>
         </Row>

         <RenderDeleteModal
            show={enableDeleteModal}
            setShow={setEnableDeleteModal}
            setConfirmDelete={setConfirmDelete}
            isMultiDelete={true}
            clearSelectedData={() => setSelectedAgentGroups([])}
            onConfirm={handleDeleteSelectedAgentGroups}
         />
      </div>
   );
};

export default AgentGroups;
