import React, { useState, useEffect } from "react";
import { getApiCall, postApiCall } from "../../../service/httpCall";
import { Row, Col, Card, Form } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import RenderDeleteModal from "../../../components/partials/components/delete-modal";
import ToastNotification from "../../../components/Toast";
import {
  API_SERVER_MANAGEMENT_LIST,
  API_SERVER_MANAGEMENT_DELETE,
} from "../../../service/url";
import { ROUTES } from "../../../utilities/constant";
import { EditIcon, DeleteIcon } from "../../../components/setting/elements";
import Loader  from '../../../components/partials/components/loader';
import { useAuth, errorCheck } from '../auth/auth-utils';
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from '../../../utilities/constant';
import { getUser } from "../../../utilities/setting";
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import "../../../assets/custom/css/custom.css";
import exportData from '../../../utilities/export';

const ServerManagementList = () => {
  const { checkAuth } = useAuth();
  const navigate = useNavigate();
  const [enableDeleteModal, setEnableDeleteModal] = useState(false);
  const [deleteServerManagementId, setDeleteServerManagementId] = useState(undefined);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [serverManagement, setserverManagement] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSuperAdmin, checkIsSuperAdmin] = useState(false);
  const [moduleOperations, setModuleOperations] = useState({});
  const [selectedServers, setSelectedServers] = useState([]);
  const [isMultiDelete, setIsMultiDelete] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [originalDataList, setOriginalDataList] = useState([]);

  const handleExport = async () => {
    setButtonDisable(true); 
    await exportData('ServerManagement', setToastProps, setShowToast);
    setButtonDisable(false); 
  };

  const handleCheckboxToggle = (serverId) => {
    const isSelected = selectedServers.includes(serverId);
    setSelectedServers(isSelected
        ? selectedServers.filter(id => id !== serverId)
        : [...selectedServers, serverId]);
  };

  const handleMultiDelete = () => {
        setIsMultiDelete(true);
        setEnableDeleteModal(true);
  };

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    setLoading(true);
    getApiCall(API_SERVER_MANAGEMENT_LIST)
      .then(onServerManagementSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getUser().then((user) => {
      if (user) {
        if (user.role === USER_ROLES.SUPERADMIN) {
          checkIsSuperAdmin(true);
        }
        if (user?.acl_settings) {
          setModuleOperations(JSON.parse(user.acl_settings.module_operations));
        }
      }
    });
  }, []);


  function onServerManagementSuccess(resp) {
    const updatedList = resp.map((val, idx) => {
      val["key"] = idx + 1;
      val["actions"] = RenderActionComponent(val._id);
      val["createdAt"] = new Date(val["createdAt"]).toGMTString();
      return val;
    });
    setserverManagement(updatedList);
    setOriginalDataList(updatedList);
  }

  useEffect(() => {
      if(searchValue){
        setserverManagement(
          serverManagement.filter((data) => data.data_center.name.toLowerCase().includes(searchValue.trim().toLowerCase()))
        );
      }
      else {
        setserverManagement(originalDataList); // Reset to the original list if no data center is selected
      }
    }, [searchValue, serverManagement]);

  const RenderActionComponent = ({id}) => {
    const serverManagementEditRoute = ROUTES.SERVER_MANAGEMENT.EDIT.replace(
      ":id",
      id
    );
    return (
      <div className="flex align-items-center list-user-action">
        {(isSuperAdmin || moduleOperations['Server Management']?.edit) && (
          <EditIcon linkTo={`/${serverManagementEditRoute}`} />
        )}{" "}
        {(!(selectedServers.length > 0) && (isSuperAdmin || moduleOperations['Server Management']?.delete)) && (
          <DeleteIcon handleCallback={() => handleServerManagementDelete(id)} />
        )}{" "}
      </div>
    );
  }
  function handleServerManagementDelete(id) {
    checkAuth(setToastProps, setShowToast)
    setIsMultiDelete(false);
    setEnableDeleteModal(true);
    setDeleteServerManagementId(id);
  }

  const handleSelectAll = () => {
    if (selectedServers.length === serverManagement.length) {
        setSelectedServers([]);
    } else {
        const allServerIds = serverManagement.map(server => server._id);
        setSelectedServers(allServerIds);
    }
  };

  useEffect( () => {
  if (confirmDelete) {
      if (isMultiDelete) {
          postApiCall(API_SERVER_MANAGEMENT_DELETE, { serverIds: selectedServers })
              .then(onDeleteSuccess)
              .catch(onDeleteFail);
      } else {
          postApiCall(API_SERVER_MANAGEMENT_DELETE, { serverIds: [deleteServerManagementId] })
              .then(onDeleteSuccess)
              .catch(onDeleteFail);
      }
  }
  }, [confirmDelete]);

  function onDeleteSuccess(resp) {
    setToastProps({
      type: "success",
      message: "Server Managemnet deleted successfully",
    });
      let updatedList;
      if (isMultiDelete) {
         updatedList = serverManagement.filter(val => !selectedServers.includes(val._id)).map((val, key) => {
            val['key'] = key + 1;
            return val;
         });
         setSelectedServers([]);
      } else {
         updatedList = serverManagement
         .filter((val) => val._id != deleteServerManagementId)
         .map((val, key) => {
           val["key"] = key + 1;
           return val;
         });
      }
    setserverManagement(updatedList);
    setEnableDeleteModal(false);
    setDeleteServerManagementId(undefined);
    setConfirmDelete(false); 
  }

  function onDeleteFail(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
    setToastProps({
      type: "danger",
      message: `Unable to delete Server Management: ${err}`,
    });
   }
   setConfirmDelete(false); 
  }

  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     console.log(err)
   }
 }

 const handleCreateButtonClick = () => {
  navigate(`/${ROUTES.SERVER_MANAGEMENT.CREATE}`);
} 

 const rowClassName = (data) => {
  return {
      'highlighted-row': selectedServers.includes(data._id)
    };
  };

  const actionBodyTemplate = (rowData) => {
    return <RenderActionComponent id={rowData._id} />;
};

  if (loading) {
    return <Loader />
 }

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <div className="header-title">
                <h4 className="card-title">Server Management List</h4>
              </div>
              {(isSuperAdmin || moduleOperations['Server Management']?.add) && (
                <button className="btn btn-primary ml-auto" onClick={handleCreateButtonClick}>
                  Create Server
                </button>
              )}
            </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive border-bottom my-3">
                <div style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
                  <Button 
                    label="Delete Selected" 
                    onClick={handleMultiDelete} 
                    className="p-button-danger p-button-sm" 
                    style={{ marginBottom: '10px', marginLeft: '10px' }} 
                    disabled={selectedServers.length === 0} 
                  />
                  <Button 
                    label="Export Data" 
                    onClick={handleExport} 
                    className="p-button-active p-button-sm" 
                    style={{ marginBottom: '10px', marginLeft: '10px' }} 
                    disabled={buttonDisable} 
                  >
                    &nbsp;
                    {buttonDisable && (
                      <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                    )}
                  </Button>
                  <Form.Group style={{height: "45px"}}>
                    <Form.Control
                      style={{height: "50px", marginLeft: "10px", border: "1px solid #ced4da", fontSize: "15px", height: "45px"}}
                      placeholder = 'Search by Name...'
                      type="text"
                      maxLength={100}
                      value={searchValue} 
                      onChange={(e) => setSearchValue(e.target.value)}
                      required
                    />
                  </Form.Group>
                  </div>
                  <DataTable
                    value={serverManagement}
                    tableStyle={{ minWidth: "50rem" }}
                    paginator
                    rows={8}
                    rowClassName={rowClassName}
                  >
                    <Column 
                      header={<Checkbox 
                      checked={selectedServers.length === serverManagement.length && serverManagement.length > 0} 
                      onChange={handleSelectAll} 
                      />} 
                      headerStyle={{ width: '3rem' }} 
                      body={(rowData) => (
                        <Checkbox 
                          checked={selectedServers.includes(rowData._id)} 
                          onChange={() => handleCheckboxToggle(rowData._id)} 
                        />
                        )} 
                      />
                    <Column
                      field="data_center.name"
                      filterField="data_center.name"
                      header="Data Center"
                      filter
                      filterPlaceholder="search by Data Center"
                    ></Column>

                    <Column
                      field="type"
                      filterField="type"
                      header="Type"
                      filter
                      filterPlaceholder="search type"
                    ></Column>
                    <Column
                      field="server_ip"
                      filterField="server_ip"
                      header="Server IP"
                      filter
                      filterPlaceholder="search server ip"
                    ></Column>
                    <Column
                      field="server_name"
                      filterField="server_name"
                      header="Server Name"
                      filter
                      filterPlaceholder="search by server name"
                    ></Column>
                    <Column
                      field="os"
                      filterField="os"
                      header="OS"
                      filter
                      filterPlaceholder="search OS"
                    ></Column>
                    <Column
                      field="cpu_cores"
                      filterField="cpu_cores"
                      header="CPU Cores"
                      filter
                      filterPlaceholder="search CPU Cores"
                    ></Column>
                    <Column
                      field="ram"
                      filterField="ram"
                      header="RAM"
                      filter
                      filterPlaceholder="search RAM"
                    ></Column>
                    <Column
                      field="hard_disk"
                      filterField="hard_disk"
                      header="Hard Disk"
                      filter
                      filterPlaceholder="search Hard Disk"
                    ></Column>
                    <Column field="createdAt" header="Created Date"></Column>
                    {((isSuperAdmin || moduleOperations['Server Management']?.edit || moduleOperations['Server Management']?.delete)) && (
                    <Column header="Action" body={actionBodyTemplate} />
                    )}
                  </DataTable>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <RenderDeleteModal 
         show={enableDeleteModal} 
         setShow={setEnableDeleteModal} 
         setConfirmDelete={setConfirmDelete} 
         isMultiDelete={isMultiDelete} 
         clearSelectedData={() => setSelectedServers([])}
      />

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
};

export default ServerManagementList;
