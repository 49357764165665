import React, { useState, useCallback , useEffect} from "react";
import ReactFlow, { addEdge, Handle, ReactFlowProvider, MarkerType, applyNodeChanges, applyEdgeChanges } from
"react-flow-renderer";
import { v4 as uuidv4 } from "uuid";
import { getApiCall, postApiCall } from '../../../service/httpCall';
import { API_IVR_SETTINGS, API_IVR_CREATE, API_GET_PROMPTS } from '../../../service/url';
import { useParams, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utilities/constant';
import ToastNotification from '../../../components/Toast';
import { IVR_LABELS , WEEK_DAYS} from '../../../utilities/constant'
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { FaCog, FaTrash  } from 'react-icons/fa';


const nodeTypes = {
  answerNode: AnswerNode,
  gatherNode: GatherNode,
  hangupNode: HangupNode,
  playNode: PlayNode,
  transferNode: TransferNode
};

function AnswerNode({ data, id }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [handles, setHandles] = useState(1);
   const [formData, setFormData] = useState({
      successWebhook: '',
      failureWebhook: '',
    });

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, 
    }));
    };

    const handleAddNodeData  = () => {
      if (data.updateNodeData) {
        data.updateNodeData(id, formData);
      }
      closeModal();
    };

  return (
    <div
      style={{
        width: `${220 + Object.keys(handles).length * 20}px`,
        height: '80px',
        border: "2px solid #007bff",
        borderRadius: "8px",
        padding: "10px",
        background: "#f9f9f9",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Handle 
        type="source" 
        position="bottom" 
        id="new-target-handle"
        style={{ left: "50%", transform: "translateX(-50%)",backgroundColor: "#007bff" }}
      />
      <span style={{
              fontSize: "20px",
            }}>{data.label}</span>
          <div
              style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              }}
            >
              <FaCog
              title="Setting"
              style={{
                  color: "#007bff",
                  fontSize: "30px",
                  cursor: "pointer",
              }}
              onClick={openModal}
              />
          </div>

            {/* Action Type Answer/hangup model  */}
            <Modal 
              size="xl" 
              // show={showModal && (selectedOption === 'answer' || selectedOption === 'hangup')}
              show={modalIsOpen}
              onHide={closeModal}
              backdrop="static"
              style={{ zIndex: 1050 }}>
                <Modal.Header closeButton>
                  {/* <Modal.Title>{selectedOption === 'answer' ? 'Answer Modal' : 'Hangup Modal'}</Modal.Title> */}
                  <Modal.Title>{'Answer Modal'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                    <Form.Group controlId="failureWebhook" style={{ flex: 1 }}>
                        <Form.Label>SMS Webhook Failure API</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="failureWebhook"
                          value={formData.failureWebhook}
                          onChange={handleChange}
                        />
                      </Form.Group>
      
                      <Form.Group controlId="successWebhook" style={{ flex: 1 }}>
                        <Form.Label>SMS Webhook Success API</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="successWebhook"
                          value={formData.successWebhook}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleAddNodeData}>
                    Add
                  </Button>
                  <Button variant="secondary" onClick={closeModal}>
                    Discard
                  </Button>
                </Modal.Footer>
              </Modal>
    </div>
  );
}

function GatherNode({ data, id }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [handles, setHandles] = useState({});
  const [audio, setAudio] = useState([]);
  const [inputFormData, setInputFormData] = useState({
      type: '',             
      dtmfBargein: '',     
      finishOnKey: '',      
      numDigits: '',        
      timeout: '',          
      play: false,          
      audio: '',            
      timeoutSecs: '', 
      successWebhook: '',
      failureWebhook: '',
      dtmfWebhook: '',
      numSources: []
    });

  useEffect(() => {
      getApiCall(`${API_GET_PROMPTS}?prompt_status = 1`)
        .then(onPromptsSuccess)
        .catch(onError)
    }, []);
  
    function onPromptsSuccess(resp) {
      setAudio(resp)
    }

    function onError(err) {
      console.log(err);
  }

  const openModal = () => setModalIsOpen(true);;
  const closeModal = () => setModalIsOpen(false);

  const handleSave = () => {
    const updatedHandles = Object.keys(handles).map((key) => ({
      id: key,
      ...handles[key], 
    }));

    if (data.updateNodeData) {
      data.updateNodeData(id, { selectedHandles: handles, ... inputFormData  });
    }
    closeModal();
  };

  const handleRemoveNode = (nodeId) => {
    if (data.onRemove) {
      data.onRemove(nodeId);
    }
  }

  const toggleHandle = (index) => {
    setHandles((prevHandles) => {
      const handleId = `${id}_handle-${index}`;
      if (prevHandles[handleId]) {
        const { [handleId]: _, ...restHandles } = prevHandles;
        return restHandles;
      } else {
        return {
          ...prevHandles,
          [handleId]: {
            type: "source", 
            position: "bottom",
            left: index * 20 + 10,
          },
        };
      }
    });

    setInputFormData((prevFormData) => {
      const updatedNumSources = prevFormData.numSources.includes(index)
        ? prevFormData.numSources.filter((item) => item !== index) 
        : [...prevFormData.numSources, index]; 

      return {
        ...prevFormData,
        numSources: updatedNumSources 
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setInputFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <div
      style={{
        width: `${220 + Object.keys(handles).length * 20}px`,
        height: '80px',
        border: "2px solid #007bff",
        borderRadius: "8px",
        padding: "10px",
        background: "#f9f9f9",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Handle
        type="target"
        position="top"
        id= {id}
        style={{ left: "50%", transform: "translateX(-50%)",backgroundColor: "#007bff" }}
      />
      {Object.keys(handles).map((handleId) => {
              const handle = handles[handleId];
              return (
                <Handle
                  key={handleId}
                  type={handle.type}
                  position={handle.position}
                  id={handleId}
                  style={{ left: `${handle.left}px`, backgroundColor: "#007bff" }}
                />
              );
            })}
      <span style={{
              fontSize: "20px",
            }}>{data.label}</span>
          <div
              style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              }}
            >
              <FaCog
              title="Setting"
              style={{
                  color: "#007bff",
                  fontSize: "30px",
                  cursor: "pointer",
              }}
              onClick={openModal}
              />
      
              <FaTrash
              title="Remove"
              style={{
                  color: "#007bff",
                  fontSize: "30px",
                  cursor: "pointer",
              }}
              onClick={() => {
                  handleRemoveNode(id);
              }}
              />
           </div>    
     {/* Action Type Input model  */}
           <Modal
            show={modalIsOpen}
            onHide={closeModal}
             backdrop="static"
             style={{ zIndex: 1050 }}
             size="xl"
           >
             <Modal.Header closeButton>
               <Modal.Title>Input Model</Modal.Title>
             </Modal.Header>
             <Modal.Body>
               <Form>
                 <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                   <Form.Group controlId="typeSelect" style={{ flex: 1 }}>
                     <Form.Label>Type:</Form.Label>
                     <Form.Control
                       as="select"
                       name="type"
                       value={inputFormData.type}
                       onChange={handleInputChange}
                     >
                       <option value="">Select Type</option>
                       <option value="digits">Digits</option>
                       <option value="speech">Speech</option>
                     </Form.Control>
                   </Form.Group>
     
                   <Form.Group controlId="dtmfBargein" style={{ flex: 1 }}>
                     <Form.Label>DTMF Barge-in:</Form.Label>
                     <Form.Control
                       as="select"
                       name="dtmfBargein"
                       value={inputFormData.dtmfBargein}
                       onChange={handleInputChange}
                     >
                       <option value="">Select True or False</option>
                       <option value="true">True</option>
                       <option value="false">False</option>
                     </Form.Control>
                   </Form.Group>
                 </div>
     
                 <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                   <Form.Group controlId="finishOnKey" style={{ flex: 1 }}>
                     <Form.Label>Finish On Key:</Form.Label>
                     <Form.Control
                       type="text"
                       name="finishOnKey"
                       value={inputFormData.finishOnKey}
                       onChange={handleInputChange}
                     />
                   </Form.Group>
     
                   <Form.Group controlId="numDigits" style={{ flex: 1 }}>
                     <Form.Label>Num Digits:</Form.Label>
                     <Form.Control
                       type="number"
                       name="numDigits"
                       value={inputFormData.numDigits}
                       onChange={handleInputChange}
                     />
                   </Form.Group>
                 </div>
     
                 <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                   <Form.Group controlId="timeout" style={{ flex: 1}}>
                     <Form.Label>Wait Time(s):</Form.Label>
                     <Form.Select
                       name="timeout"
                       value={inputFormData.timeout}
                       onChange={handleInputChange}
                       style={{ padding: '10px'}}
                     >
                       {[...Array(11).keys()].map((num) => (
                         <option key={num} value={num}>
                           {num}
                         </option>
                       ))}
                     </Form.Select>
                   </Form.Group>
                   <Form.Group controlId="failureWebhook" style={{ flex: 1 }}>
                       <Form.Label>SMS Webhook dtmf API</Form.Label>
                       <Form.Control
                         as="textarea"
                         rows={2}
                         name="dtmfWebhook"
                         value={inputFormData.dtmfWebhook}
                         onChange={handleInputChange}
                       />
                     </Form.Group>
                 </div>
     
                 <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                   <Form.Group controlId="failureWebhook" style={{ flex: 1 }}>
                       <Form.Label>SMS Webhook Failure API</Form.Label>
                       <Form.Control
                         as="textarea"
                         rows={2}
                         name="failureWebhook"
                         value={inputFormData.failureWebhook}
                         onChange={handleInputChange}
                       />
                     </Form.Group>
                     
     
                     <Form.Group controlId="successWebhook" style={{ flex: 1 }}>
                       <Form.Label>SMS Webhook Success API</Form.Label>
                       <Form.Control
                         as="textarea"
                         rows={2}
                         name="successWebhook"
                         value={inputFormData.successWebhook}
                         onChange={handleInputChange}
                       />
                     </Form.Group>
                   </div>
     
                 <div style={{ marginBottom: '10px' }}>
                   <Form.Group controlId="playCheckbox" style={{display: 'flex', gap: '10px'}}>
                     <Form.Check
                       type="checkbox"
                       name="play"
                       checked={inputFormData.play}
                       onChange={handleInputChange}
                     />
                     <Form.Label>Play</Form.Label>
                   </Form.Group>
                 </div>
     
                 {inputFormData.play && (
                   <>
                     <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                       <Form.Group controlId="audioSelect" style={{ flex: 1 }}>
                         <Form.Label>Select Audio</Form.Label>
                         <Form.Control
                           as="select"
                           name="audio"
                           value={inputFormData.audio}
                           onChange={handleInputChange}
                         >
                         <option value="">Select Audio</option>
                         {audio.map((actionKey) => {
                           return (
                             <option key={actionKey.prompt_url} value={actionKey.prompt_url}>
                               {actionKey.prompt_name}
                             </option>
                           );
                         })}
                         </Form.Control>
                       </Form.Group>
     
                       <Form.Group controlId="timeoutSecs" style={{ flex: 1}}>
                         <Form.Label>Wait Time(s):</Form.Label>
                         <Form.Select
                           name="timeoutSecs"
                           value={inputFormData.timeoutSecs}
                           onChange={handleInputChange}
                           style={{ padding: '10px'}}
                         >
                           {[...Array(11).keys()].map((num) => (
                             <option key={num} value={num}>
                               {num}
                             </option>
                           ))}
                         </Form.Select>
                       </Form.Group>
                     </div>
                   </>
                 )}
     
                 <div style={{ marginBottom: '10px' }}>
                   <Form.Group controlId="checkboxGroup" style={{ display: 'flex', flexDirection: 'column' }}>
                     <Form.Label>Digits (Select Values):</Form.Label>
                     <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
                       {Array.from({ length: 12 }).map((_, index) => (
                        <label key={index}>
                          <input
                            type="checkbox"
                            checked={handles[`${id}_handle-${index}`] !== undefined}
                            onChange={() => toggleHandle(index)}
                            style={{marginRight: '5px'}}
                          />
                          {IVR_LABELS[index]}
                        </label>
                      ))}
                     </div>
                   </Form.Group>
                 </div>
     
               </Form>
             </Modal.Body>
             <Modal.Footer>
               <Button variant="primary" onClick={handleSave}>
                 Add
               </Button>
               <Button variant="secondary" onClick={closeModal}>
                 Discard
               </Button>
             </Modal.Footer>
           </Modal>
    </div>
  );
}

function HangupNode({ data, id }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [handles, setHandles] = useState(1);
   const [formData, setFormData] = useState({
      successWebhook: '',
      failureWebhook: '',
    });

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, 
    }));
    };

    const handleAddNodeData  = () => {
      if (data.updateNodeData) {
        data.updateNodeData(id, formData);
      }
      closeModal();
    };

    const handleRemoveNode = (nodeId) => {
      if (data.onRemove) {
        data.onRemove(nodeId);
      }
    }

  return (
    <div
      style={{
        width: `${220 + Object.keys(handles).length * 20}px`,
        height: '80px',
        border: "2px solid #007bff",
        borderRadius: "8px",
        padding: "10px",
        background: "#f9f9f9",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Handle 
        type="target" 
        position="top" 
        id="new-target-handle"
        style={{ left: "50%", transform: "translateX(-50%)",backgroundColor: "#007bff" }}
      />
      <span style={{
              fontSize: "20px",
            }}>{data.label}</span>
          <div
              style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              }}
            >
              <FaCog
              title="Setting"
              style={{
                  color: "#007bff",
                  fontSize: "30px",
                  cursor: "pointer",
              }}
              onClick={openModal}
              />
      
              <FaTrash
              title="Remove"
              style={{
                  color: "#007bff",
                  fontSize: "30px",
                  cursor: "pointer",
              }}
              onClick={() => {
                  handleRemoveNode(id);
              }}
              />
          </div>
            {/* Action Type Answer/hangup model  */}
            <Modal 
              size="xl" 
              // show={showModal && (selectedOption === 'answer' || selectedOption === 'hangup')}
              show={modalIsOpen}
              onHide={closeModal}
              backdrop="static"
              style={{ zIndex: 1050 }}>
                <Modal.Header closeButton>
                  {/* <Modal.Title>{selectedOption === 'answer' ? 'Answer Modal' : 'Hangup Modal'}</Modal.Title> */}
                  <Modal.Title>{'Hangup Modal'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                    <Form.Group controlId="failureWebhook" style={{ flex: 1 }}>
                        <Form.Label>SMS Webhook Failure API</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="failureWebhook"
                          value={formData.failureWebhook}
                          onChange={handleChange}
                        />
                      </Form.Group>
      
                      <Form.Group controlId="successWebhook" style={{ flex: 1 }}>
                        <Form.Label>SMS Webhook Success API</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="successWebhook"
                          value={formData.successWebhook}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleAddNodeData}>
                    Add
                  </Button>
                  <Button variant="secondary" onClick={closeModal}>
                    Discard
                  </Button>
                </Modal.Footer>
              </Modal>
    </div>
  );
}

function PlayNode({ data, id }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [handles, setHandles] = useState(1);
  const [audio, setAudio] = useState([])
  const [playFormData, setPlayFormData] = useState({
      audio: '',
      timeoutSecs: '',
      loop: '',
      successWebhook: '',
      failureWebhook: '',
    });

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

   useEffect(() => {
      getApiCall(`${API_GET_PROMPTS}?prompt_status = 1`)
        .then(onPromptsSuccess)
        .catch(onError)
    }, []);
  
    function onPromptsSuccess(resp) {
      setAudio(resp)
    }

    function onError(err) {
      console.log(err);
  }

  const handlePlayInputChange = (e) => {
    const { name, value } = e.target;
    setPlayFormData((prevState) => ({
      ...prevState,
      [name]: value, 
    }));
    };

    const handleAddPlayNode  = () => {
      if (data.updateNodeData) {
        data.updateNodeData(id, playFormData);
      }
      closeModal();
   };

   const handleRemoveNode = (nodeId) => {
    if (data.onRemove) {
      data.onRemove(nodeId);
    }
  }

  return (
    <div
      style={{
        width: `${220 + Object.keys(handles).length * 20}px`,
        height: '80px',
        border: "2px solid #007bff",
        borderRadius: "8px",
        padding: "10px",
        background: "#f9f9f9",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Handle 
        type="target" 
        position="top" 
        id="new-target-handle"
        style={{ left: "50%", transform: "translateX(-50%)",backgroundColor: "#007bff" }}
      />
      <Handle 
        type="source" 
        position="bottom" 
        id="new-target-handle"
        style={{ left: "50%", transform: "translateX(-50%)",backgroundColor: "#007bff" }}
      />
      <span style={{
              fontSize: "20px",
            }}>{data.label}</span>
          <div
              style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              }}
            >
              <FaCog
              title="Setting"
              style={{
                  color: "#007bff",
                  fontSize: "30px",
                  cursor: "pointer",
              }}
              onClick={openModal}
              />
      
              <FaTrash
              title="Remove"
              style={{
                  color: "#007bff",
                  fontSize: "30px",
                  cursor: "pointer",
              }}
              onClick={() => {
                  handleRemoveNode(id);
              }}
              />
          </div>

        {/* Action Type Play model  */}
        <Modal 
          size="xl"
          // show={showPlayModal && selectedOption === 'play'}
          // onHide={() => setShowPlayModal(false)}
          show={modalIsOpen}
          onHide={closeModal}
          backdrop="static"
          style={{ zIndex: 1050 }}>
          <Modal.Header closeButton>
            <Modal.Title>Play Model</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                <Form.Group controlId="exampleForm.SelectCustom" style={{ flex: 1 }}>
                  <Form.Label>Select Audio</Form.Label>
                  <Form.Control
                    as="select"
                    name="audio"
                    value={playFormData.audio}
                    onChange={handlePlayInputChange}
                  >
                    <option value="">Select Audio</option>
                    {audio.map((actionKey) => {
                      return (
                        <option key={actionKey.prompt_url} value={actionKey.prompt_url}>
                          {actionKey.prompt_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="timeoutSecs" style={{ flex: 1 }}>
                  <Form.Label>Timeout Seconds</Form.Label>
                  <Form.Control
                    type="number"
                    name="timeoutSecs"
                    value={playFormData.timeoutSecs}
                    onChange={handlePlayInputChange}
                  />
                </Form.Group>
              </div>

              <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
              <Form.Group controlId="failureWebhook" style={{ flex: 1 }}>
                  <Form.Label>SMS Webhook Failure API</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    name="failureWebhook"
                    value={playFormData.failureWebhook}
                    onChange={handlePlayInputChange}
                  />
                </Form.Group>
                

                <Form.Group controlId="successWebhook" style={{ flex: 1 }}>
                  <Form.Label>SMS Webhook Success API</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    name="successWebhook"
                    value={playFormData.successWebhook}
                    onChange={handlePlayInputChange}
                  />
                </Form.Group>
              </div>

              <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                <Form.Group controlId="loop" style={{ flex: 1}}>
                  <Form.Label>Retry:</Form.Label>
                  <Form.Select
                    name="loop"
                    value={playFormData.loop}
                    onChange={handlePlayInputChange}
                    style={{ padding: '10px'}}
                  >
                    {[...Array(4).keys()].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleAddPlayNode}>
              Add
            </Button>
            <Button variant="secondary" onClick={closeModal}>
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
}

function TransferNode({ data, id }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [handles, setHandles] = useState(1);
  const [audio, setAudio] = useState([])
  const [playFormData, setPlayFormData] = useState({
      audio: '',
      timeoutSecs: '',
      loop: '',
      successWebhook: '',
      failureWebhook: '',
    });

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

   useEffect(() => {
      getApiCall(`${API_GET_PROMPTS}?prompt_status = 1`)
        .then(onPromptsSuccess)
        .catch(onError)
    }, []);
  
    function onPromptsSuccess(resp) {
      setAudio(resp)
    }

    function onError(err) {
      console.log(err);
  }

  const handlePlayInputChange = (e) => {
    const { name, value } = e.target;
    setPlayFormData((prevState) => ({
      ...prevState,
      [name]: value, 
    }));
    };

    const handleAddPlayNode  = () => {
      if (data.updateNodeData) {
        data.updateNodeData(id, playFormData);
      }
      closeModal();
   };

   const handleRemoveNode = (nodeId) => {
    if (data.onRemove) {
      data.onRemove(nodeId);
    }
  }

  return (
    <div
      style={{
        width: `${220 + Object.keys(handles).length * 20}px`,
        height: '80px',
        border: "2px solid #007bff",
        borderRadius: "8px",
        padding: "10px",
        background: "#f9f9f9",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Handle 
        type="target" 
        position="top" 
        id="new-target-handle"
        style={{ left: "50%", transform: "translateX(-50%)",backgroundColor: "#007bff" }}
      />
      <Handle 
        type="source" 
        position="bottom" 
        id="new-target-handle"
        style={{ left: "50%", transform: "translateX(-50%)",backgroundColor: "#007bff" }}
      />
       <span style={{
              fontSize: "20px",
            }}>{data.label}</span>
          <div
              style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              }}
            >
              <FaCog
              title="Setting"
              style={{
                  color: "#007bff",
                  fontSize: "30px",
                  cursor: "pointer",
              }}
              onClick={openModal}
              />
      
              <FaTrash
              title="Remove"
              style={{
                  color: "#007bff",
                  fontSize: "30px",
                  cursor: "pointer",
              }}
              onClick={() => {
                  handleRemoveNode(id);
              }}
              />
          </div>

     {/* Transfer model  */}
        
    </div>
  );
}

function App() {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [nodeConnections, setNodeConnections] = useState({});
  const [flowName, setFlowName] = useState('')
  const [actions, setAction] = useState({});
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [weekDays, setWeekDays] = useState([]);
  const [timeScheduleModel, setTimeScheduleModel] = useState(false);
  const [previousTime, setPreviousTime] = useState({ start: "", end: "" });
  const [previousWeekDays, setPreviousWeekDays] = useState([]);
  const [scheduleData , setScheduleData] = useState({})
  const [isWholeDay, setIsWholeDay] = useState(false);
  const [isWholeWeek, setIsWholeWeek] = useState(false);
  const {cid} = useParams();
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
      getApiCall(API_IVR_SETTINGS)
        .then(onIvrSettingSuccess)
        .catch(onError)
    }, []);
  
  function onIvrSettingSuccess(resp) {
    setAction(resp?.actions)
  }

  useEffect( () => {
  
      if(toastProps){
         setShowToast(true);
      }
  
   }, [toastProps]);

  function onError(err) {
    console.log(err);
  }

   const onConnect = useCallback(
      (connection) => {
        const { source, target } = connection;
        const sourceNode = nodes.find((node) => node.id === source);
        const sourceAction = sourceNode.data?.action;
    
        setNodeConnections((prevConnections) => {
          if (sourceAction === "gather") {
            const sourceHandle = connection.sourceHandle;
            const match = sourceHandle.match(/handle-(\d+)/);
            let dtmf
            if (match) {
              dtmf = match[1];
            }
            const isSource1Connected = prevConnections[sourceHandle]?.length > 0;
    
            if (isSource1Connected) {
              setToastProps({
                message: "Each source can only connect to one node!",
                type: "danger",
              });
              return prevConnections;
            }
    
            const updatedConnections = {
              ...prevConnections,
              [sourceHandle]: [...(prevConnections[sourceHandle] || []), target],
            };

            const sourceHandleIndex =
            parseInt(connection.sourceHandle.split("-")[1], 10);
            const sourceNode = nodes.find((node) => node.id === connection.source);
            const validHandles = sourceNode.data.selectedHandles;
        
            if (validHandles && validHandles.hasOwnProperty(`${source}_handle-${sourceHandleIndex}`)) {
              setEdges((eds) => addEdge(
                {
                  ...connection,
                  markerEnd: { type: MarkerType.Arrow },
                  label: `dtmf: ${IVR_LABELS[dtmf]}`,
                  labelStyle: { fill: "blue", fontWeight: "bold" },
                },
                eds
              ));
            }
            return updatedConnections;
          }
    
          if (sourceAction !== "gather" && (prevConnections[source]?.length || 0) >= 1) {
            setToastProps({
              message: "A node can only have one child!",
              type: "danger",
            });
            return prevConnections;
          }
          const updatedConnections = {
            ...prevConnections,
            [source]: [...(prevConnections[source] || []), target],
          };
    
          setEdges((eds) =>
            addEdge(
              { ...connection, markerEnd: { type: MarkerType.Arrow } },
              eds
            )
          );
    
          return updatedConnections;
        });
      },
      [nodes, setEdges, setNodeConnections]
    );

  const onNodeDragStop = (event, node) => {
    setNodes((nds) =>
      nds.map((n) => (n.id === node.id ? { ...n, position: node.position } : n))
    );
  };

  const addNewNode = (type) => {
    const newNode = {
      id: `${nodes.length + 1}`,
      type: `${type}Node`,
      position: { x: Math.random() * 400, y: Math.random() * 400 },
      data: {
        label: `${type} Node`,
        action: type,
        onRemove: onRemove,
        ...(type === 'gather' && {
          selectedHandles: {
            "handle-0": {
              type: "source",
              position: "bottom",
              left: 10,
            },
          },
        }),
      },
    };
    setNodes((nds) => [...nds, newNode]);
  };

  const onRemove = (nodeId) => {
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
};


    const onNodesChange = useCallback(
      (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
      []
    );
  
    const onEdgesChange = useCallback(
      (changes) => {
        setEdges((eds) => {
          const updatedEdges = applyEdgeChanges(changes, eds);
    
          changes.forEach((change) => {
            if (change.type === "remove") {
              const { source, target } = change;
              setNodeConnections((prevConnections) => ({
                ...prevConnections,
                [source]: prevConnections[source]?.filter((child) => child !== target),
              }));
            }
          });
    
          return updatedEdges;
        });
      },
      []
    );

    const updateNodeData = (nodeId, updatedData) => {
       setNodes((nds) =>
         nds.map((node) =>
           node.id === nodeId ? { ...node, data: { ...node.data,...updatedData } } : node
         )
       );
   
       const removedHandles = [];
       nodes.forEach((node) => {
         if (node.id === nodeId) {
           const currentHandles = node.data.selectedHandles || {};
           removedHandles.push(
             ...Object.keys(currentHandles).filter(
               (handleId) => !updatedData.selectedHandles.hasOwnProperty(handleId)
             )
           );
         }
       });
   
       setEdges((eds) =>
         eds.filter(
           (edge) =>
             !(
               edge.source === nodeId &&
               removedHandles.includes(edge.sourceHandle)
             )
         )
       );
     };
   
    
      useEffect(() => {
        setEdges((eds) =>
          eds.filter(
            (edge) =>
              !nodes.find((node) => edge.source === node.id && (node.data?.selectedHandles  && !node.data.selectedHandles[edge.sourceHandle]))
          )
        );
      }, [nodes]);

      useEffect(() => {
        const result = (edges).reduce((acc, { source, target , sourceHandle}) => {
          if (sourceHandle === null) {
           if (!acc[source]) {
             acc[source] = [];
           }
           acc[source].push(target);
          } else {
           if (!acc[sourceHandle]) {
             acc[sourceHandle] = [];
           }
           acc[sourceHandle].push(target);
          }
           
           return acc;
         }, {});
       setNodeConnections(result)
      }, [edges]);

  useEffect(() => {
      handleActionClick('answer')
    }, []);
  
    const handleActionClick = (type) => {
      addNewNode(type)
    };

 const handleWholeDayChange = (e) => {
    const isChecked = e.target.checked;
    setIsWholeDay(isChecked); 
    if (isChecked) {
      setPreviousTime({ start: startTime, end: endTime });
      setStartTime("00:00");
      setEndTime("23:59");
    } else {
      setStartTime(previousTime.start || "");
      setEndTime(previousTime.end || "");
    }
  };

  const handleWholeWeekChange = (e) => {
    const isChecked = e.target.checked;
    setIsWholeWeek(isChecked); 
    if (isChecked) {
      setPreviousWeekDays(weekDays);
      setWeekDays(WEEK_DAYS);
    } else {
      setWeekDays(previousWeekDays || []);
    }
  };

  const handleSetSchedule = () => {
     setScheduleData({
      start_time: startTime,
      end_time: endTime,
      week_days: weekDays
     })
     setTimeScheduleModel(false)
  };

  const saveData = async() => {
    //change format
     const nodesData = transformNodes(nodes);
     const transformJsonData = JSON.stringify({nodesData,edges}, null, 2);
     await createIVR(transformJsonData);
  };

  const transformNodes = (nodesArray) => {
    const transformedNodes = nodesArray.reduce((acc, node, index) => {
      const edgeData = edges.filter(obj => obj.source === node.id);
      const nodeId = index.toString();
  
      const flowJson = {
        verb: node.data?.action,
        ...(node.data?.action === 'play' && {
          url: node.data?.audio,
          timeoutSecs: node.data?.timeoutSecs,
          loop: node.data?.loop,
          actionHook: '/play/action',
          successWebhook: node.data?.successWebhook,
          failureWebhook: node.data?.failureWebhook
        }),
        ...(node.data?.action === 'gather' && {
          actionHook: 'http://example.com/collect',
          type: node.data?.type,
          dtmfBargein: node.data?.dtmfBargein,
          finishOnKey: node.data?.finishOnKey,
          numDigits: node.data?.numDigits,
          timeout: node.data?.timeout,
          successWebhook: node.data?.successWebhook,
          failureWebhook: node.data?.failureWebhook,
          dtmfWebhook: node.data?.dtmfWebhook,
          numSources: node.data?.numSources,
          ...(node.data?.play && {
            play: {
              url: node.data?.audio,
              timeoutSecs: node.data?.timeoutSecs,
            },
          }),
        }),
        ...((node.data?.action === 'answer' || node.data?.action === 'hangup') && {
          successWebhook: node.data?.successWebhook,
          failureWebhook: node.data?.failureWebhook
        }),
      };
  
      let  flowControl = {};
  
      if (node.data?.action === 'gather') {
        edgeData.forEach((edge, edgeIndex) => {
          const targetNode = nodesArray.find(n => n.id === edge.target);
          
          if (targetNode) {
            const targetNodeId = nodesArray.indexOf(targetNode);
            if (edge.source === edge.target) {
                flowControl[`dtmf_${IVR_LABELS[node.data?.numSources[edgeIndex]]}`] = {
                  flowName: flowName,
                  nodeId: targetNodeId,
                  isLoop: true,  
                };
            } else {
              flowControl[`dtmf_${IVR_LABELS[node.data?.numSources[edgeIndex]]}`] = {
                flowName: flowName,
                nodeId: targetNodeId,
              };
            }
          }
        });
      } else {
        if (edgeData.length > 0) {
          const targetNode = nodesArray.find(n => n.id === edgeData[0].target);
          
          if (targetNode) {
            const targetNodeId = nodesArray.indexOf(targetNode);
            flowControl.default = {
              flowName: flowName,
              nodeId: targetNodeId,
            };
          }
        }
      }
  
     
  
      acc[nodeId] = {
        id: nodeId,
        flowJson: flowJson,
        flowRender: {
          ...node,
        },
        flowControl: flowControl,
      };
  
      return acc;
    }, {});
  
    return {
      flowName: flowName,
      callCenterId: cid,
      nodes: transformedNodes,
      scheduleData : scheduleData
    };
  };

  function validateCreateIVR() {
    let valid = true;
  
    if (nodes.length <= 0) {
       valid = false;
       setToastProps({ message: 'Atleast Add one Node', type: 'danger' });
    } else if (!flowName) {
      valid = false;
      setToastProps({ message: 'Flow Name is required', type: 'danger' });
    } 
    
    return valid;
  
  }
  
    async function createIVR(data) {
      if (!validateCreateIVR()) {
        return;
      }
      const apiIvrCreateUrl = API_IVR_CREATE.replace(':cid', cid);
      postApiCall(apiIvrCreateUrl, data)
         .then(onIVRCreateSuccess)
         .catch(onIVRCreateError);
    }
  
   function onIVRCreateSuccess(resp) {
      setToastProps({ message: 'IVR Created Successfully', type: 'success' });
      setTimeout(() => {
        navigate(`/${ROUTES.IVR.LIST.replace(':cid', cid)}`);
      }, 1500);
   }
  
   function onIVRCreateError(err) {
     setToastProps({message: JSON.stringify(err), type: 'danger'});
   }

  return (
    <div style={{ height: "100vh", width: "100%" , position: "relative" }}>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes.map((node) => ({
            ...node,
            data: { ...node.data, updateNodeData },
          }))}
          edges={edges}
          onConnect={onConnect}
          onNodeDragStop={onNodeDragStop}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          fitView
          // style={{ height: "calc(100vh - 60px)" }}
        />
      </ReactFlowProvider>
      <div style={{ position: "absolute", top: "10px", right: "10px", zIndex: 10 }}>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px", 
          }}
        >
          <div style={{ flex: 1 }}>
            <input
              id="flow-name"
              type="text"
              value={flowName}
              onChange={(e) => setFlowName(e.target.value)}
              placeholder="Enter Flow Name"
              style={{
                padding: "8px",
                width: "100%",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </div>

          <button
            onClick={() => setTimeScheduleModel(true)}
            style={{
              padding: "8px 16px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              marginLeft: "5px",
            }}
          >
            IVR Schedule
          </button>
        </div>

        <div style={{ textAlign: "right" }}>
          <button
            onClick={saveData}
            style={{
              padding: "8px 16px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Save IVR
          </button>
        </div>
      </div>


        <div
          style={{
            position: "absolute",
            top: "100px",
            right: "0",
            width: "80px",
            backgroundColor: "black",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            borderRadius: "4px",
            zIndex: 5,
          }}
        >
          {Object.keys(actions).map((type) => (
            <div
              key={type}
              style={{
                backgroundColor: "white",
                color: "black",
                textAlign: "center",
                cursor: "pointer",
                margin: "1px",
                padding: '10px'
              }}
              onClick={() => handleActionClick(type)}
            >
              {actions[type]}
            </div>
          ))}
        </div>
        
      </div>

      {/* Ivr Schedule module */}
          <Modal show={timeScheduleModel} onHide={() => setTimeScheduleModel(false)} size="lg" centered>
                <Modal.Header closeButton >
                  <Modal.Title>Set Time Schedule Of IVR</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                  <Row>
                    <Col>
                      <Form>
                        <Form.Check
                          type="checkbox"
                          label="Whole Day"
                          id="wholeDay"
                          className="mb-3"
                          checked={isWholeDay} 
                          onChange={handleWholeDayChange}
                        />
                        <Form.Group className="mb-3">
                          <Form.Label>Start Time</Form.Label>
                          <Form.Control
                            type="time"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            disabled={isWholeDay}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>End Time</Form.Label>
                          <Form.Control
                            type="time"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            disabled={isWholeDay}
                          />
                        </Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="Whole Week"
                          id="wholeWeek"
                          className="mb-3"
                          checked={isWholeWeek}
                          onChange={handleWholeWeekChange}
                        />
                        <Form.Group className="mb-3">
                          <Form.Label>Select Days</Form.Label>
                          <div>
                            {WEEK_DAYS.map((day) => (
                              <Form.Check
                                inline
                                key={day}
                                label={day}
                                type="checkbox"
                                checked={weekDays.includes(day)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setWeekDays((prev) => [...prev, day]);
                                  } else {
                                    setWeekDays((prev) => prev.filter((d) => d !== day));
                                  }
                                }}
                                disabled={isWholeWeek}
                              />
                            ))}
                          </div>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setTimeScheduleModel(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSetSchedule}>Set Schedule</Button>
                </Modal.Footer>
            </Modal> 

      <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />
    </div>
  );
}

const buttonStyle = {
  backgroundColor: "#007bff",
  color: "white",
  padding: "8px 16px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  transition: "background-color 0.3s",
};

const smallButtonStyle = {
  backgroundColor: "#007bff",
  color: "white",
  padding: "4px 8px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "12px",
  marginTop: "8px",
  transition: "background-color 0.3s",
};

const modalStyle = {
  content: {
    padding: "20px",
    width: "300px",
    height: "auto",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9998,
  },
};

const nodeBoxStyle = {
  padding: "10px",
  border: "2px solid #007bff",
  borderRadius: "8px",
  background: "#f9f9f9",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
};

const handleStyle = {
  backgroundColor: "#007bff",
  borderRadius: "50%",
  width: "12px",
  height: "12px",
};

const addNodeButtonStyle = {
  backgroundColor: "#28a745",
  color: "white",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  backgroundColor: "transparent",
  border: "none",
  fontSize: "18px",
  color: "#007bff",
  cursor: "pointer",
  padding: "5px",
  borderRadius: "50%",
};

export default App;