import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function RenderShowPasswordModal(props) {
  const handleClose = () => {
    props.setShow(false);
  };
  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>User Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props?.passwordValue}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RenderShowPasswordModal;
