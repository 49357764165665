import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button,Table } from "react-bootstrap";
import Card from "../../../components/Card";
import ToastNotification from "../../../components/Toast";
import { API_USERS_SIGNUP } from "../../../service/url";
import { postApiCall,getApiCall } from "../../../service/httpCall";
import { useNavigate } from "react-router-dom";
import {getUserCreateAccessRoleLabel,ifUserAssociateCompany, getUser} from "../../../utilities/setting";
import { validatePhone } from "../../../utilities/helpers";
import RenderUserCompanyFields from "../../../components/partials/dashboard/users/company";
import { ROUTES } from "../../../utilities/constant";
import { useAuth, errorCheck } from '../auth/auth-utils';
import { API_ACL_SETTING_LIST } from "../../../service/url";
import { USER_ROLES } from '../../../utilities/constant';
import AsyncStorage from '@react-native-async-storage/async-storage';

const UserAdd = () => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPincode, setCompanyPincode] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [activeUserAccessRole, setActiveUserAccessRole] = useState(undefined);
  const [aclSettingList, setAClSettingList] = useState([]);
  const [selectedACL, setSelectedACL] = useState("");
  const [ifRenderCompanyFields, setIfRenderCompanyFields] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [loading, setLoading] = useState(true);
  const [isCompanyAdmin, setCompanyAdmin] = useState(false);
  const [isCallCenterAdmin, setCallCenterAdmin] = useState(false);
  const [licence, setSelectedLicence] = useState("");


  useEffect(() => {
    async function superadminCheck() {
      const user = JSON.parse(await AsyncStorage.getItem('user'));
      if (user.role === USER_ROLES.SUPERADMIN) {
        setIsSuperAdmin(true);
      }
      if (user.role === USER_ROLES.COMPANY_ADMIN) {
        setCompanyAdmin(true);
      }
      if (user.role === USER_ROLES.CALL_CENTRE_ADMIN) {
        setCallCenterAdmin(true);
      }
      setLoading(false);
    }
    superadminCheck();
  }, []);

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    if (!loading) { 
      getApiCall(API_ACL_SETTING_LIST, {})
        .then(onAClSettingListSuccess)
        .catch(onError);
    }
  }, [loading]);

  useEffect(() => {
    checkAuth(setToastProps, setShowToast)
  }, []);

  useEffect(() => {
    getUserCreateAccessRoleLabel()
      .then((resp) => {
        if (resp) {
          setActiveUserAccessRole(resp[0].value);

          const isUserAssociatedCompany = ifUserAssociateCompany(resp[0].value);
          setIfRenderCompanyFields(isUserAssociatedCompany);
        }
      })
      .catch(onError);
  }, []);


  function onAClSettingListSuccess(resp) {
    if (isSuperAdmin) {
      setAClSettingList(resp);
    } else {
      getUser().then((user) => {
        if (user) {
          if (user?.acl_settings) {
            setAClSettingList([user.acl_settings]);
          }
        }
      });
    }
  }


  function validateForm() {
    let valid = true;
    if (!username.trim()) {
      valid = false;
      setToastProps({ message: "Username required", type: "danger" });
    }
    if (!name.trim()) {
      valid = false;
      setToastProps({ message: "Name required", type: "danger" });
    }
    if (!email.trim()) {
      valid = false;
      setToastProps({ message: "Name required", type: "danger" });
    } else if (!password.trim()) {
      valid = false;
      setToastProps({ message: "Password required", type: "danger" });
    } else if (password.trim().length < 6) {
      valid = false;
      setToastProps({
        message: "Password must be of minimum 6 characters",
        type: "danger",
      });
    } else if (!confirmPassword.trim()) {
      valid = false;
      setToastProps({ message: "Confirm passowrd required", type: "danger" });
    } else if (password.trim() != confirmPassword.trim()) {
      valid = false;
      setToastProps({
        message: "Password and confirm password not matching",
        type: "danger",
      });
    } else if (!licence) {
      valid = false;
      setToastProps({
        message: "Licence Is required",
        type: "danger",
      });
    } else if (ifRenderCompanyFields) {
      valid = validateCompanyFields();
    }

    if (isSuperAdmin || isCompanyAdmin || isCallCenterAdmin) {
      if (!selectedUserType.trim()) {
        valid = false;
        setToastProps({ message: "UserType required", type: "danger" });
      }
    }

    return valid;
  }

  function validateCompanyFields() {
    let valid = true;

    if (!companyName.trim()) {
        valid = false;
        setToastProps({ message: "Company name required", type: "danger" });
    } else if (!validatePhone(companyPhone)) {
        valid = false;
        setToastProps({
            message: "Company phone invalid format",
            type: "danger",
        });
    } else if (!companyPincode.trim()) {
        valid = false;
        setToastProps({ message: "Company pincode required", type: "danger" });
    } else if (!/^\d{4}$/.test(companyPincode)) {
        valid = false;
        setToastProps({ message: "Company pincode must be exactly 4 digits", type: "danger" });
    } else if (!companyAddress.trim()) {
        valid = false;
        setToastProps({ message: "Company address required", type: "danger" });
    }

    return valid;
}


  async function submitForm() {
    setButtonDisable(true);

    if (!validateForm()) {
      setButtonDisable(false);
      return;
    }

    const companyParams = {
      name: companyName,
      phone: companyPhone,
      pincode: companyPincode,
      address: companyAddress,
    };
    const params = {
      username,
      password,
      name,
      email,
      company: companyParams,
      acl_settings: (selectedACL === "") ? null: selectedACL,
      licence: licence
    };

    params.role = (isSuperAdmin || isCompanyAdmin || isCallCenterAdmin) ? selectedUserType : activeUserAccessRole

    postApiCall(API_USERS_SIGNUP, params)
      .then(onUserCreateSuccess)
      .catch(onUserCreateError);
  }

  function onUserCreateSuccess(resp) {
    setToastProps({ message: "User created successfully", type: "success" });
    setTimeout(() => {
      navigate(`/${ROUTES.USERS.LIST}`);
    }, 1500);
  }

  function onUserCreateError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     setButtonDisable(false);
     setToastProps({message: JSON.stringify(err), type: 'danger'});
   }
  }

  
  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
     errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
     console.log(err)
    }
 }


  return (
    <>
      <div>
        <Row>
          <Col xl="12" lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">New User Information</h4>
                  {/* <RenderUserAccessRolesList
                    activeUserAccessRole={activeUserAccessRole}
                    userAccessRoles={userAccessRoles}
                    setActiveUserAccessRole={setActiveUserAccessRole}
                  /> */}
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-user-info">
                  <form>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Control
                          type="text"
                          id="username"
                          placeholder="User Name"
                          maxLength={100}
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="Name"
                          maxLength={100}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Control
                          type="email"
                          id="email"
                          placeholder="Email"
                          maxLength={100}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                          <Form.Select
                            id="aclSetting"
                            required
                            value={selectedACL}
                            className="p-2"
                            onChange={(e) => setSelectedACL(e.target.value)}
                          >
                            <option value={""}>ACL Setting</option>
                            {aclSettingList.map(function (val, idx) {
                              return (
                                <option key={idx} value={val._id}>
                                  {val.acl_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Control
                          type="password"
                          id="pass"
                          placeholder="Password"
                          maxLength={100}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Control
                          type="password"
                          id="rpass"
                          placeholder="Confirm Password"
                          maxLength={100}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>
                    {ifRenderCompanyFields ? (
                      RenderUserCompanyFields({
                        company_name: companyName,
                        set_company_name: setCompanyName,
                        company_phone: companyPhone,
                        set_company_phone: setCompanyPhone,
                        company_address: companyAddress,
                        set_company_address: setCompanyAddress,
                        company_pincode: companyPincode,
                        set_company_pincode: setCompanyPincode,
                      })
                    ) : (
                      <></>
                    )}
                    {/* Row with userType and licence dropdowns */}
                      {(isSuperAdmin || isCompanyAdmin || isCallCenterAdmin) ? (
                        <div className="row">
                          {/* userType Dropdown */}
                          <Form.Group className="col-md-6 form-group">
                            <Form.Select
                              id="userType"
                              required
                              value={selectedUserType}
                              className="p-2"
                              onChange={(e) => setSelectedUserType(e.target.value)}
                            >
                              <option value="" disabled hidden>
                                UserType
                              </option>
                              {isSuperAdmin && (
                                <>
                                  <option key="1" value="role_sub_superadmin">
                                    Sub-Superadmin
                                  </option>
                                  <option key="2" value="role_reseller">
                                    Reseller
                                  </option>
                                </>
                              )}
                              {isCompanyAdmin && (
                                <>
                                  <option key="1" value="role_cadmin">
                                    Company User
                                  </option>
                                  <option key="2" value="role_ccadmin">
                                    Call-Center
                                  </option>
                                </>
                              )}
                              {isCallCenterAdmin && (
                                <>
                                  <option key="1" value="role_ccadmin">
                                    Call Centre Admin User
                                  </option>
                                  <option key="2" value="role_ccagent">
                                    Call Centre Admin Agent
                                  </option>
                                  <option key="3" value="role_ccteamlead">
                                    Call Centre Admin Team Lead
                                  </option>
                                </>
                              )}
                            </Form.Select>
                          </Form.Group>

                          {/* Licence Dropdown */}
                          <Form.Group className="col-md-6 form-group">
                            <Form.Select
                              id="licence"
                              required
                              value={licence}
                              className="p-2"
                              onChange={(e) => setSelectedLicence(e.target.value)}
                            >
                              <option value="" disabled hidden>
                                Select Licence
                              </option>
                              {Array.from({ length: 10 }, (_, i) => (
                                <option key={(i + 1) * 10} value={(i + 1) * 10}>
                                  {(i + 1) * 10}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      ) : (
                        /* Row with only licence dropdown when userType is not rendered */
                        <div className="row">
                          <Form.Group className="col-md-6 form-group">
                            <Form.Select
                              id="licence"
                              required
                              value={licence}
                              className="p-2"
                              onChange={(e) => setSelectedLicence(e.target.value)}
                            >
                              <option value="" disabled hidden>
                                Select Licence
                              </option>
                              {Array.from({ length: 10 }, (_, i) => (
                                <option key={(i + 1) * 10} value={(i + 1) * 10}>
                                  {(i + 1) * 10}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      )}
                    <Button
                      onClick={async () => {
                        submitForm();
                      }}
                      type="button"
                      variant="btn btn-primary"
                      disabled={buttonDisable}
                    >
                      Add New User &nbsp;
                      {buttonDisable ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                    </Button>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
};

export default UserAdd;
