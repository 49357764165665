import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { getApiCall } from "../../../service/httpCall"; // Adjust the path if necessary
import Loader from "../../../components/partials/components/loader";
import ToastNotification from "../../../components/Toast";
import { BACKEND_API_BASE_URL } from "../../../utilities/constant";
import "./index.css";

const ViewVoiceFiles = () => {
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setLoading(true);
    // API call to fetch prompts with prompt_status: 1
    getApiCall(`${BACKEND_API_BASE_URL}/v1/prompt?prompt_status=1`)
      .then(onPromptsSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);

  const onPromptsSuccess = (resp) => {
    const updatedList = resp.map((item, index) => ({
      ...item,
      key: index + 1,
    }));
    setPrompts(updatedList);
  };

  const onError = (error) => {
    setToastProps({
      type: "danger",
      message: `Failed to load prompts: ${error}`,
    });
    setShowToast(true);
  };

  const actionBodyTemplate = (rowData) => {
    return <span className="status-text">Approved</span>;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="card" style={{ marginTop: "20px" }}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="card-title">Voice List</h4>
        </div>
        <div className="card-body">
          <DataTable
            value={prompts}
            paginator
            rows={10}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column field="prompt_name" header="Name" filterPlaceholder="Search by name" />
            <Column field="prompt_url" header="URL" filterPlaceholder="Search by URL" />
            <Column field="prompt_duration" header="Duration" filterPlaceholder="Search by duration" />
            <Column field="prompt_category" header="Category" filterPlaceholder="Search by category" />
            <Column header="Status" body={actionBodyTemplate} style={{ textAlign: "center" }} />
          </DataTable>
        </div>
      </div>
      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </div>
  );
};

export default ViewVoiceFiles;
