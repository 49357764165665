import React, { useEffect, useState } from "react";
import Index from "../views/dashboard/index";
import { Navigate } from "react-router-dom";
import { isUserAuthenticated } from "../utilities/setting";

import { ROUTES } from "../utilities/constant";

// users
// import UserProfile from '../views/dashboard/app/user-profile';
import UserAdd from "../views/dashboard/app/user-add";
import UserEdit from "../views/dashboard/app/user-edit";
import UserList from "../views/dashboard/app/user-list";

//Company
import CompanyList from "../views/dashboard/company/list";
import CompanyUpdate from "../views/dashboard/company/edit";
import CompanyCreate from "../views/dashboard/company/create";

//Call Centre
import CallCentreList from "../views/dashboard/call-centre/list";
import CallCentreCreate from "../views/dashboard/call-centre/create";
import CallCentreUpdate from "../views/dashboard/call-centre/edit";
import CallCentreUsersList from "../views/dashboard/call-centre/user-list";
import CallCentreUserCreate from "../views/dashboard/call-centre/user-add";
import CallCentreUserEdit from "../views/dashboard/call-centre/user-edit";

//Trunks
import { TrunkCreate, TrunkList, TrunkEdit } from "../views/dashboard/trunks";
import {
  DataCenterList,
  DataCenterCreate,
  DataCenterEdit,
} from "../views/dashboard/data-center";

//IVR
import { IvrCreate, IvrList, IvrEdit, IvrView } from "../views/dashboard/ivr";

// import userProfileEdit from '../views/dashboard/app/user-privacy-setting';

//admin
// import Admin from '../views/dashboard/admin/admin';
import DefaultRouter1 from "./default-router1";
import DefaultTemp from "../layouts/dashboard/default-temp";
import UserAllocate from "../views/dashboard/app/user-allocate";

//Numbers
import {
  NumbersCreate,
  NumbersList,
  NumbersEdit,
  NumberFileList,
  StatusChangeActionList
} from "../views/dashboard/numbers";

//server-management
import {
  ServerManagementCreate,
  ServerManagementEdit,
  ServerManagementList,
} from "../views/dashboard/server-management";

import {AgentGroups, CreateAgent, Extention, UserAgent} from '../views/dashboard/manage-agents'
import {Agents} from '../views/dashboard/manage-agents'

//module
import { ModuleCreate,ModuleList,ModuleEdit, ACLSettingEdit, ACLSettingList, ACLSettingCreate } from "../views/dashboard/module";
import JourneyOperationList from "../views/dashboard/journey-operation/list";
import CreateAgentGroups from "../views/dashboard/manage-agents/createAgentGroups";
import CreateExtention from "../views/dashboard/manage-agents/createExtention";
import { Queues } from "../views/manage-queues";
import GroupSettings from "../views/dashboard/manage-agents/groupSettings";
import AddVoice from "../views/dashboard/ivr/voice-library/add-voice";
import ViewVoice from "../views/dashboard/ivr/voice-library/view-voice";
import { ManagePrompts } from "../views/dashboard/voice-library";
import Tts from "../views/dashboard/voice-library/View-voice-files";
import InboundCalls from '../views/dashboard/ivr/inbound-calls/inboundCalls'
import UserResetPassword from "../views/dashboard/app/user-reset-pass";








export const DefaultRouter = async () => {

  const defaultRouter = [
    {
      path: "/",
      element: <DefaultRouter1 />,
      children: [
        {
          path: "/",
          element: <Navigate to={ROUTES.DASHBOARD} replace />,
        },
        {
          path: ROUTES.DASHBOARD,
          element: <Index />,
        },
        {
          path: ROUTES.USERS.EDIT,
          element: <UserEdit />,
        },
        {
          path: ROUTES.USERS.RESET_PASSWORD,
          element: <UserResetPassword />,
        },
        {
          path: ROUTES.USERS.CREATE,
          element: <UserAdd />,
        },
        {
          path: ROUTES.USERS.LIST,
          element: <UserList />,
        },
        {
          path: ROUTES.COMPANIES.LIST,
          element: <CompanyList />,
        },
        {
          path: ROUTES.COMPANIES.EDIT,
          element: <CompanyUpdate />,
        },
        {
          path: ROUTES.COMPANIES.CREATE,
          element: <CompanyCreate />,
        },
        {
          path: ROUTES.CALL_CENTRES.CREATE,
          element: <CallCentreCreate />,
        },
        {
          path: ROUTES.CALL_CENTRES.LIST,
          element: <CallCentreList />,
        },
        {
          path: ROUTES.CALL_CENTRES.EDIT,
          element: <CallCentreUpdate />,
        },
        {
          path: ROUTES.CALL_CENTRES.USERS.LIST,
          element: <CallCentreUsersList />,
        },
        {
          path: ROUTES.CALL_CENTRES.USERS.CREATE,
          element: <CallCentreUserCreate />,
        },
        {
          path: ROUTES.CALL_CENTRES.USERS.EDIT,
          element: <CallCentreUserEdit />,
        },
        {
          path: ROUTES.TRUNKS.CREATE,
          element: <TrunkCreate />,
        },
        {
          path: ROUTES.TRUNKS.LIST,
          element: <TrunkList />,
        },
        {
          path: ROUTES.TRUNKS.EDIT,
          element: <TrunkEdit />,
        },
        {
          path: ROUTES.NUMBERS.CREATE,
          element: <NumbersCreate />,
        },
        {
          path: ROUTES.NUMBERS.LIST,
          element: <NumbersList />,
        },
        {
          path: ROUTES.NUMBERS.EDIT,
          element: <NumbersEdit />,
        },
        {
          path: ROUTES.NUMBERS.NUMBER_FILE_LIST,
          element: <NumberFileList />,
        },
        {
          path: ROUTES.NUMBERS.STATUS_CHANGE_ACTION,
          element: <StatusChangeActionList />,
        },
        {
          path: ROUTES.NUMBERS.DID_MAPPING,
          element: <UserList />,
        },
        {
          path: ROUTES.IVR.CREATE,
          element: <IvrCreate />,
        },
        {
          path: ROUTES.IVR.LIST,
          element: <IvrList />,
        },
        {
          path: ROUTES.IVR.EDIT,
          element: <IvrEdit />,
        },
        {
          path: ROUTES.IVR.VIEW,
          element: <IvrView />,
        },
        {
          path: ROUTES.DATA_CENTER.CREATE,
          element: <DataCenterCreate />,
        },
        {
          path: ROUTES.DATA_CENTER.LIST,
          element: <DataCenterList />,
        },
        {
          path: ROUTES.DATA_CENTER.EDIT,
          element: <DataCenterEdit />,
        },
        {
          path: ROUTES.SERVER_MANAGEMENT.CREATE,
          element: <ServerManagementCreate />,
        },
        {
          path: ROUTES.SERVER_MANAGEMENT.LIST,
          element: <ServerManagementList />,
        },
        {
          path: ROUTES.SERVER_MANAGEMENT.EDIT,
          element: <ServerManagementEdit />,
        },
        {
          path: ROUTES.MODULE.CREATE,
          element: <ModuleCreate />,
        },
        {
          path: ROUTES.MODULE.LIST,
          element: <ModuleList />,
        },
        {
          path: ROUTES.MODULE.EDIT,
          element: <ModuleEdit />,
        },
        {
          path: ROUTES.ACL_SETTING.CREATE,
          element: <ACLSettingCreate />,
        },
        {
          path: ROUTES.ACL_SETTING.LIST,
          element: <ACLSettingList />,
        },
        {
          path: ROUTES.ACL_SETTING.EDIT,
          element: <ACLSettingEdit />,
        },
        {
          path: ROUTES.JOURNEY_OPERATION.LIST,
          element: < JourneyOperationList/>,
        },
        {
          path: ROUTES.MANAGE_AGENTS.CREATE_AGENT,
          element: <CreateAgent/>,
        },
        {
          path: ROUTES.MANAGE_AGENTS.AGENTS,
          element: <Agents/>,
        },
        {
          path: ROUTES.MANAGE_AGENTS.AGENT_GROUP_LIST,
          element: <AgentGroups/>,
        },
        {
          path: ROUTES.MANAGE_AGENTS.CREATE_AGENT_GROUP,
          element: <CreateAgentGroups/>,
        },
        {
          path: ROUTES.MANAGE_AGENTS.EXTENTION,
          element: <Extention/>,
        },
        {
          path: ROUTES.MANAGE_AGENTS.CREATE_EXTENTION,
          element: <CreateExtention/>,
        },
        {
          path: ROUTES.MANAGE_QUEUES.QUEUES,
          element: <Queues/>,
        },
        {
          path: ROUTES.USERS.RESELLERALLOCATE,
          element: < UserAllocate/>,
        },
        {
          path: ROUTES.MANAGE_AGENTS.GROUP_SETTINGS,
          element: < GroupSettings/>,
        },
        {
          path: ROUTES.IVR.ADD_VOICE,
          element: < AddVoice/>,
        },
        {
          path: ROUTES.VOICE_LIBRARY.MANAGE_PROMPTS,
          element: < ManagePrompts/>,
        },
        {
          path: ROUTES.IVR.VIEW_VOICE,
          element: < ViewVoice/>,
        },
        {
          path: ROUTES.VOICE_LIBRARY.VIEW_VOICE_FILES,
          element: < Tts/>,
        },
        {
          path: ROUTES.IVR.INBOUND_CALLS,
          element: < InboundCalls/>,
        },
      ],
    },
  ];

  return defaultRouter;
};

// const DefaultRouter = () => {
//     return (
//         <TransitionGroup>
//             {/* <CSSTransition classNames="fadein" timeout={300}> */}
//                 <Switch>
//                     <Route path="/dashboard" exact component={userAuthenticated ? Index : navigateToLogin} />
//                     {/* user */}
//                     <Route path="/dashboard/app/user-profile"     exact component={UserProfile} />
//                     <Route path="/dashboard/app/user-add"         exact component={UserAdd}/>
//                     <Route path="/dashboard/app/user-list"        exact component={UserList}/>
//                     <Route path="/dashboard/app/user-privacy-setting" exact component={userProfileEdit}/>
//                      {/* widget */}
//                      <Route path="/dashboard/widget/widgetbasic"   exact component={Widgetbasic}/>
//                      <Route path="/dashboard/widget/widgetcard"    exact component={Widgetcard}/>
//                      <Route path="/dashboard/widget/widgetchart"   exact component={Widgetchart}/>
//                      {/* icon */}
//                      <Route path="/dashboard/icon/solid"           exact component={Solid}/>
//                      <Route path="/dashboard/icon/outline"         exact component={Outline}/>
//                      <Route path="/dashboard/icon/dual-tone"       exact component={DualTone}/>
//                      {/* From */}
//                      <Route path="/dashboard/form/form-element"    exact component={FormElement}/>
//                      <Route path="/dashboard/form/form-validation" exact component={FormValidation}/>
//                      <Route path="/dashboard/form/form-wizard"     exact component={FormWizard}/>
//                      {/* table */}
//                      <Route path="/dashboard/table/bootstrap-table" exact component={BootstrapTable}/>
//                      <Route path="/dashboard/table/table-data"      exact component={TableData}/>
//                      {/*special pages */}
//                      <Route path="/dashboard/special-pages/billing" exact component={Billing}/>
//                      <Route path="/dashboard/special-pages/kanban" exact component={Kanban}/>
//                      <Route path="/dashboard/special-pages/pricing" exact component={Pricing}/>
//                      <Route path="/dashboard/special-pages/timeline" exact component={Timeline}/>
//                      <Route path="/dashboard/special-pages/calender" exact component={Calender}/>
//                      {/* map */}
//                      <Route path="/dashboard/map/vector" exact component={Vector}/>
//                      <Route path="/dashboard/map/google" exact component={Google}/>
//                      {/* extra */}
//                      <Route path="/dashboard/extra/privacy-policy" exact component={PrivacyPolicy}/>
//                      <Route path="/dashboard/extra/terms-of-service" exact component={TermsofService}/>
//                      {/*admin*/}
//                      <Route path="/dashboard/admin/admin" exact component={Admin}/>
//                 </Switch>
//             {/* </CSSTransition> */}
//         </TransitionGroup>
//     )
// }

// export { DefaultRouter }
