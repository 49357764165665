import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Button } from 'primereact/button';
import { postApiCall } from '../../../service/postApiCall';
import { API_EXTENTION } from '../../../service/url';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import { getUser } from '../../../utilities/setting';

const CreateExtension = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [extension, setExtension] = useState('');
    const [description, setDescription] = useState('');
    const [rolePrefix, setRolePrefix] = useState(''); // Prefix from data.prefix
    const [buttonDisable, setButtonDisable] = useState(false);
    const [extensionError, setExtensionError] = useState('');

    useEffect(() => {
        getUser()
            .then((data) => {
                setRolePrefix(String(data.prefix)); // Set the 2-digit prefix
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
                toast.error('Failed to fetch user data.');
            });
    }, []);

    const handleSaveExtension = async () => {
        if (extensionError) {
            toast.error('Please fix the errors before submitting.');
            return;
        }

        const fullExtension = `${rolePrefix}${extension}`; // Combine rolePrefix and extension

        const payload = {
            username,
            password,
            extention: fullExtension, // Send full 5-digit extension to backend
            description,
        };

        try {
            setButtonDisable(true); // Disable button during API call
            await postApiCall(API_EXTENTION, payload);
            toast.success('Extension created successfully.');
            setTimeout(() => navigate('/extention'), 3000); // Navigate after 3 seconds
        } catch (error) {
            console.error('Error creating extension:', error);
            const errorMessage =
                error?.response?.data?.message || 'Failed to create extension.';
            toast.error(errorMessage);
        } finally {
            setButtonDisable(false); // Re-enable button after API call
        }
    };

    const handleExtensionChange = (e) => {
        const input = e.target.value;

        // Ensure the input is a 3-digit number within the range 100 to 999
        if (/^\d{0,3}$/.test(input) && Number(input) >= 100 && Number(input) <= 999) {
            setExtension(input);
            setExtensionError(''); // Clear error if valid
        } else {
            setExtension(input);
            setExtensionError('Extension must be a 3-digit number between 100 and 999.');
        }
    };

    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-block justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Create Extension</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <form>
                                    <Row className="mb-3">
                                        <Col md="4">
                                            <label htmlFor="username" className="form-label">
                                                Username
                                            </label>
                                            <input
                                                type="text"
                                                id="username"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                className="form-control"
                                                placeholder="Enter Username"
                                                required
                                            />
                                        </Col>
                                        <Col md="4">
                                            <label htmlFor="password" className="form-label">
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                id="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="form-control"
                                                placeholder="Enter Password"
                                                required
                                            />
                                        </Col>
                                        <Col md="4">
                                            <label htmlFor="extension" className="form-label">
                                                Extension
                                            </label>
                                            <div className="d-flex">
                                                <span className="input-group-text">{rolePrefix}</span> {/* Prefix */}
                                                <input
                                                    type="number"
                                                    id="extension"
                                                    value={extension}
                                                    onChange={handleExtensionChange}
                                                    className={`form-control ${extensionError ? 'is-invalid' : ''}`}
                                                    placeholder="Enter 3-digit Extension"
                                                    required
                                                />
                                            </div>
                                            {extensionError && (
                                                <div className="invalid-feedback">{extensionError}</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <label htmlFor="description" className="form-label">
                                                Description
                                            </label>
                                            <textarea
                                                id="description"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                className="form-control"
                                                placeholder="Enter Description"
                                                rows="3"
                                            />
                                        </Col>
                                    </Row>
                                    <Button
                                        label="Create"
                                        onClick={handleSaveExtension}
                                        className="p-button-danger mt-3"
                                        type="button"
                                        disabled={buttonDisable}
                                    >
                                        {buttonDisable && (
                                            <span
                                                className="spinner-border spinner-border-sm ml-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                    </Button>
                                </form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ToastContainer position="top-right" autoClose={3000} />
        </>
    );
};

export default CreateExtension;
