import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import { API_CODECS_LIST, API_OPERATORS_LIST, API_SERVER_MANAGEMENT_LIST } from "../../../service/url";
import { getApiCall } from "../../../service/httpCall";
import {
  TRUNK_AUTH_TYPES,
  OPERATOR_TYPES,
  CONTENT_STATUS,
  TRUNK_AUTH_TYPE_IP,
  TRUNK_AUTH_TYPE_REGISTRATION,
} from "../../../utilities/constant";

function RenderAuthenticationFields(props) {
  props = props["props"];

  if (props.authType == TRUNK_AUTH_TYPE_IP) {
    return (
      <Form.Group className="col-md-12 form-group">
        {props.isEditMode && <Form.Label htmlFor="identify_by">Identify By:</Form.Label>}
        <Form.Control
          placeholder = 'Identify By'
          as="textarea"
          id="identify_by"
          maxLength={1000}
          rows={3}
          value={props.ipIdentifyBy}
          onChange={(e) => props.setIpIdentifyBy(e.target.value)}
          required
        />
      </Form.Group>
    );
  } else if (props.authType == TRUNK_AUTH_TYPE_REGISTRATION) {
    return (
      <>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && <Form.Label htmlFor="username">Authentication username:</Form.Label>}
          <Form.Control
            type="text"
            placeholder="Authentication username:"
            id="username"
            maxLength={100}
            value={props.authUsername}
            onChange={(e) => props.setAuthUsername(e.target.value)}
            autoComplete="off"
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode &&  <Form.Label htmlFor="password">Authentication password:</Form.Label>}
          <Form.Control
            type="password"
            placeholder="Authentication password:"
            id="password"
            maxLength={100}
            value={props.authPassword}
            onChange={(e) => props.setAuthPassword(e.target.value)}
            autoComplete="off"
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
        {props.isEditMode && <Form.Label htmlFor="server_url">
            Authentication server url:
          </Form.Label>}
          <Form.Control
            type="text"
            placeholder="Authentication server url:"
            id="server_url"
            maxLength={100}
            value={props.authServerUrl}
            onChange={(e) => props.setAuthServerUrl(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
        {props.isEditMode && <Form.Label htmlFor="client_url">
            Authentication client url:
          </Form.Label>}
          <Form.Control
            type="text"
            id="client_url"
            placeholder="Authentication client url:"
            maxLength={100}
            value={props.authClientUrl}
            onChange={(e) => props.setAuthClientUrl(e.target.value)}
            required
          />
        </Form.Group>
      </>
    );
  }

  return <></>;
}

export default function RenderTrunkForm(props) {
  const [codecsList, setCodecsList] = useState([]);
  const [operatorsList, setOperatorsList] = useState([]);
  const [serverList, setServerList] = useState([]);

  useEffect(() => {
    getApiCall(API_CODECS_LIST, {}).then(onCodecsListSuccess).catch(onError);

    getApiCall(API_OPERATORS_LIST, {})
      .then(onOperatorListSuccess)
      .catch(onError);

      getApiCall(API_SERVER_MANAGEMENT_LIST, {})
      .then(onServerListSuccess)
      .catch(onError);  
  }, []);

  function onCodecsListSuccess(resp) {
    setCodecsList(resp);
  }

  function onOperatorListSuccess(resp) {
    setOperatorsList(resp);
  }

  function onServerListSuccess(resp) {
    setServerList(resp);
}

  function onError(err) {
    console.log(err);
  }

  return (
    <>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && <Form.Label htmlFor="name">Name:</Form.Label>}
          <Form.Control
            type="text"
            id="name"
            placeholder="Name"
            maxLength={100}
            value={props.name}
            onChange={(e) => props.setName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="auth_type">Authentication Type:</Form.Label>
          )}
          <Form.Select
            id="auth_type"
            className="p-2"
            required
            value={props.authType}
            placeholder="Authentication Type"
            onChange={(e) => props.setAuthType(e.target.value)}
          >
            <option value={""}>Select Authentication Type</option>
            {TRUNK_AUTH_TYPES.map(function (val, idx) {
              return (
                <option key={idx} value={val.value}>
                  {val.label}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </div>
      <div className="row">
        <RenderAuthenticationFields props={props} />
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="domain">Domain:</Form.Label>
          )}
          <Form.Control
            type="text"
            id="domain"
            maxLength={100}
            value={props.domain}
            placeholder="Domain"
            onChange={(e) => props.setDomain(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="port">Port Number:</Form.Label>
          )}
          <Form.Control
            type="number"
            id="port"
            min={1024}
            max={65535}
            value={props.port}
            placeholder="Port"
            onChange={(e) => props.setPort(e.target.value)}
            required
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group" placeholder="Select codecs">
          {props.isEditMode && (
            <Form.Label htmlFor="codecs">Select codecs:</Form.Label>
          )}
          <Form.Select
            id="codecs"
            className="p-2"
            required
            value={props.codec}
            onChange={(e) => props.setCodec(e.target.value)}
          >
            <option value={""}>Select codec</option>
            {codecsList.map(function (val, idx) {
              return (
                <option key={idx} value={val._id}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="piolt_number">Pilot Number:</Form.Label>
          )}
          <Form.Control
            type="text"
            id="pilot_number"
            maxLength={100}
            value={props.pilotNumber}
            placeholder="Pilot Number"
            onChange={(e) => props.setPilotNumber(e.target.value)}
            required
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="operator">Select Operator:</Form.Label>
          )}
          <Form.Select
            id="operator"
            className="p-2"
            required
            value={props.operator}
            onChange={(e) => props.setOperator(e.target.value)}
          >
            <option value={""}>Select Operator</option>
            {operatorsList.map(function (val, idx) {
              return (
                <option key={idx} value={val._id}>
                  {val.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="chanels">Channels:</Form.Label>
          )}
          <Form.Control
            type="number"
            id="chanels"
            placeholder="Channels"
            min={0}
            value={props.channels}
            onChange={(e) => props.setChannels(e.target.value)}
            required
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="cps">Calls per sec:</Form.Label>
          )}
          <Form.Control
            type="number"
            id="cps"
            placeholder="Calls pers sec"
            min={0}
            value={props.cps}
            onChange={(e) => props.setCps(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="col-md-6 form-group">
          {props.isEditMode && (
            <Form.Label htmlFor="status">Status:</Form.Label>
          )}
          <Form.Select
            id="status"
            className="p-2"
            required
            value={props.status}
            onChange={(e) => props.setStatus(e.target.value)}
          >
            <option value={""}>Status</option>
            {CONTENT_STATUS.map(function (val, idx) {
              return (
                <option key={idx} value={val.value}>
                  {val.label}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </div>
      <div className="row">
                <Form.Group className="col-md-6 form-group">
                {props.isEditMode && (
                   <Form.Label htmlFor="server">Server:</Form.Label>
                    )}
                    <Form.Select id="operator" required value={props.server} className="p-2" onChange={e => props.setServer(e.target.value)}>
                        <option value={''}>Select Server</option>
                        {
                            serverList.map(function (val, idx) {
                                return (<option key={idx} value={val._id}>{val.server_name}</option>)
                            })
                        }
                    </Form.Select>
                </Form.Group>
            </div>
    </>
  );
}
