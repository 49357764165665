import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Card from '../../../components/Card'
import { getApiCall, deleteApiCallWithHeader } from '../../../service/httpCall';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import { ROUTES, USER_STATUS_LABEL, TRUNK_AUTH_TYPES, OPERATOR_TYPES } from '../../../utilities/constant';
import { EditIcon, DeleteIcon } from '../../../components/setting/elements';
import { API_TRUNKS_LIST, API_TRUNKS_DELETE } from '../../../service/url';
import Loader  from '../../../components/partials/components/loader';
import { USER_ROLES } from '../../../utilities/constant';
import { getUser } from "../../../utilities/setting";
import { postApiCall} from "../../../service/httpCall";
import { Checkbox } from 'primereact/checkbox';
import "../../../assets/custom/css/custom.css";
import exportData from '../../../utilities/export';

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';
import { useAuth, errorCheck } from '../auth/auth-utils';
import { useNavigate } from "react-router-dom";


const authTypeLabels = TRUNK_AUTH_TYPES.map(val => val['label']);
const operatorTypeLabels = OPERATOR_TYPES.map(val => val['label']);


export default function TrunkList() {
   const { checkAuth } = useAuth();
   const navigate = useNavigate();
   const [trunksList, setTrunksList] = useState([]);
   const [filters, setFilters] = useState({
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      domain: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      port: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
      auth_type: { value: null, matchMode: FilterMatchMode.EQUALS },
      operator: { value: null, matchMode: FilterMatchMode.EQUALS }
   });
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [deleteTrunkId, setDeleteTrunkId] = useState(undefined);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [loading, setLoading] = useState(false);
   const [isSuperAdmin, checkIsSuperAdmin] = useState(false);
   const [moduleOperations, setModuleOperations] = useState({});
   const [selectedTrunks, setSelectedTrunks] = useState([]);
   const [isMultiDelete, setIsMultiDelete] = useState(false);
   const [buttonDisable, setButtonDisable] = useState(false);

   const [searchValue, setSearchValue] = useState(null);
   const [originalTrunkList, setOriginalTrunkList] = useState([]);

   const handleExport = async () => {
      setButtonDisable(true); 
      await exportData('Trunks', setToastProps, setShowToast);
      setButtonDisable(false); 
    };

   const handleCheckboxToggle = (trunkId) => {
      const isSelected = selectedTrunks.includes(trunkId);
      setSelectedTrunks(isSelected
          ? selectedTrunks.filter(id => id !== trunkId)
          : [...selectedTrunks, trunkId]);
  };

  const handleMultiDelete = () => {
        setIsMultiDelete(true);
        setEnableDeleteModal(true);
  };

   useEffect(() => {

      if (toastProps) {
         setShowToast(true);
      }

   }, [toastProps]);

   useEffect(() => {
      setLoading(true);
      getApiCall(API_TRUNKS_LIST)
         .then(onTrunkListSuccess)
         .catch(onError)
         .finally(() => setLoading(false));

   }, []);

   useEffect(() => {
      getUser().then((user) => {
        if (user) {
          if (user.role === USER_ROLES.SUPERADMIN) {
            checkIsSuperAdmin(true);
          }
          if (user?.acl_settings) {
            setModuleOperations(JSON.parse(user.acl_settings.module_operations));
          }
        }
      });
    }, []);


   function onTrunkListSuccess(resp) {
      const updatedList = resp.map((val, idx) => {
         val['key'] = idx + 1;
         // val['actions'] = RenderActionComponent(val._id);
         val['createdAt'] = new Date(val['createdAt']).toGMTString();
         return val;
      });
      setTrunksList(updatedList);
      setOriginalTrunkList(updatedList);
   }

   function handleDeleteTrunk(id) {
      checkAuth(setToastProps, setShowToast);
      setIsMultiDelete(false);
      setEnableDeleteModal(true);
      setDeleteTrunkId(id);
   }

   const handleSelectAll = () => {
      if (selectedTrunks.length === trunksList.length) {
          setSelectedTrunks([]);
      } else {
          const allTrunksIds = trunksList.map(trunks => trunks._id);
          setSelectedTrunks(allTrunksIds);
      }
  };

  useEffect( () => {
   if (confirmDelete) {
      if (isMultiDelete) {
          postApiCall(API_TRUNKS_DELETE, { trunkIds: selectedTrunks })
              .then(onDeleteSuccess)
              .catch(onDeleteFail);
      } else {
          postApiCall(API_TRUNKS_DELETE, { trunkIds: [deleteTrunkId] })
              .then(onDeleteSuccess)
              .catch(onDeleteFail);
      }
  }
}, [confirmDelete]);

  useEffect(() => {
    if(searchValue){
      setTrunksList(
         trunksList.filter((trunk) => trunk.name.toLowerCase().includes(searchValue.trim().toLowerCase()))
      );
    }
    else {
      setTrunksList(originalTrunkList); // Reset to the original list if no name is selected
    }
  }, [searchValue, trunksList]);


   function onDeleteSuccess(resp) {
      setToastProps({ type: 'success', message: 'Trunk deleted successfully' });
      let updatedList;
      if (isMultiDelete) {
         updatedList = trunksList.filter(val => !selectedTrunks.includes(val._id)).map((val, key) => {
            val['key'] = key + 1;
            return val;
         });
         setSelectedTrunks([]);
      } else {
         updatedList = trunksList.filter(val => val._id != deleteTrunkId).map((val, key) => {
            val['key'] = key + 1;
            return val;
         });
      }

      setTrunksList(updatedList);
      setEnableDeleteModal(false);
      setDeleteTrunkId(undefined);
      setConfirmDelete(false); 
   }

   function onDeleteFail(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
         setToastProps({ type: 'danger', message: `Unable to delete trunk: ${err}` });
      }
      setConfirmDelete(false);
   }

   const RenderActionComponent = ({id}) => {
      const trunkEditRoute = ROUTES.TRUNKS.EDIT.replace(':id', id);

      return (
         <div className="flex align-items-center list-user-action">
            {(isSuperAdmin || moduleOperations?.Trunks?.edit) && (
              <EditIcon linkTo={`/${trunkEditRoute}`} />
            )}
            {' '}
            {!(selectedTrunks.length > 0) && (isSuperAdmin || moduleOperations?.Trunks?.delete) && (
              <DeleteIcon handleCallback={() => handleDeleteTrunk(id)} />
            )}
            {' '}
         </div>
      );

   }

   const dropdownItemTemplate = (option) => {
      return <span>{option}</span>;
   }

   const authTypeFilterTemplate = options => {
      return <Dropdown value={options.value} options={authTypeLabels} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
   }

   const statusFilterTemplate = (options) => {
      return <Dropdown value={options.value} options={USER_STATUS_LABEL} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
   }

   const operatorFilterTemplate = options => {
      return <Dropdown value={options.value} options={operatorTypeLabels} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dropdownItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
   }

  

   function onError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
         errorCheck(err, navigate, setToastProps, setShowToast)
      } else {
        console.log(err)
      }
   }

   const handleCreateButtonClick = () => {
      navigate(`/${ROUTES.TRUNKS.CREATE}`);
   } 

   const rowClassName = (data) => {
      return {
          'highlighted-row': selectedTrunks.includes(data._id)
     };
   };

   const actionBodyTemplate = (rowData) => {
      return <RenderActionComponent id={rowData._id} />;
   };

   if (loading) {
      return <Loader />
   }

   return (
      <>
         <div>
            <Row>
               <Col sm="12">
                  <Card>
                     <Card.Header className="d-block justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Trunks List</h4>
                        </div>
                        { (isSuperAdmin || moduleOperations?.Trunks?.add) && (<button className="btn btn-primary ml-auto" onClick={handleCreateButtonClick}>Create Trunk</button>) }
                     </Card.Header>
                     <Card.Body className="px-0">

                        <div className="table-responsive border-bottom my-3">
                           <div style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
                           <Button 
                                 label="Delete Selected" 
                                 onClick={handleMultiDelete} 
                                 className="p-button-danger p-button-sm" 
                                 style={{ marginBottom: '10px', marginLeft: '10px' }} 
                                 disabled={selectedTrunks.length === 0} 
                              />
                              <Button 
                                 label="Export Data" 
                                 onClick={handleExport} 
                                 className="p-button-active p-button-sm" 
                                 style={{ marginBottom: '10px', marginLeft: '10px' }} 
                                 disabled={buttonDisable} 
                              >
                                 &nbsp;
                                 {buttonDisable && (
                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                 )}
                              </Button>
                              <Form.Group style={{height: "45px"}}>
                                 <Form.Control
                                    style={{height: "50px", marginLeft: "10px", border: "1px solid #ced4da", fontSize: "15px", height: "45px"}}
                                    placeholder = 'Search by Name...'
                                    type="text"
                                    maxLength={100}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    required
                                 />
                              </Form.Group>
                           </div>
                              
                           <DataTable value={trunksList} tableStyle={{ minWidth: '50rem' }} filters={filters} paginator rows={8} rowClassName={rowClassName}>
                              <Column 
                                 header={<Checkbox 
                                       checked={selectedTrunks.length === trunksList.length && trunksList.length > 0} 
                                       onChange={handleSelectAll} 
                                 />} 
                                 headerStyle={{ width: '3rem' }} 
                                 body={(rowData) => (
                                       <Checkbox 
                                          checked={selectedTrunks.includes(rowData._id)} 
                                          onChange={() => handleCheckboxToggle(rowData._id)} 
                                       />
                                 )} 
                              />
                              <Column field="name" filterField="name" header="Name" filter filterPlaceholder="Search by name" ></Column>
                              <Column field="auth_type" filterField="auth_type" header="Authentication Type" filter filterPlaceholder="Search by authentication type" filterElement={authTypeFilterTemplate} ></Column>
                              <Column field="domain" filterField="domain" header="Domain" filter filterPlaceholder="Search by domain" ></Column>
                              <Column field="port" filterField="port" header="Port" filter filterPlaceholder="Search by port" ></Column>
                              <Column field="operator.name" header="Operator"></Column>
                              <Column field="status" header="Status" filterElement={statusFilterTemplate} filter></Column>
                              <Column field="server.server_name" header="Server"></Column>
                              <Column field="createdAt" header="Created Date"></Column>
                              {((isSuperAdmin || moduleOperations?.Trunks?.edit || moduleOperations?.Trunks?.delete)) && (
                                 <Column header="Action" body={actionBodyTemplate} />
                              )}
                           </DataTable>
                        </div>

                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>

         <RenderDeleteModal 
         show={enableDeleteModal} 
                setShow={setEnableDeleteModal} 
                setConfirmDelete={setConfirmDelete} 
                isMultiDelete={isMultiDelete} 
                clearSelectedData={() => setSelectedTrunks([])}
         />

         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />

      </>
   )

}