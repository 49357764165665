import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import Card from '../../../components/Card'; // Assuming the same Card component is used
import { getApiCall } from '../../../service/getApiCall';
import { API_AGENTS_LIST } from '../../../service/url';
import { useLocation } from 'react-router-dom';
import { postApiCall } from '../../../service/postApiCall';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import LottieAnimation from '../../../components/lottie/lottie';
import './slider.css'
import { DeleteIcon, EditIcon } from '../../../components/setting/elements';

const GroupSettings = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [showAddMembersModal, setShowAddMembersModal] = useState(false);
    const [selectedAgentsTableData, setSelectedAgentsTableData] = useState([]);


    const [showTimeScheduleModal, setShowTimeScheduleModal] = useState(false);
    const [showWhisperModal, setShowWhisperModal] = useState(false);
    const [agents, setAgents] = useState([]);
    const [showCallStrategyModal, setShowCallStrategyModal] = useState(false);
  
    const [selectedAgentIds, setSelectedAgentIds] = useState([]);
    const [startTime, setStartTime] = useState("00:00:00 AM");
    const [endTime, setEndTime] = useState("11:59:59 PM");

    const [weekDays, setWeekDays] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
    const [memberWeekDays, setmemberWeekDays] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
    const [selectedStrategy, setSelectedStrategy] = useState("ROUNDROBIN RINGING");
    const [sticky, setSticky] = useState(false)
    const [time, setTime] = useState(false);
    const [callStrategy, setCallStratergy] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showToastModal, setShowToastModal] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [assignedAgents, setAssignedAgents] = useState([]); // Already assigned agents
    const [availableAgents, setAvailableAgents] = useState([]); // Agents available to assign
    const [groupId, setGroupId] = useState(location.state?.groupId);
    const [editData, setEditData] = useState({
        id: '',
        startTime: "00:00:00 AM",
        endTime: "11:59:59 PM",
        weekDays: [],
    });
    
    




    const showToast = (message) => {
        setToastMessage(message);
        setShowToastModal(true);

        // Hide modal automatically after 3 seconds
        setTimeout(() => {
            setShowToastModal(false);
        }, 3000);
    };

    const handleAddMembersClick = () => {
        setShowAddMembersModal(true);
    };

    const handleModalClose = () => {
        setShowAddMembersModal(false);
    };

    const handleTimeScheduleClick = () => {
        setStartTime("00:00:00 AM");
        setEndTime("11:59:59 PM");
        setShowTimeScheduleModal(true);
    };
    

    const handleTimeScheduleClose = () => {
        setTime(true)
        setShowTimeScheduleModal(false);
    };

    const handleWhisperClick = () => {
        setShowWhisperModal(true);
    };

    const handleWhisperClose = () => {
        setShowWhisperModal(false);
    };

    const handleCallStrategyClick = () => {
        setShowCallStrategyModal(true);
    };

    const handleCallStrategyCloseall = () => {
        setShowCallStrategyModal(false);
    }

    const handleCallStrategyClose = () => {

        const finalPayload = {
            strategy: selectedStrategy
        }

        postApiCall(`/v1/agent-group/${groupId}`, finalPayload).then((response) => {
            if (response) {
                setShowCallStrategyModal(false)
                showToast('Call Stratergy Updated');
                setShowAddMembersModal(false);
            }
        }).catch((err) => {
            toast.error(err)
        })
        setShowCallStrategyModal(false);
    };


    // const fetchAgents = async () => {
    //     try {
    //         const response = await getApiCall(API_AGENTS_LIST);
    //         if (response) {
    //             const assigned = response.filter((agent) => agent.isAllocated === 1);
    //             const available = response.filter((agent) => agent.isAllocated === 0);


    //             console.log("ASSIGNED AGENTS", assigned);
    
    //             setAssignedAgents(assigned);
    //             setAvailableAgents(available);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching agents:', error);
    //     }
    // };
    



    const handleSaveTimeSchedule = () => {
        const formattedStartTime = formatTimeTo12Hour(startTime);
        const formattedEndTime = formatTimeTo12Hour(endTime);
    
        const finalPayload = {
            time_schedule: {
                start_time: formattedStartTime,
                end_time: formattedEndTime,
                week_days: [...weekDays],
            },
        };
    
        postApiCall(`/v1/agent-group/${groupId}`, finalPayload)
            .then((response) => {
                if (response) {
                    setShowTimeScheduleModal(false);
                    showToast('Time Schedule Updated');
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    };
    
    

    // const getAssigendAgents = async () => {
    //     try {
    //         const response = await getApiCall(`/v1/agent-group/${groupId}/assigned-agents`);
    //         if (response)
    //             {
    //             console.log("Fetched Assigned Agents:", response.agents);
    //             setAssignedAgents(response.agents);
    //         } else {
    //             toast.error("Failed to fetch assigned agents.");
    //         }
    //     } catch (error) {
    //         console.error("Error fetching assigned agents:", error);
    //         toast.error("Error fetching assigned agents.");
    //     }
    // };

    

    const handleEditClick = (agent) => {
        setEditData({
            id: agent._id,
            startTime: agent.time_schedule?.start_time || "00:00:00 AM",
            endTime: agent.time_schedule?.end_time || "11:59:59 PM",
            weekDays: agent.time_schedule?.week_days || ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
        });
        setShowEditModal(true);
    };
    
    


    const fetchAgentData = async () => {
        try {
            const [agentsResponse, assignedAgentsResponse] = await Promise.all([
                getApiCall(API_AGENTS_LIST),
                getApiCall(`/v1/agent-group/${groupId}/assigned-agents`)
            ]);

            if (agentsResponse) {
                console.log("AGENT RESPONSE", agentsResponse);
                const assignedFromList = agentsResponse.filter((agent) => agent.isAllocated === 1);
                const available = agentsResponse.filter((agent) => agent.isAllocated === 0);
                setAvailableAgents(available);
            }
    
            if (assignedAgentsResponse && assignedAgentsResponse.agents) {
                setAssignedAgents(assignedAgentsResponse.agents);
            }
    
            console.log("Fetched Assigned Agents:", assignedAgentsResponse.agents);
        } catch (error) {
            console.error("Error fetching agents:", error);
            toast.error("Failed to fetch agent data.");
        }
    };
    
    const handleAddMembersSave = async () => {
        const selectedAgents = availableAgents
            .filter((agent) => selectedAgentIds.includes(agent._id))
            .map((agent) => ({
                ...agent,
                startTime,
                endTime,
                isAllocated: 1, // Mark agent as allocated
            }));
    
        const AddmemberPayload = {
            agent_id: selectedAgents.map((data) => data._id),
            member_count: selectedAgents?.length,
        };
    
        const memberSchedulePayload = {
            week_days: memberWeekDays,
            start_time: startTime,
            end_time: endTime,
        };

        const updateAllocationPayload = {
            agentIds: selectedAgentIds,
        };
    
        try {
            // Step 1: Update agents in the group
            const [addMembersResponse, memberScheduleResponse,updateAllocationResponse] = await Promise.all([
                postApiCall(`/v1/agent-group/${groupId}`, AddmemberPayload),
                postApiCall(`/v1/member-schedule/${groupId}`, memberSchedulePayload),
                postApiCall(`/v1/agents/allocate`, updateAllocationPayload)
            ]);
            if (addMembersResponse && memberScheduleResponse && updateAllocationResponse) {
                showToast('Members added, schedules updated, and allocation updated successfully');
    
                setAssignedAgents((prev) => [...prev, ...selectedAgents]);
                setAvailableAgents((prev) =>
                    prev.filter((agent) => !selectedAgentIds.includes(agent._id))
                );
    
                setSelectedAgentIds([]);
                setShowAddMembersModal(false);
            } else {
                toast.error("Failed to complete all member actions. Please try again.");
            }
        } catch (error) {
            console.error("Error in handleAddMembersSave:", error);
            toast.error(`Error: ${error.message || 'Something went wrong'}`);
        }
    };
    
    



    const handleSticky = (value) => {
        const finalPayload = {
            sticky: value
        }

        postApiCall(`/v1/agent-group/${groupId}`, finalPayload).then((response) => {
            if (response) {
                toast.success(value ? "Sticky On " : "Sticky Off");
                setShowAddMembersModal(false);
            }
        }).catch((err) => {
            toast.error(err)
        })
    }

    const fetchCallStratergies = () => {

        getApiCall('/v1/call-stratergy').then((data) => {
            setCallStratergy(data.strategies);
        }).catch((err) => {
            toast.error("Error fetching call stratergies", { duration: 4000 })
        })
    }

    useEffect(() => {
        if (location.state?.groupId) {
            setGroupId(location.state.groupId);
        }
        fetchAgentData();
        fetchCallStratergies();
    }, []);
    
    
    

    const handleRemoveAgent = async (agentId) => {
        try {
            await postApiCall(`/v1/agent-group/${groupId}/remove-agent`, { agent_id: agentId });
            showToast('Agent removed successfully');
        } catch (error) {
            toast.error('Failed to remove agent');
        }
    };


    const formatTimeTo12Hour = (time) => {
        const [hours, minutes] = time.split(':');
        let formattedHours = parseInt(hours, 10) % 12 || 12; // Convert hours to 12-hour format
        const period = hours >= 12 ? 'PM' : 'AM';
        return `${formattedHours.toString().padStart(2, '0')}:${minutes}:00 ${period}`;
    };
    
    const handleSaveAgentEdit = async () => {
        const formattedStartTime = formatTimeTo12Hour(editData.startTime);
        const formattedEndTime = formatTimeTo12Hour(editData.endTime);
    
        const finalPayload = {
            start_time: formattedStartTime,
            end_time: formattedEndTime,
            week_days: editData.weekDays,
        };
    
        try {
            await postApiCall(`/v1/agents/schedule/${editData.id}`, finalPayload);
            showToast('Agent time schedule updated successfully');
            setShowEditModal(false);
    
            setAssignedAgents((prev) =>
                prev.map((agent) =>
                    agent._id === editData.id
                        ? {
                              ...agent,
                              time_schedule: {
                                  start_time: formattedStartTime,
                                  end_time: formattedEndTime,
                                  week_days: editData.weekDays,
                              },
                          }
                        : agent
                )
            );
        } catch (error) {
            console.error('Error updating agent time schedule:', error);
            toast.error('Failed to update agent time schedule');
        }
    };
    
    
    


    const handlesave = async () => {

        const finalPayload = {
            agent_id: selectedAgentsTableData.map((agent) => agent._id),
            strategy: selectedStrategy,
            sticky: sticky,
            time_schedule: [
                {
                    week_days: [...weekDays],
                    time: time,
                },
            ],
            add_member_schedule: [
                {
                    week_days: memberWeekDays,
                    start_time: startTime,
                    end_time: endTime,
                },
            ],
        };


        postApiCall(`/v1/agent-group/${groupId}`, finalPayload)
            .then((response) => {
                if (response) {
                    navigate('/agent-group-list')
                }
            })
            .catch((error) => {
                console.log("ERROR HERE", error);
            });
    };


    return (
        <div style={{ marginTop: '20px' }}>
            <Toaster />
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header>
                            <h4>Group Settings</h4>
                        </Card.Header>
                        <Card.Body>
                            <p>You can configure the IVR settings according to the created call group from adding members to time schedules and call strategies.</p>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>

                                <div className="cardy">
                                    <div className="card cardx">
                                        <p className="mb-0">Include your member in this group to assign workflows specifically according to time schedules and call strategies.</p>
                                        <button className="btn btn-danger btn-sm me-3 mt-5 btncardy" onClick={handleAddMembersClick}>+ Add Members</button>
                                    </div>

                                </div>

                                <div className="cardy">
                                    <div className="card cardx">
                                        <p className="mb-0">Account owner can configure when calls are routed to available members based on provided scheduled business hours.</p>
                                        <button className="btn btn-danger btn-sm me-3 mt-4 btncardy " onClick={handleTimeScheduleClick} >+ Time Schedule</button>

                                    </div>
                                </div>

                                <div className="cardy">
                                    <div className="card cardx">
                                        <p className="mb-0">It provides a method to handle call flows for members with several extensions using a variety of routing strategies.</p>
                                        <button className="btn btn-danger btn-sm me-3 btncardy mt-5" onClick={handleCallStrategyClick}>+ Call Strategy</button>

                                    </div>
                                </div>


                                {/*Configure Whisper */}
                                <div className="cardy" >
                                    <div className="card cardx" >
                                        <p className="mb-0">Configure Whisper voice messages so that your team can hear this voice prompt first before connecting with the customer.</p>
                                        <button className="btn btn-danger btn-sm me-3 mt-4 btncardy" onClick={handleWhisperClick}>+ Configure Whisper</button>
                                        <div class="shine"></div>
                                    </div>
                                    <div class="card">
                                        <span class="icon">
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="mb-3">
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-danger btn-sm me-3" style={{backgroundColor:"#4D869C", border:"none"}} onClick={handleWhisperClick}>+ Configure Whisper</button>
                                    <p className="mb-0">Configure Whisper voice messages so that your team can hear this voice prompt first before connecting with the customer.</p>
                                </div>
                                <hr style={{ border: 'none', height: '1px', backgroundColor: '#dee2e6', margin: '0.5rem 0' }} />
                            </div> */}

                            <div className="mt-4">
                                <p>Only the assigned member will receive calls from the customer. If they don't pick up, the call will get forwarded to the next member automatically:</p>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="form-check me-4">
                                        <input type="radio" id="normal" name="stickyMember" className="form-check-input" />
                                        <label htmlFor="normal" className="form-check-label">Normal</label>
                                    </div>
                                    <div className="form-check me-4">
                                        <input type="radio" id="advance" name="stickyMember" className="form-check-input" />
                                        <label htmlFor="advance" className="form-check-label">Advance</label>
                                    </div>
                                    <div className="form-check">
                                        <input type="radio" id="off" name="stickyMember" className="form-check-input" defaultChecked />
                                        <label htmlFor="off" className="form-check-label">Off</label>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div className="mt-4">
                                <p>Multi IVR Sticky:</p>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="form-check me-4">
                                        <input type="radio" id="multiIvrOn" name="multiIvr" className="form-check-input" onChange={() => handleSticky(true)} />
                                        <label htmlFor="multiIvrOn" className="form-check-label" >On</label>
                                    </div>
                                    <div className="form-check">
                                        <input type="radio" id="multiIvrOff" name="multiIvr" className="form-check-input" defaultChecked onChange={() => handleSticky(false)} />
                                        <label htmlFor="multiIvrOff" className="form-check-label">Off</label>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div className="mt-4 text-center">
                                <button className="btn btn-danger btncardy" onClick={handlesave}  >
                                    Save
                                </button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>



            <div className="mt-4">
    <h5>Assigned Agents</h5>
    <table className="table table-bordered" style={{ marginTop: "20px" }}>
        <thead>
            <tr>
                <th>S.No</th>
                <th>Team Member Name</th>
                <th>Mobile Number</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Agent Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
    {assignedAgents.length > 0 ? (
        assignedAgents.map((agent, index) => (
            <tr key={agent._id}>
                <td>{index + 1}</td>
                <td>{agent.agent_name}</td>
                <td>{agent.agent_number}</td>
                <td>{agent.time_schedule?.start_time || "00:00"}</td>
                <td>{agent.time_schedule?.end_time || "23:59"}</td>
                <td>{agent.login_status === "1" ? "Active" : "Inactive"}</td>
                <td>
                    <div className="actions" style={{ display: 'flex', gap: '5px' }}>
                        <div onClick={() => handleEditClick(agent)}>
                            <EditIcon title="Edit" />
                        </div>
                        <div>
                            <DeleteIcon
                                title="Delete"
                                handleCallback={() => handleRemoveAgent(agent._id)}
                            />
                        </div>
                    </div>
                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan="8" className="text-center">No assigned agents found</td>
        </tr>
    )}
</tbody>

    </table>
</div>





            {/* Add Members Modal */}


            <Modal show={showAddMembersModal} onHide={handleModalClose} size="lg" centered>
                <Modal.Header closeButton >
                    <Modal.Title>Add Members in Call Group</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col>

                            <div style={{
                                maxHeight: '300px',
                                overflowY: 'auto',
                                scrollbarWidth: 'thin', // For Firefox
                            }}>
                                <table className="table table-bordered" >
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Team Member Name</th>
                                            <th>Mobile Number</th>
                                            <th>

                                                <input
                                                    type="checkbox"
                                                    // style={{ marginLeft: '10px' }}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            // Select all agents
                                                            setSelectedAgentIds(agents.map((agent) => agent._id));
                                                        } else {
                                                            // Deselect all agents
                                                            setSelectedAgentIds([]);
                                                        }
                                                    }}
                                                    checked={selectedAgentIds.length === agents.length && agents.length > 0}
                                                />
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
{availableAgents.map((agent, index) => (
    <tr key={agent._id}>
        <td>{index + 1}</td>
        <td>{agent.agent_name}</td>
        <td>{agent.agent_number}</td>
        <td>
            <input
                type="checkbox"
                checked={selectedAgentIds.includes(agent._id)}
                onChange={(e) => {
                    if (e.target.checked) {
                        setSelectedAgentIds((prev) => [...prev, agent._id]);
                    } else {
                        setSelectedAgentIds((prev) =>
                            prev.filter((id) => id !== agent._id)
                        );
                    }
                }}
            />
        </td>
    </tr>
))}

                                    </tbody>


                                </table>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form>
                                <Form.Check
                                    type="checkbox"
                                    label="Whole Day"
                                    id="wholeDay"
                                    className="mb-3"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setStartTime("00:00");
                                            setEndTime("23:59");
                                        }
                                    }}
                                />
                               <Form.Group className="mb-3">
    <Form.Label>Start Time</Form.Label>
    <Form.Control
        type="time"
        value={editData.startTime}
        placeholder="12:00:00 AM"
        onChange={(e) =>
            setEditData((prev) => ({
                ...prev,
                startTime: e.target.value,
            }))
        }
    />
</Form.Group>
<Form.Group className="mb-3">
    <Form.Label>End Time</Form.Label>
    <Form.Control
        type="time"
        value={editData.endTime}
        placeholder="11:59:59 PM"
        onChange={(e) =>
            setEditData((prev) => ({
                ...prev,
                endTime: e.target.value,
            }))
        }
    />
</Form.Group>


                                <Form.Group className="mb-3">
                                    <Form.Label>Select Days</Form.Label>
                                    <div>
                                        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
                                            (day) => (
                                                <Form.Check
                                                    inline
                                                    key={day}
                                                    label={day}
                                                    type="checkbox"
                                                    checked={memberWeekDays.includes(day)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setmemberWeekDays((prev) => [...prev, day]);
                                                        } else {
                                                            setmemberWeekDays((prev) => prev.filter((d) => d !== day));
                                                        }
                                                    }}
                                                />
                                            )
                                        )}
                                    </div>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddMembersSave}>Add member</Button>
                </Modal.Footer>
            </Modal>


            {/* Call strategy */}
            <Modal show={showCallStrategyModal} onHide={handleCallStrategyCloseall} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Call Strategy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Select a Strategy:</Form.Label>
                            <div>
                                {callStrategy.map(
                                    (strategy) => (
                                        <Form.Check
                                            style={{ marginTop: "5px" }}
                                            type="radio"
                                            key={strategy}
                                            label={strategy}
                                            name="callStrategy"
                                            id={strategy.replace(/\s+/g, "-").toLowerCase()}
                                            checked={selectedStrategy === strategy}
                                            onChange={() => setSelectedStrategy(strategy)}
                                        />
                                    )
                                )}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCallStrategyCloseall}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCallStrategyClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>





            {/* Add Time Schedule Modal */}
            <Modal show={showTimeScheduleModal} onHide={handleTimeScheduleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Time Schedule Setting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Whole Day Checkbox */}
                        <Form.Check
                            type="checkbox"
                            label="Whole Day"
                            id="wholeDayTimeSchedule"
                            className="mb-3"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setWeekDays(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
                                } else {
                                    setWeekDays([]);
                                }
                            }}
                            checked={weekDays.length === 7} // If all 7 days are selected, "Whole Day" is checked
                        />

                        {/* Individual Weekday Checkboxes */}
                        <Form.Group className="mb-3">
                            <Form.Label>Select Days</Form.Label>
                            <div>
                                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                                    <Form.Check
                                        inline
                                        key={day}
                                        label={day}
                                        type="checkbox"
                                        id={`day-${day}`}
                                        checked={weekDays.includes(day)} // Checkbox reflects the current state
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                // Add the day if checked
                                                setWeekDays((prev) => [...prev, day]);
                                            } else {
                                                // Remove the day if unchecked
                                                setWeekDays((prev) => prev.filter((d) => d !== day));
                                            }
                                        }}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTimeScheduleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveTimeSchedule}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Show whisper model */}


            <Modal show={showWhisperModal} onHide={handleWhisperClose} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Configure Whisper Prompt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Select A Prompt:</Form.Label>
                            <Form.Select>
                                <option>Select Prompt</option>
                                <option value="1">Prompt 1</option>
                                <option value="2">Prompt 2</option>
                                <option value="3">Prompt 3</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary">Submit</Button>
                    <Button variant="secondary" onClick={handleWhisperClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* LOTTIE MODEL FOR TOAST */}
            <Modal show={showToastModal} centered onHide={() => setShowToastModal(false)} size="sm">
                <Modal.Body className="text-center">
                    <h5>{toastMessage}</h5>
                    <LottieAnimation />
                </Modal.Body>
            </Modal>

            {/* EDIT MODAL */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Member Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3">
    <Form.Label>Start Time</Form.Label>
    <Form.Control
        type="text"
        value={editData.startTime}
        placeholder="00:00:00 AM"
        onChange={(e) =>
            setEditData((prev) => ({ ...prev, startTime: e.target.value }))
        }
    />
</Form.Group>
<Form.Group className="mb-3">
    <Form.Label>End Time</Form.Label>
    <Form.Control
        type="text"
        value={editData.endTime}
        placeholder="11:59:59 PM"
        onChange={(e) =>
            setEditData((prev) => ({ ...prev, endTime: e.target.value }))
        }
    />
</Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Week Days</Form.Label>
                            <div>
                                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                                    <Form.Check
                                        inline
                                        key={day}
                                        label={day}
                                        type="checkbox"
                                        checked={editData.weekDays.includes(day)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setEditData((prev) => ({
                                                    ...prev,
                                                    weekDays: [...prev.weekDays, day],
                                                }));
                                            } else {
                                                setEditData((prev) => ({
                                                    ...prev,
                                                    weekDays: prev.weekDays.filter((d) => d !== day),
                                                }));
                                            }
                                        }}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                    </Button>
                    <Button
    variant="primary"
    onClick={handleSaveAgentEdit}
>
    Save Changes
</Button>

                </Modal.Footer>
            </Modal>




        </div>
    );
};

export default GroupSettings;
