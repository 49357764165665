import React, { useState, useEffect } from "react";
import { getApiCall, postApiCall } from "../../../service/httpCall";
import { Row, Col, Card, Form } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import RenderDeleteModal from "../../../components/partials/components/delete-modal";
import ToastNotification from "../../../components/Toast";
import {
  API_DATA_CENTER_LIST,
  API_DATA_CENTER_DELETE,
} from "../../../service/url";
import { ROUTES, DOMESTIC_COUNTRY } from "../../../utilities/constant";
import { EditIcon, DeleteIcon } from "../../../components/setting/elements";
import Loader  from '../../../components/partials/components/loader';
import { useAuth, errorCheck } from '../auth/auth-utils';
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from '../../../utilities/constant';
import { getUser } from "../../../utilities/setting";
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import "../../../assets/custom/css/custom.css";
import exportData from '../../../utilities/export';


const DataCenterList = () => {
  const [enableDeleteModal, setEnableDeleteModal] = useState(false);
  const [deleteDataCenterId, setDeleteDataCenterId] = useState(undefined);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [dataCenter, setDataCenter] = useState([]);
  const [loading, setLoading] = useState(false);
  const { checkAuth } = useAuth();
  const navigate = useNavigate();
  const [isSuperAdmin, checkIsSuperAdmin] = useState(false);
  const [moduleOperations, setModuleOperations] = useState({});
  const [selectedData, setSelectedDataCenter] = useState([]);
  const [isMultiDelete, setIsMultiDelete] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [originalDataList, setOriginalDataList] = useState([]);

  const handleExport = async () => {
    setButtonDisable(true); 
    await exportData('DataCenter', setToastProps, setShowToast);
    setButtonDisable(false); 
  };

  const handleCheckboxToggle = (dataCenterId) => {
    const isSelected = selectedData.includes(dataCenterId);
    setSelectedDataCenter(isSelected
        ? selectedData.filter(id => id !== dataCenterId)
        : [...selectedData, dataCenterId]);
  };

  const handleMultiDelete = () => {
        setIsMultiDelete(true);
        setEnableDeleteModal(true);
  };


  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    setLoading(true);
    getApiCall(API_DATA_CENTER_LIST)
      .then(onDataCenterSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);

useEffect(() => {
  getUser().then((user) => {
    if (user) {
      if (user.role === USER_ROLES.SUPERADMIN) {
        checkIsSuperAdmin(true);
      }
      if (user?.acl_settings) {
        setModuleOperations(JSON.parse(user.acl_settings.module_operations));
      }
    }
  });
}, []);


  function onDataCenterSuccess(resp) {
    const updatedList = resp.map((val, idx) => {
      val["key"] = idx + 1;
      // val["actions"] = RenderActionComponent(val._id);
      val["createdAt"] = new Date(val["createdAt"]).toGMTString();
      val["state"] = val.type === "International"
      ? val.overseas_details.state.name
      : val.domestic_details.state.name;
    val["city"] = val.type === "International"
      ? val.overseas_details.city
      : val.domestic_details.city;
      return val;
    });
    setDataCenter(updatedList);
    setOriginalDataList(updatedList);
  }

  useEffect(() => {
    if(searchValue){
      setDataCenter(
        dataCenter.filter((data) => data.name.toLowerCase().includes(searchValue.trim().toLowerCase()))
      );
    }
    else {
      setDataCenter(originalDataList); // Reset to the original list if no name is selected
    }
  }, [searchValue, dataCenter]);

  const RenderActionComponent = ({id}) => {
    const trunkEditRoute = ROUTES.DATA_CENTER.EDIT.replace(":id", id);
    return (
      <div className="flex align-items-center list-user-action">
        {(isSuperAdmin || moduleOperations['Data Center']?.edit) && (
          <EditIcon linkTo={`/${trunkEditRoute}`} />
        )}{" "}
        {(!(selectedData.length > 0) && (isSuperAdmin || moduleOperations['Data Center']?.delete)) && (
          <DeleteIcon handleCallback={() => handleDataCeterDelete(id)} />
        )}{" "}
      </div>
    );
  };

  function handleDataCeterDelete(id) {
    checkAuth(setToastProps, setShowToast)
    setIsMultiDelete(false);
    setEnableDeleteModal(true);
    setDeleteDataCenterId(id);
  }

  const handleSelectAll = () => {
    if (selectedData.length === dataCenter.length) {
        setSelectedDataCenter([]);
    } else {
        const allDataCenterIds = dataCenter.map(dataCenter => dataCenter._id);
        setSelectedDataCenter(allDataCenterIds);
    }
  };

  useEffect( () => {
    if (confirmDelete) {
        if (isMultiDelete) {
            postApiCall(API_DATA_CENTER_DELETE, { dataCenterIds: selectedData })
                .then(onDeleteSuccess)
                .catch(onDeleteFail);
        } else {
            postApiCall(API_DATA_CENTER_DELETE, { dataCenterIds: [deleteDataCenterId] })
                .then(onDeleteSuccess)
                .catch(onDeleteFail);
        }
    }
    }, [confirmDelete]);

  function onDeleteSuccess(resp) {
    setToastProps({
      type: "success",
      message: "Data Center deleted successfully",
    });
    let updatedList;
    if (isMultiDelete) {
        updatedList = dataCenter.filter(val => !selectedData.includes(val._id)).map((val, key) => {
            val['key'] = key + 1;
            return val;
        });
        setSelectedDataCenter([]);
    } else {
        updatedList = dataCenter
        .filter((val) => val._id != deleteDataCenterId)
        .map((val, key) => {
          val["key"] = key + 1;
          return val;
        });
    }
    setDataCenter(updatedList);
    setEnableDeleteModal(false);
    setDeleteDataCenterId(undefined);
    setConfirmDelete(false); 
  }

  function onDeleteFail(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
    } else {
      setToastProps({
        type: "danger",
        message: `Unable to delete data center: ${err}`,
      });
    }
    setConfirmDelete(false); 
   
  }

  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     console.log(err)
   }
 }

  const getCountry = (item) => {
    return item.type === "International"
      ? item.overseas_details.country.name
      : DOMESTIC_COUNTRY.value;
  };

  const getState = (item) => {
    return item.type === "International"
      ? item.overseas_details.state.name
      : item.domestic_details.state.name;
  };

  const getCity = (item) => {
    return item.type === "International"
      ? item.overseas_details.city
      : item.domestic_details.city;
  };

  const handleCreateButtonClick = () => {
    navigate(`/${ROUTES.DATA_CENTER.CREATE}`);
  } 

 const rowClassName = (data) => {
  return {
      'highlighted-row': selectedData.includes(data._id)
    };
  }


  const actionBodyTemplate = (rowData) => {
    return <RenderActionComponent id={rowData._id} />;
  };


  if (loading) {
    return <Loader />
  }

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <div className="header-title">
                <h4 className="card-title">Data Center List</h4>
              </div>
              {(isSuperAdmin || moduleOperations['Data Center']?.add) && (
                <button className="btn btn-primary ml-auto" onClick={handleCreateButtonClick}>
                  Create Data Center
                </button>
              )}
            </Card.Header>
              <Card.Body className="px-0">
                <div className="table-responsive border-bottom my-3">
                <div style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
                  <Button 
                    label="Delete Selected" 
                    onClick={handleMultiDelete} 
                    className="p-button-danger p-button-sm" 
                    style={{ marginBottom: '10px', marginLeft: '10px' }} 
                    disabled={selectedData.length === 0} 
                  />
                  <Button 
                     label="Export Data" 
                     onClick={handleExport} 
                     className="p-button-active p-button-sm" 
                     style={{ marginBottom: '10px', marginLeft: '10px' }} 
                     disabled={buttonDisable} 
                  >
                     &nbsp;
                     {buttonDisable && (
                        <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                     )}
                  </Button>
                  <Form.Group style={{height: "45px"}}>
                    <Form.Control
                      style={{height: "50px", marginLeft: "10px", border: "1px solid #ced4da", fontSize: "15px", height: "45px"}}
                      placeholder = 'Search by Name...'
                      type="text"
                      maxLength={100}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      required
                    />
                  </Form.Group>
                  </div>
                  <DataTable
                    value={dataCenter}
                    tableStyle={{ minWidth: "50rem" }}
                    paginator
                    rows={8}
                    rowClassName={rowClassName}
                  >
                    <Column 
                      header={<Checkbox 
                      checked={selectedData.length === dataCenter.length && dataCenter.length > 0} 
                      onChange={handleSelectAll} 
                      />} 
                      headerStyle={{ width: '3rem' }} 
                      body={(rowData) => (
                      <Checkbox 
                        checked={selectedData.includes(rowData._id)} 
                        onChange={() => handleCheckboxToggle(rowData._id)} 
                      />
                      )} 
                    />
                    <Column
                      field="name"
                      header="Name"
                      filter
                      filterPlaceholder="Search by name"
                    ></Column>
                    <Column field="type" header="Type"></Column>
                    <Column field={getCountry} header="Country"></Column>
                    <Column
                      field="state"
                      header="State"
                      filter
                      filterPlaceholder="Search by state"
                    ></Column>
                    <Column
                      field="city"
                      header="City"
                      filter
                      filterPlaceholder="Search by city"
                    ></Column>
                    <Column
                      field="contact_person"
                      header="Contact Person"
                    ></Column>
                    <Column
                      field="contact_email"
                      header="Contact Email"
                    ></Column>
                    <Column
                      field="contact_number"
                      header="Contact Number"
                    ></Column>
                    <Column
                      field="data_centre_company"
                      header="Data Centre Company"
                    ></Column>
                    <Column
                      field="data_centre_address"
                      header="Data Centre Address"
                    ></Column>
                    <Column field="createdAt" header="Created Date"></Column>
                    {((isSuperAdmin || moduleOperations['Data Center']?.edit || moduleOperations['Data Center']?.delete)) && (
                      <Column header="Action" body={actionBodyTemplate} />
                    )}
                  </DataTable>
                </div>
              </Card.Body>

            </Card>
          </Col>
        </Row>
      </div>

      <RenderDeleteModal 
         show={enableDeleteModal} 
         setShow={setEnableDeleteModal} 
         setConfirmDelete={setConfirmDelete} 
         isMultiDelete={isMultiDelete} 
         clearSelectedData={() => setSelectedDataCenter([])}
      />

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
};

export default DataCenterList;
