import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_DATA_CENTER_EDIT } from "../../../service/url";
import {
  ROUTES,
  DATA_CENTER_DOMESTIC_TYPE,
  DATA_CENTER_INTERNATIONAL,
  DOMESTIC_COUNTRY,
} from "../../../utilities/constant";
import Card from "../../../components/Card";
import { Row, Col, Button } from "react-bootstrap";
import ToastNotification from "../../../components/Toast";
import { postApiCall, getApiCall } from "../../../service/httpCall";
import RenderDataCenterForm from "./_form";
import { validatePhone, validateEmail } from "../../../utilities/helpers";
import { useAuth, errorCheck } from '../auth/auth-utils';
//import RenderUpdateDataCenterForm from "./_form_update";

function getApiUrl(url, id) {
  return url.replace(":id", id);
}

export default function DataCenterEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [name, setName] = useState("");
  const [dataCenterType, setDataCenterType] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [dataCenterCompany, setDataCenterCompany] = useState("");
  const [dataCenterAddress, setDataCenterAddress] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [countryData, setCountryData] = useState({});
  const [stateData, setStateData] = useState({});
  

  useEffect(() => {
    if (toastProps) {
      setShowToast(true);
    }
  }, [toastProps]);

  useEffect(() => {
    checkAuth(setToastProps, setShowToast)
  }, []);

  useEffect(() => {
    const editApiUrl = getApiUrl(API_DATA_CENTER_EDIT, id);
    getApiCall(editApiUrl, {}).then(onGetSuccess).catch(onError);
  }, [id]);

  function onGetSuccess(resp) {
    setName(resp.name);
    setDataCenterType(resp.type);
    setCountry(
      resp.overseas_details
        ? resp.overseas_details.country.code
        : DOMESTIC_COUNTRY.label
    );
    setState(
      resp.overseas_details
        ? resp.overseas_details.state.code
        : resp.domestic_details.state.code
    );
    setCity(
      resp.overseas_details
        ? resp.overseas_details.city
        : resp.domestic_details.city
    );
    setContactPerson(resp.contact_person);
    setContactEmail(resp.contact_email);
    setContactNumber(resp.contact_number);
    setDataCenterCompany(resp.data_centre_company);
    setDataCenterAddress(resp.data_centre_address);
    setCountryData(
      resp.overseas_details
        ? updateCountryData(resp.overseas_details.country)
        : { [DOMESTIC_COUNTRY.label]: DOMESTIC_COUNTRY.value }
    );
    setStateData(
      resp.overseas_details
        ? updatesetStateData(resp.overseas_details.state)
        : updatesetStateData(resp.domestic_details.state)
    );
  }

  function updateCountryData(country) {
    return {
      [country.code]: country.name,
    };
  }

  function updatesetStateData(state) {
    return {
      [state.code]: state.name,
    };
  }

  function onError(err) {
    if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
      errorCheck(err, navigate, setToastProps, setShowToast)
   } else {
     setButtonDisable(false);
     setToastProps({message: JSON.stringify(err), type: 'danger'});
   }
 }

  function validateForm() {
    let valid = true;
    if (!name.trim()) {
      valid = false;
      setToastProps({ message: "Name required", type: "danger" });
    } else if (
      dataCenterType === undefined ||
      dataCenterType === null ||
      dataCenterType === ""
    ) {
      valid = false;
      setToastProps({ message: "Data Center Type required", type: "danger" });
    } else if (!country.trim()) {
      valid = false;
      setToastProps({ message: "Country required", type: "danger" });
    } else if (!state.trim()) {
      valid = false;
      setToastProps({ message: "State required", type: "danger" });
    } else if (!city.trim()) {
      valid = false;
      setToastProps({ message: "City required", type: "danger" });
    } else if (!contactPerson.trim()) {
      valid = false;
      setToastProps({ message: "Contact Person required", type: "danger" });
    } else if (!validateEmail(contactEmail)) {
      valid = false;
      setToastProps({
        message: "Invalid Contact Email format",
        type: "danger",
      });
    } else if (!validatePhone(contactNumber)) {
      valid = false;
      setToastProps({
        message: "Invalid Contact number format",
        type: "danger",
      });
    } else if (!dataCenterCompany.trim()) {
      valid = false;
      setToastProps({
        message: "Data Center Company required",
        type: "danger",
      });
    } else if (!dataCenterAddress.trim()) {
      valid = false;
      setToastProps({
        message: "Data Center Address required",
        type: "danger",
      });
    }

    return valid;
  }

  async function submitForm(e) {
    e.preventDefault();

    setButtonDisable(true);

    if (!validateForm()) {
      setButtonDisable(false);
      return;
    }

    let params = {
      name,
      type: Number(dataCenterType),
      contact_person: contactPerson,
      contact_email: contactEmail,
      contact_number: Number(contactNumber),
      data_centre_company: dataCenterCompany,
      data_centre_address: dataCenterAddress,
    };
    if (dataCenterType == DATA_CENTER_DOMESTIC_TYPE) {
      const domesticDetails = {
        state: {
          code: state,
          name: stateData[state],
        },
        city: city,
      };
      params = { ...params, domestic_details: domesticDetails };
    } else if (dataCenterType == DATA_CENTER_INTERNATIONAL) {
      const overseasDetails = {
        country: {
          code: country,
          name: countryData[country],
        },
        state: {
          code: state,
          name: stateData[state],
        },
        city: city,
      };
      params = { ...params, overseas_details: overseasDetails };
    }

    const editApiUrl = getApiUrl(API_DATA_CENTER_EDIT, id);
    postApiCall(editApiUrl, params)
      .then(onDataCenterUpdateSuccess)
      .catch(onDataCenterUpdateError);

    function onDataCenterUpdateSuccess(resp) {
      setToastProps({
        message: "Data Center updated successfully",
        type: "success",
      });
      setTimeout(() => {
        navigate(`/${ROUTES.DATA_CENTER.LIST}`);
      }, 1500);
    }

    function onDataCenterUpdateError(err) {
      if (typeof err !== 'object' && err.includes('TokenExpiredError')) {
        errorCheck(err, navigate, setToastProps, setShowToast)
     } else {
       setButtonDisable(false);
       setToastProps({message: JSON.stringify(err), type: 'danger'});
     }
    }
  }
  return (
    <>
      <div>
        <Row>
          <Col xl="12" lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">New Data Center Information</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-trunk-info"></div>

                <form
                  onSubmit={async (e) => {
                    submitForm(e);
                  }}
                >
                  <RenderDataCenterForm
                    isEditMode={true}
                    name={name}
                    setName={setName}
                    dataCenterType={dataCenterType}
                    setDataCenterType={setDataCenterType}
                    country={country}
                    setCountry={setCountry}
                    state={state}
                    setState={setState}
                    city={city}
                    setCity={setCity}
                    contactPerson={contactPerson}
                    setContactPerson={setContactPerson}
                    contactEmail={contactEmail}
                    setContactEmail={setContactEmail}
                    contactNumber={contactNumber}
                    setContactNumber={setContactNumber}
                    dataCenterCompany={dataCenterCompany}
                    setDataCenterCompany={setDataCenterCompany}
                    dataCenterAddress={dataCenterAddress}
                    setDataCenterAddress={setDataCenterAddress}
                    countryData={countryData}
                    setCountryData={setCountryData}
                    stateData={stateData}
                    setStateData={setStateData}
                  />

                  <Button
                    type={"submit"}
                    variant="btn btn-primary"
                    disabled={buttonDisable}
                  >
                    Update Data Center &nbsp;
                    {buttonDisable ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <></>
                    )}
                  </Button>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </>
  );
}
