import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Button } from 'primereact/button';
import { postApiCall } from '../../../service/postApiCall';
import { getApiCall } from '../../../service/getApiCall';
import { AGENT_GROUP_CREATE, API_AGENT_UPDATE, API_AGENT_GROUP_EXTENSION } from '../../../service/url';
import { useNavigate } from 'react-router-dom';
import ToastNotification from '../../../components/Toast';
import { Dropdown } from 'primereact/dropdown';
import { useLocation } from 'react-router-dom';

export default function CreateAgent() {
    const navigate = useNavigate();
    const location = useLocation();
    const agentData = location.state;
    const isEditMode = Boolean(agentData);

    const [agentName, setAgentName] = useState(agentData?.agent_name || '');
    const [agentNumber, setAgentNumber] = useState(agentData?.agent_number || '');
    const [selectedExtension, setSelectedExtension] = useState(
        agentData?.extention?._id || agentData?.extention || null
    );
    const [emailId, setEmailId] = useState(agentData?.email_id || '');
    const [description, setDescription] = useState(agentData?.description || '');
    const [username, setUsername] = useState(agentData?.username || '');
    const [password, setPassword] = useState('');
    const [access, setAccess] = useState(agentData?.access || null);
    const [type, setType] = useState(agentData?.type || null);
    const [loginStatus, setLoginStatus] = useState(agentData?.login_status || 'Inactive');

    const [extensionOptions, setExtensionOptions] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState({ type: 'success', message: '' });

    const accessOptions = [
        { label: 'Admin', value: 'Admin' },
        { label: 'Regular', value: 'Regular' },
        { label: 'Group Owner', value: 'Group Owner' },
    ];

    const typeOptions = [
        { label: 'Normal', value: 'Normal' },
        { label: 'Browser Phone', value: 'Browser Phone' },
        { label: 'Soft Phone', value: 'Soft Phone' },
    ];
    const loginStatusOptions = [
        { label: 'Active', value: '1' },
        { label: 'Inactive', value: '0' },
    ];

    // Fetch extensions for the dropdown
    useEffect(() => {
        getApiCall(API_AGENT_GROUP_EXTENSION)
            .then((data) => {
                const options = data.map((extension) => ({
                    label: extension.username,
                    value: extension._id,
                }));
                setExtensionOptions(options);
            })
            .catch((error) => {
                console.error('Error fetching extensions:', error);
                setToastProps({ type: 'danger', message: 'Error fetching extensions.' });
                setShowToast(true);
            });
    }, []);

    const handleSaveAgent = async () => {
        const payload = {
            agent_name: agentName,
            agent_number: String(agentNumber),
            extention: selectedExtension ? [selectedExtension] : [],
            email_id: emailId,
            description,
            username,
            password,
            access,
            type,
            login_status: loginStatus,
        };

        try {
            if (isEditMode) {
                await postApiCall(`${API_AGENT_UPDATE}/${agentData._id}`, payload);
                setToastProps({ type: 'success', message: 'Agent updated successfully.' });
                setShowToast(true);
                setTimeout(() => navigate('/agents'), 2000);
            } else {
                await postApiCall(AGENT_GROUP_CREATE, payload);
                setToastProps({ type: 'success', message: 'Agent created successfully.' });
                setShowToast(true);
                setTimeout(() => navigate('/agents'), 3000);
            }
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message || error.message || String(error);
            setToastProps({ type: 'danger', message: errorMessage });
            setShowToast(true);
        }
    };

    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-block justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">{isEditMode ? 'Edit Agent' : 'Create Agent'}</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <form>
                                    <Row className="mb-3">
                                        <Col md="4">
                                            <label htmlFor="agentName" className="form-label">Agent Name</label>
                                            <input
                                                type="text"
                                                id="agentName"
                                                value={agentName}
                                                onChange={(e) => setAgentName(e.target.value)}
                                                className="form-control"
                                                placeholder="Agent Name"
                                                required
                                                disabled={isEditMode}
                                            />
                                        </Col>
                                        <Col md="4">
                                            <label htmlFor="agentNumber" className="form-label">Agent Number</label>
                                            <input
                                                type="number"
                                                id="agentNumber"
                                                value={agentNumber}
                                                onChange={(e) => setAgentNumber(e.target.value)}
                                                className="form-control"
                                                placeholder="Agent Number"
                                                required
                                            />
                                        </Col>
                                        <Col md="4">
                                            <label htmlFor="emailId" className="form-label">Email ID</label>
                                            <input
                                                type="email"
                                                id="emailId"
                                                value={emailId}
                                                onChange={(e) => setEmailId(e.target.value)}
                                                className="form-control"
                                                placeholder="Email ID"
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="4">
                                            <label htmlFor="extensionDropdown" className="form-label">Extension</label>
                                            <Dropdown
                                                id="extensionDropdown"
                                                value={selectedExtension}
                                                style={{height:"50%",alignItems:"center"}}
                                                options={extensionOptions}
                                                onChange={(e) => setSelectedExtension(e.value)}
                                                placeholder="Select Extension"
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col md="4">
                                            <label htmlFor="username" className="form-label">Username</label>
                                            <input
                                                type="text"
                                                id="username"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                className="form-control"
                                                placeholder="Username"
                                                required
                                            />
                                        </Col>
                                        <Col md="4">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input
                                                type="password"
                                                id="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="form-control"
                                                placeholder="Password"
                                                required={!isEditMode}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3" style={{marginTop:"-16px"}}>
                                        <Col md="4">
                                            <label htmlFor="accessDropdown" className="form-label">Access</label>
                                            <Dropdown
                                                id="accessDropdown"
                                                value={access}
                                                options={accessOptions}
                                                style={{height:"50%",alignItems:"center"}}
                                                onChange={(e) => setAccess(e.value)}
                                                placeholder="Select Access"
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col md="4" >
                                            <label htmlFor="typeDropdown" className="form-label">Type</label>
                                            <Dropdown
                                            style={{height:"50%",alignItems:"center"}}
                                                id="typeDropdown"
                                                value={type}
                                                options={typeOptions}
                                                onChange={(e) => setType(e.value)}
                                                placeholder="Select Type"
                                                className="form-control"
                                            />
                                        </Col>
                                        {/* <Col md="4">
                                            <label htmlFor="loginStatusDropdown" className="form-label">Login Status</label>
                                            <Dropdown
                                            style={{height:"50%",alignItems:"center"}}
                                                id="loginStatusDropdown"
                                                value={loginStatus}
                                                options={loginStatusOptions}
                                                onChange={(e) => setLoginStatus(e.value)}
                                                placeholder="Select Login Status"
                                                className="form-control"
                                            />
                                        </Col> */}
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <label htmlFor="description" className="form-label">Description</label>
                                            <textarea
                                                id="description"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                className="form-control"
                                                placeholder="Description"
                                                rows="3"
                                            ></textarea>
                                        </Col>
                                    </Row>
                                    <Button
                                        label={isEditMode ? 'Update' : 'Create'}
                                        onClick={handleSaveAgent}
                                        className="p-button-danger mt-3"
                                        type="button"
                                    />
                                </form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />
        </>
    );
}
