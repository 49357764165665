import _ from 'lodash'
import { getColorShadeTint } from './colors'
import { getRootVars, setRootVariables, setFontFamily } from './root-var'
import { addClass, removeClass, setAttr, setContent } from './dom';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { USER_ROLES, USER_ROLES_MAP, PERMISSION_TYPES } from './constant';

export const updateBodyClass = (choices, value) => {
    // remove class from choices
    if(choices.length > 0) {
        choices.forEach((other) => {
            removeClass('body', other)
        });
    }
    if(_.isArray(value)) {
        _.forEach(value, (val) => {
            addClass('body', val)
        })
    } else {
        addClass('body', value)
    }
}

export const updateHtmlAttr = (value) => {
    // set direction
    setAttr('html', value)
}

export const updateHtmlClass = (choices, value) => {
    // remove class from choices
    if(choices.length > 0) {
        choices.forEach((other) => {
            removeClass('html', other)
        });
    }
    addClass('html', value)
}

export const updateTitle = (value) => {
    // set title
    setContent('[data-setting="app_name"]', value)
}

export const updateColorRootVar = (theme_scheme, theme_color, choices) => {
    const prefix = getRootVars("--prefix") || "bs-";
    let newColors = {};
    let dark = false
    if (theme_scheme !== 'light' && theme_scheme !== 'auto') {
        dark = true
    }

    _.forEach(theme_color.colors, (value, key) => {
        key = key.replace("{{prefix}}", prefix);
        newColors = {
            ...newColors,
            ...getColorShadeTint(key, value, dark),
        };
    });
    setRootVariables(newColors);
    removeClass("body", choices);
    addClass("body", [theme_color.value]);
}

export const updateDomValueBySetting = (setting, Choices) => {
    updateHtmlAttr({prop: 'dir',  value: setting.theme_scheme_direction.value})
    updateThemeScheme(setting.theme_scheme.value, Choices, setting.theme_color)
    updateHtmlClass(Choices.FSChoice, setting.theme_font_size.value)
    updateBodyClass(Choices.StyleAppearanceChoice, setting.theme_style_appearance.value)
    updateBodyClass(Choices.Animation, setting.theme_transition.value)
    updateTitle(setting.app_name.value)
    setFontFamily('body', setting.body_font_family.value)
    setFontFamily('heading', setting.heading_font_family.value)
}

export const updateStorage = (storage,key,value) => {
    if(typeof value !== typeof undefined && typeof key !== typeof undefined) {
        switch (storage) {
            case 'localStorage':
                sessionStorage.removeItem(key)
                localStorage.setItem(key, JSON.stringify(value))       
                break;

            case 'sessionStorage':
                localStorage.removeItem(key)
                sessionStorage.setItem(key, JSON.stringify(value))
                break;
        
            default:
                localStorage.removeItem(key)
                sessionStorage.removeItem(key)
                localStorage.setItem(key, 'none')
                sessionStorage.setItem(key, 'none')
                break;
        }
    }
}

export const getStorage = (key) => {
    if(localStorage.getItem(key) === 'none' || sessionStorage.getItem(key) === 'none') return 'none'
    if((localStorage.getItem(key) !== null && localStorage.getItem(key) !== '' ) || 
    (sessionStorage.getItem(key) !== null && sessionStorage.getItem(key) !== '')) {
        let value = localStorage.getItem(key)
        if(value === null) value = sessionStorage.getItem(key)
        if (value !== null) {
            try {
                return JSON.parse(value);
            } catch (e) {
                return value
            }
        }
    }
    return null
}


export const updateThemeScheme = (value, Choices, theme_color) => {
    let className = []
    if(value === 'auto') {
        className.push('auto')
        if (matchMedia("(prefers-color-scheme: light)").matches) {
            className.push('light')
        } else {
            className.push('dark')
        }
    } else {
        className.push(value)
    }
    const filterValue = className.filter((x) => x !== 'auto')
    updateBodyClass(Choices.SchemeChoice, className)
    updateColorRootVar(...filterValue, theme_color, Choices.ColorChoice)
}

export const storeUser = async(data, cb) => {

    try {
        AsyncStorage.setItem('access_token', data.token);
        AsyncStorage.setItem('user', JSON.stringify(data))
        .then(cb)
        .catch(err => console.log(err));
    } catch (error) {
        console.log(error);
    }

}

export const storeUserSync = async(data) => {

    try {
        await AsyncStorage.setItem('user', JSON.stringify(data));
    } catch (error) {
        console.log(error);
    }

}

export const getUser = async() => {
    try {
        const savedUser = await AsyncStorage.getItem('user');
        const currentUser = JSON.parse(savedUser);
        return currentUser;
    } catch (error) {
        console.log(error);
    }
}


export const getUserAccessToken = async() => {

    try {
        const userToken = await AsyncStorage.getItem('access_token');
        return userToken;
    } catch (error) {
        console.log(error);
    }

}

export async function isUserAuthenticated(){

    try {
        const userToken = await getUserAccessToken();
        return Boolean(userToken);
    } catch (error) {
        console.log(error);
    }

}

/**
 * List of roles, the given user can access
 */
export const getUserAccessRoles = async() => {

    try {
        
        const user = await getUser();
        const userRoles = user.roles_access;
        if(userRoles.length == 0) return null;
        else return userRoles;

    } catch (error) {
        console.log(error);
    }

}

export async function getUserReadAccessRoleLabel(){
    
    try {

        const userRoles = await getUserAccessRoles();
        const userReadRoles = userRoles[PERMISSION_TYPES.READ].map( val => {
            return { 'value':  val, 'label': USER_ROLES_MAP[val] };
        } );
        return userReadRoles;

    } catch (error) {
        
        console.log(error);
        throw error;
    
    }

}


export async function getUserCreateAccessRoleLabel(){
    
    try {

        const userRoles = await getUserAccessRoles();
        const userReadRoles = userRoles[PERMISSION_TYPES.CREATE].map( val => {
            return { 'value':  val, 'label': USER_ROLES_MAP[val] };
        } );
        return userReadRoles;

    } catch (error) {
        
        console.log(error);
        throw error;
    
    }

}


export async function getUserUpdateAccessRoleLabel(){
    
    try {

        const userRoles = await getUserAccessRoles();
        const userReadRoles = userRoles[PERMISSION_TYPES.UPDATE].map( val => {
            return { 'value':  val, 'label': USER_ROLES_MAP[val] };
        } );
        return userReadRoles;

    } catch (error) {
        
        console.log(error);
        throw error;
    
    }

}


export function ifUserAssociateCompany(user_role){

    return Boolean( [USER_ROLES.RESELLER, USER_ROLES.COMPANY_ADMIN, USER_ROLES.SUB_SUPERADMIN].includes(user_role) );

}

export async function logoutUser(){

    AsyncStorage.removeItem('access_token');

    AsyncStorage.removeItem('user', function(err){

        if(err) console.log(`Error while logout user: ${ String(err) }`);

        return true;

    });

}

export async function switchAs(type, id, name){

    try {
     
        if(type == USER_ROLES.CALL_CENTRE) switchAsCallCentre(id, name);

    } catch (error) {
        
        console.log(`Error while switching account: ${error}`);

    }

}

async function switchAsCallCentre(id, name){

    const savedUser = await AsyncStorage.getItem('user');
    await AsyncStorage.setItem('user_backup', savedUser);
    //TODO: Make it dynamic from server end
    const obj = {id, username: name, role: USER_ROLES.CALL_CENTRE, roles_access: {[PERMISSION_TYPES.CREATE]: [USER_ROLES.CALL_CENTRE_ADMIN], [PERMISSION_TYPES.UPDATE]: [USER_ROLES.CALL_CENTRE_ADMIN], [PERMISSION_TYPES.READ]: [USER_ROLES.CALL_CENTRE_ADMIN], [PERMISSION_TYPES.DELETE]: [USER_ROLES.CALL_CENTRE_ADMIN]} };
    const details = JSON.stringify(obj);
    await AsyncStorage.setItem('user', details);

    return true;

}

export async function switchBack(){

    //TODO: Make the fields dynamic
    const savedBackupUser = await AsyncStorage.getItem('user_backup');
    await AsyncStorage.setItem('user', savedBackupUser);
    await AsyncStorage.removeItem('user_backup');

    return true;

}

export async function switchUser(targetUser) {
    try {
        const savedUser = await AsyncStorage.getItem('user');
        await AsyncStorage.setItem('userBackup', savedUser);
        const tokenBackup = await AsyncStorage.getItem('access_token')
        await AsyncStorage.setItem('tokenBackup', tokenBackup);

        await AsyncStorage.setItem('access_token', targetUser.token);
        await AsyncStorage.setItem('user', JSON.stringify(targetUser));
        return true; 
    } catch (error) {
        console.log(error);
        return false; 
    }
}

export async function switchBackUser() {
    try {
        const savedBackupUser = await AsyncStorage.getItem('userBackup');
        const savedToken = await AsyncStorage.getItem('tokenBackup');
        if (savedBackupUser && savedToken) {
            await AsyncStorage.setItem('user', savedBackupUser);
            await AsyncStorage.setItem('access_token', savedToken);

            await AsyncStorage.removeItem('userBackup');
            await AsyncStorage.removeItem('tokenBackup');

            return true;
        }
        return false;
    } catch (error) {
        console.error('Switch back user error:', error);
        return false;
    }
}