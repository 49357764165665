import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { getApiCall, postApiCall } from "../../../service/httpCall"; // Replace with your API call service
import Loader from '../../../components/partials/components/loader';
import ToastNotification from "../../../components/Toast";
import { API_PROMPTS_LIST } from "../../../service/url";
import "../../../assets/custom/css/custom.css";
import { BACKEND_API_BASE_URL } from "../../../utilities/constant";
import "./index.css"
import { PROMPT_STATUS } from "../../../utilities/constant";
import { Toaster, toast } from 'react-hot-toast';
import LottieAnimation from '../../../components/lottie/lottie';
import Modal from "react-bootstrap/Modal";


const ManagePrompts = () => {
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPrompts, setSelectedPrompts] = useState([]);
  const [toastProps, setToastProps] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [audioUrl, setAudioUrl] = useState("");
  const [showToastModal, setShowToastModal] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    setLoading(true);
    getApiCall(`${BACKEND_API_BASE_URL}/v1/prompt`)
      .then(onPromptsSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  }, [reloadTrigger]);

  const showToasts = (message) => {
    setToastMessage(message);
    setShowToastModal(true);

    // Hide modal automatically after 3 seconds
    setTimeout(() => {
        setShowToastModal(false);
    }, 3000);
  };

  const onPromptsSuccess = (resp) => {
    const updatedList = resp.map((item, index) => ({
      ...item,
      key: index + 1,
      status: item.prompt_status === 0 
        ? "Pending" 
        : item.prompt_status === 1 
        ? "Approved" 
        : "Rejected",
    }));
    setPrompts(updatedList);
  };
  
  const handleApprove = (id) => {
    const payload = {
      status: PROMPT_STATUS.APPROVED
    }
    postApiCall(`/v1/prompt/${id}`, payload).then((data) => {
      showToasts('Audio Approved');
      setReloadTrigger((prev) => !prev);
    }).catch((err) => {
      toast.error(err)
    })
  };

  const handleReject = (id) => {
    const payload = {
      status: PROMPT_STATUS.REJECT
    }
    postApiCall(`/v1/prompt/${id}`, payload).then((data) => {
      showToasts('Audio Rejected');
      setReloadTrigger((prev) => !prev);
    }).catch((err) => {
      toast.error(err)
    })
  };

  const handleListen = (url) => {
    setAudioUrl(url);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setAudioUrl("");
  };

  const onError = (error) => {
    setToastProps({
      type: "danger",
      message: `Failed to load prompts: ${error}`,
    });
    setShowToast(true);
  };

  const handleSelectAll = () => {
    if (selectedPrompts.length === prompts.length) {
      setSelectedPrompts([]);
    } else {
      const allPromptIds = prompts.map((prompt) => prompt.id);
      setSelectedPrompts(allPromptIds);
    }
  };

  const handleCheckboxToggle = (promptId) => {
    const isSelected = selectedPrompts.includes(promptId);
    setSelectedPrompts(
      isSelected
        ? selectedPrompts.filter((id) => id !== promptId)
        : [...selectedPrompts, promptId]
    );
  };


  const rowClassName = (data) => {
    return {
      "highlighted-row": selectedPrompts.includes(data.id),
    };
  };


  const actionBodyTemplate = (rowData) => {

    const isApproved = rowData.status === "Approved";
    const isRejected = rowData.status === "Rejected";


    return (


      <div className="d-flex justify-space-between gap-2">
      <Button
        label="Listen"
        icon="pi pi-play"
        className="listen"
        id="btnprompt"
        onClick={() => handleListen(rowData.prompt_url)}
      />
      <Button
        label="Approve"
        icon="pi pi-check"
        id="btnprompt"
        className={`approve ${isApproved ? "disabled-btn" : ""}`}
        onClick={() => !isApproved && handleApprove(rowData._id)} // Disable click if approved
        disabled={isApproved} // Disable button functionality
      />
      <Button
        label="Reject"
        icon="pi pi-times"
        id="btnprompt"
        className={`reject ${isRejected ? "disabled-btn" : ""}`}
        onClick={() => !isRejected && handleReject(rowData._id)} // Disable click if rejected
        disabled={isRejected} // Disable button functionality
      />
    </div>
    );
  };


  if (loading) {
    return <Loader />;
  }

  return (
    <>
     <div>
      <Toaster />
      <div className="card" style={{ marginTop: "20px" }}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="card-title">Manage Prompts</h4>
          {/* <Button
            label="Export Selected"
            className="p-button-success p-button-sm"
            onClick={handleExport}
            disabled={selectedPrompts.length === 0}
          /> */}
        </div>
        <div className="card-body">
          <DataTable
            value={prompts}
            paginator
            rows={10}
            tableStyle={{ minWidth: "50rem" }}
            rowClassName={rowClassName}
          >
            <Column field="prompt_name" header="Name" filterPlaceholder="Search by name" />
            <Column field="prompt_duration" header="Duration" filterPlaceholder="Search by duration" />
            <Column field="status" header="Status" filterPlaceholder="Search by status" />
            <Column field="prompt_url" header="URL" filterPlaceholder="Search by URL" />
            <Column field="prompt_category" header="Category" filterPlaceholder="Search by category" />
            <Column
              header="Actions"
              body={actionBodyTemplate}
              style={{ textAlign: "center", width: "12rem" }}
            />

          </DataTable>
        </div>
      </div>
      <ToastNotification
        show_toast={showToast}
        set_show_toast={setShowToast}
        {...toastProps}
      />
    </div>
    <Dialog
        header="Audio Playback"
        visible={isModalVisible}
        style={{
          width: "40vw",
          position: "absolute",
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        onHide={handleModalClose}
      >
        <audio controls style={{ width: "100%" }}>
          <source src={audioUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </Dialog>

      {/* LOTTIE MODEL FOR TOAST */}
      <Modal show={showToastModal} centered onHide={() => setShowToastModal(false)} size="sm">
                <Modal.Body className="text-center">
                    <h5>{toastMessage}</h5>
                    <LottieAnimation />
                </Modal.Body>
            </Modal>
    </>
   
  );
};

export default ManagePrompts;
