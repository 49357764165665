import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { API_AGENTS_LIST, API_AGENT_GROUPS_LIST } from '../../../service/url';
import { getApiCall } from '../../../service/getApiCall';
import { postApiCall } from '../../../service/postApiCall';
import { useNavigate, useLocation } from 'react-router-dom';
import ToastNotification from '../../../components/Toast';

export default function CreateAgentGroups() {
    const navigate = useNavigate();
    const location = useLocation();
    const agentGroupData = location.state || {}; // Data passed during navigation
    const isEditMode = Boolean(agentGroupData._id); // Detect if editing or creating

    const [groupName, setGroupName] = useState(agentGroupData.group_name || '');
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [agents, setAgents] = useState(agentGroupData.agent_id || []);
    const [agentOptions, setAgentOptions] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState({ type: 'success', message: '' });
    const [groupOwner, setGroupOwner] = useState('Vikram Arora'); // Default value
    const [manager, setManager] = useState('Vikram Arora'); // Default value


    // Fetch agents for the dropdown
    useEffect(() => {
        getApiCall(API_AGENTS_LIST)
            .then((data) => {
                const options = data.map((agent) => ({
                    label: agent.agent_name,
                    value: agent,
                }));
                setAgentOptions(options);
            })
            .catch((error) => {
                console.error('Error fetching agents:', error);
                setToastProps({ type: 'danger', message: 'Error fetching agents.' });
                setShowToast(true);
            });
    }, []);

    // Add selected agents to the group
    const handleAddAgents = () => {
        const newAgents = selectedAgents.filter(
            (selectedAgent) =>
                !agents.some((agent) => agent.id === selectedAgent.id || agent.id === selectedAgent._id)
        );
        setAgents([...agents, ...newAgents]);
        setSelectedAgents([]);
    };

    // Save the agent group (Create or Update)
    const handleSaveGroup = () => {
        const data = {
            group_name: groupName.trim(), // Ensure no trailing/leading spaces
            group_owner: groupOwner.trim(),
            manager: manager.trim(),
        };
    
        const apiUrl = isEditMode
            ? `${API_AGENT_GROUPS_LIST}/${agentGroupData._id}` // Update endpoint
            : API_AGENT_GROUPS_LIST; // Create endpoint
    
        postApiCall(apiUrl, data)
            .then(() => {
                const successMessage = isEditMode
                    ? 'Agent group updated successfully.'
                    : 'Agent group created successfully.';
                setToastProps({ type: 'success', message: successMessage });
                setShowToast(true);
    
                // Navigate to list after toast disappears
                setTimeout(() => navigate('/agent-group-list'), 3000);
            })
            .catch((error) => {
                const errorMessage =
                    error?.response?.data?.message ||
                    error.message ||
                    'Error saving agent group.';
                console.error('Error saving agent group:', error);
                setToastProps({ type: 'danger', message: errorMessage });
                setShowToast(true);
            });
    };
    
    // Remove an agent from the group
    const handleDeleteAgent = (index) => {
        const updatedAgents = agents.filter((_, i) => i !== index);
        setAgents(updatedAgents);
    };

    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-block justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">
                                        {isEditMode ? 'Edit Call Group' : 'Create Call Group'}
                                    </h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <form>
                                    <Row className="mb-3">
                                        <Col md="4">
                                            <label htmlFor="groupName" className="form-label">
                                                Group Name
                                            </label>
                                            <input
                                                type="text"
                                                id="groupName"
                                                value={groupName}
                                                onChange={(e) => setGroupName(e.target.value)}
                                                className="form-control"
                                                placeholder="Group Name"
                                                required
                                            />
                                        </Col>
                                        <Col md="4">
        <label htmlFor="groupOwner" className="form-label">
            Group Owner
        </label>
        <select
            id="groupOwner"
            value={groupOwner}
            onChange={(e) => setGroupOwner(e.target.value)}
            className="form-control"
        >
            <option value="Vikram Arora">Vikram Arora</option>
        </select>
    </Col>

    <Col md="4">
        <label htmlFor="manager" className="form-label">
            Select Manager
        </label>
        <select
            id="manager"
            value={manager}
            onChange={(e) => setManager(e.target.value)}
            className="form-control"
        >
            <option value="Vikram Arora">Vikram Arora</option>
        </select>
    </Col>
                                        <Col md="8">
                                            {/* <MultiSelect
                                                id="agentDropdown"
                                                value={selectedAgents}
                                                options={agentOptions}
                                                onChange={(e) => setSelectedAgents(e.value)}
                                                placeholder="Select Agents"
                                                display="chip"
                                                style={{ marginTop: '25px', width: '40%' }}
                                                showSelectAll={true} // Adds a "Select All" checkbox
                                            /> */}
                                           
                                     
                                            {/* <Button
                                                // label="Add Selected Agents"
                                                icon="pi pi-plus"
                                                className="fa fa-plus-circle plus-icon"
                                                onClick={handleAddAgents}
                                                disabled={selectedAgents.length === 0}
                                                style={{marginLeft:"40px", marginBottom:"5px"}}
                                            /> */}
                                        
                                    
                                        </Col>
                                        
                                    </Row>
                                    
                                </form>

                                {agents.length > 0 && (
                                    <div className="mt-4" style={{ maxWidth: '600px' }}>
                                        <div
                                            style={{
                                                overflowY: 'auto',
                                                maxHeight: '200px',
                                                border: '1px solid #ddd',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <table className="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Agent Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {agents.map((agent, index) => (
                                                        <tr key={index}>
                                                            <td>{agent.agent_name}</td>
                                                            <td>
                                                                <Button
                                                                    icon="pi pi-minus"
                                                                    className="fa fa-minus-circle plus-icon"
                                                                    onClick={() =>
                                                                        handleDeleteAgent(index)
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}

                                <Button
                                    label={isEditMode ? 'Update' : 'Create'}
                                    onClick={handleSaveGroup}
                                    className="p-button-danger mt-3"
                                    type="button"
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* Render Toast Notification */}
            <ToastNotification
                show_toast={showToast}
                set_show_toast={setShowToast}
                {...toastProps}
            />
        </>
    );
}
