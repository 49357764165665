import React, { useState, useCallback, useEffect } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  Handle
} from "react-flow-renderer";
import { getApiCall} from '../../../service/httpCall';
import {API_IVR_VIEW } from '../../../service/url';
import { useParams} from 'react-router-dom';

const CustomNode = ({ data }) => {
    return (
      <div style={{ paddingRight: '14px', border: '1px solid black', borderRadius: '4px', backgroundColor: 'white' }}>
        <Handle type="target" position="top" />
        <div dangerouslySetInnerHTML={{ __html: data.htmlContent }} />
        <Handle type="source" position="bottom" />
      </div>
    );
  };

  const nodeTypes = {
    customNode: CustomNode,
  };



const IVRView = () => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const {cid} = useParams();

  useEffect(() => {
    const apiIvrViewUrl = API_IVR_VIEW.replace(':cid', cid).replace(':id', '607454cf-df31-46f5-92c6-f9bb0e0ab513');
    getApiCall(apiIvrViewUrl)
      .then(onIvrViewSuccess)
      .catch(onError)
  }, []);

  function onIvrViewSuccess(resp) {
    transformNodes(resp)
    setEdges(resp.edgeData.edgeJson)
  }

    function onError(err) {
        console.log(err);
    }


const transformNodes = (nodesArray) => {
    const nodes =[]  
    nodesArray.nodesData.forEach(item => {
      nodes.push(item.flowRender); 
    });
  
    setNodes(nodes)
    return nodes;
  };
  
  return (
    <div style={{ height: "50vh", width: "100%" , position: "relative" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes= {nodeTypes}
        fitView
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default IVRView;